const defaultState = {
    activeDocumentOpeartion: null,
    documentsModal: false,
}

const UPDATE_DOCUMENT_OPERATION = 'UPDATE_DOCUMENT_OPERATION';
const UPDATE_DOCUMENTS_MODAL = 'UPDATE_DOCUMENTS_MODAL';

export const DocumentsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_DOCUMENT_OPERATION:
            return { ...state, activeDocumentOpeartion: action.payload }
        case UPDATE_DOCUMENTS_MODAL:
            return { ...state, documentsModal: action.payload }
        default:
            return state;
    }
}

export const updateDocumentOperation = (payload) => ({ type: UPDATE_DOCUMENT_OPERATION, payload });
export const updateDocumentsModal = (payload) => ({ type: UPDATE_DOCUMENTS_MODAL, payload });
import React, { useEffect } from 'react';
import "./LiveMatchPage.scss";
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Footer from 'components/Footer/Footer';
import AsideMenu from 'components/AsideMenu/AsideMenu';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import TabMenu from 'components/PageMatch/TabMenu/TabMenu';
import BetsContainer from 'components/PageMatch/BetsContainer/BetsContainer';
import { updateParamAction } from 'store/CategoryReducer';
import { getRoute } from 'utils/routes';
import LiveMatchHeader from 'components/PageMatch/MatchHeader/LiveMatchHeader';
import MainLayout from 'layouts/MainLayout';

const LiveMatchPage = () => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.CategoryReducer.categories);
    const params = useParams();
    const currentCategory = categories.find(category => category.slug === params.category);

    const currentMatch = useSelector(state => state.MatchesReducer.matches)
        .filter(match => match.id === params.match)[0]

    useEffect(() => {
        dispatch(updateParamAction(params.category));
    }, [dispatch, params])

    useEffect(() => () => {
        dispatch(updateParamAction(''));
    }, [dispatch])

    if (!currentMatch) {
        return <Navigate to={getRoute("error-404")} />
    }

    return (
        <MainLayout pageName="live-match">
            <main className="content_block_wrapper small_width">
                <Breadcrumb pages={[
                    { name: "Ставки лайв", routeName: 'live-bets' },
                    { name: `${currentMatch.mainInfo.teamName_1} x ${currentMatch.mainInfo.teamName_2}`, routeName: '' },
                ]} prevPage={true} />
                <section className='content_block'>
                    <LiveMatchHeader currentCategory={currentCategory} currentMatch={currentMatch} />
                    <TabMenu />
                    <BetsContainer betsList={currentMatch.betsList} />
                </section>
                <Footer />
                <AsideMenu />
            </main>
        </MainLayout>
    )
}

export default LiveMatchPage;
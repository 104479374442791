import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL

const api = axios.create({
    baseURL: apiUrl
})

api.interceptors.request.use(
    function (config) {
        const newConfig = config;

        const accessToken = localStorage.getItem('accessToken');

        if (!config.withoutAuth && accessToken) {
            newConfig.headers.Authorization = `Bearer ${accessToken}`;
        }

        return newConfig;
    },
    function (error) {
        return Promise.reject(error)
    }
)

export default api
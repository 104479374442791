import React from 'react';

const ArrowDown = ({...props}) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
            <path d="M15.4844 2.5471L8.86426 9.23555L2.24414 2.5471" stroke="#6E7074" strokeWidth="3" strokeLinecap="square" />
        </svg>
    )
}

export default ArrowDown;
import ProfilePageTitle from 'components/ProfilePageTitle/ProfilePageTitle';
import React, { useState } from 'react';
import BalanceForm from './BalanceForm/BalanceForm';
import MyButton from 'components/UI/MyButton/MyButton';
import { getRoute } from 'utils/routes';
import BalanceAlert from './BalanceAlert/BalanceAlert';

const DepositeBlock = () => {
    const [alert, setAlert] = useState(null);
    const [errors, setErors] = useState('');
    const [value, setValue] = useState('');

    return (
        <div className="inner_block profile_content_block">
            <ProfilePageTitle
                title="Поповнення рахунку"
                subtitle="Зручний та безпечний спосіб збільшити баланс на вашому обліковому записі.Після поповнення рахунку ви завжди можете перевіряти свій актуальний баланс та історію операцій у вашому обліковому кабінеті."
            />
            {
                alert
                    ?
                    <>
                        <BalanceAlert
                            title="Ігровий рахунок успішно поповнений. Вдалої гри!"
                            subtitle="Вітаємо вас із успішним поповненням вашого ігрового рахунку! Тепер у вас є більше можливостей для захопливих пригод та вражаючих перемог у своїх улюблених іграх."
                            setAlert={setAlert}
                        />
                        <MyButton href={getRoute('home')} className="active my_button">Ставити на спорт </MyButton>
                    </>
                    :
                    (
                        <>
                            {
                                alert === false &&
                                <BalanceAlert
                                    success={false}
                                    title="Рахунок не поповнено"
                                    subtitle={errors}
                                    setAlert={setAlert}
                                />
                            }
                            <BalanceForm
                                sumTitle="Сума депозиту"
                                btnTitle="Поповнити баланс"
                                setAlert={setAlert}
                                setErors={setErors}
                                value={value}
                                setValue={setValue}
                            />
                        </>
                    )

            }
        </div>
    )
}

export default DepositeBlock;
import React from 'react';

const SignOut = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.27458 5.13017C3.77804 4.63789 4.46087 4.36133 5.17287 4.36133H14.3772C15.0892 4.36133 15.772 4.63789 16.2755 5.13017C16.7789 5.62246 17.0618 6.29013 17.0618 6.98633V10.0412C17.0618 10.4554 16.7183 10.7912 16.2947 10.7912C15.8711 10.7912 16.0595 10.7912 15.5372 10.7912C15.0149 10.7912 14.7482 10.7912 14.3772 10.7912C14.0061 10.7912 13.3839 10.7912 12.7809 10.7912C12.1778 10.7912 11.5664 10.7912 11.2613 10.7912H8.93578C8.63064 10.7912 8.338 10.9097 8.12223 11.1207C7.90646 11.3317 7.78525 11.6178 7.78525 11.9162L7.80098 13.4863C7.80098 13.7847 7.9222 14.0708 8.13796 14.2818C8.35373 14.4928 8.64637 14.6113 8.95152 14.6113H11.5565C11.8616 14.6113 13.9448 14.6113 14.6429 14.6113C14.9408 14.6113 15.8711 14.6113 16.2947 14.6113C16.7183 14.6113 17.0618 14.9471 17.0618 15.3613V18.2363C17.0618 18.9325 16.7789 19.6002 16.2755 20.0925C15.772 20.5848 15.0892 20.8613 14.3772 20.8613H5.17287C4.46087 20.8613 3.77804 20.5848 3.27458 20.0925C2.77112 19.6002 2.48828 18.9325 2.48828 18.2363V6.98633C2.48828 6.29013 2.77112 5.62246 3.27458 5.13017Z" fill="#6E7074" />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.3458 8.46869C18.6453 8.1758 19.1309 8.1758 19.4305 8.46869L23.2656 12.2187C23.5652 12.5116 23.5652 12.9865 23.2656 13.2794L19.4305 17.0294C19.1309 17.3222 18.6453 17.3222 18.3458 17.0294C18.0462 16.7365 18.0462 16.2616 18.3458 15.9687L21.6385 12.749L18.3458 9.52935C18.0462 9.23646 18.0462 8.76159 18.3458 8.46869Z" fill="#6E7074" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.86914 12.749C8.86914 12.3348 9.21255 11.999 9.63617 11.999H22.7235C23.1471 11.999 23.4906 12.3348 23.4906 12.749C23.4906 13.1632 23.1471 13.499 22.7235 13.499H9.63617C9.21255 13.499 8.86914 13.1632 8.86914 12.749Z" fill="#6E7074" />
        </svg>
    )
}

export default SignOut;
import React from 'react';

const FiltersIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="43" viewBox="0 0 41 43" fill="none">
            <rect x="0.152344" y="4.26672" width="40.5312" height="5.4992" rx="2.7496" fill="#6E7074" />
            <rect x="0.152344" y="18.3662" width="40.5312" height="5.4992" rx="2.7496" fill="#6E7074" />
            <rect x="0.152344" y="32.4656" width="40.5312" height="5.4992" rx="2.7496" fill="#6E7074" />
            <rect x="6.06836" y="42.092" width="13.754" height="5.4992" rx="2.7496" transform="rotate(-90 6.06836 42.092)" fill="#6E7074" />
            <rect x="29.2715" y="27.9927" width="13.754" height="5.4992" rx="2.7496" transform="rotate(-90 29.2715 27.9927)" fill="#6E7074" />
            <rect x="5.86523" y="13.8932" width="13.754" height="5.4992" rx="2.7496" transform="rotate(-90 5.86523 13.8932)" fill="#6E7074" />
        </svg>
    )
}

export default FiltersIcon;
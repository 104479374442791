import React from 'react';

const Home = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="17" viewBox="0 0 27 17" fill="none">
            <path d="M6.65234 9.76489C6.65234 8.66033 7.54777 7.76489 8.65234 7.76489H18.084C19.1886 7.76489 20.084 8.66032 20.084 9.76489V14.214C20.084 15.3185 19.1886 16.214 18.084 16.214H8.65234C7.54777 16.214 6.65234 15.3185 6.65234 14.214V9.76489Z" fill="#6E7074" />
            <path d="M12.9183 1.15642C13.2007 1.01453 13.5336 1.01453 13.8161 1.15642L21.0727 4.80182C22.0147 5.27505 21.678 6.6954 20.6237 6.6954H6.11063C5.05643 6.6954 4.71971 5.27505 5.66173 4.80182L12.9183 1.15642Z" fill="#6E7074" />
        </svg>
    )
}

export default Home;
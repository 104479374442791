import React, { useState } from 'react';
import "./ImagesUpload.scss";
import MyButton from 'components/UI/MyButton/MyButton';
import ImageUploading from 'react-images-uploading';
import ImageUpload from './ImageUpload';
import PostService from 'api/PostService';
import Loader from 'components/UI/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { updateDocumentsModal } from 'store/DocumentsReducer';

const ImagesUpload = ({ documentType = null }) => {
    const activeDocumentOpeartion = useSelector(state => state.DocumentsReducer.activeDocumentOpeartion);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [images, setImages] = useState([]);
    const maxNumber = 4;
    const extensions = ['jpg', 'jpeg', 'png'];
    const [loader, setLoader] = useState(false)

    const onChange = (imageList, addUpdateIndex) => {
        // console.log("imageList: ", imageList);
        setImages(imageList);
    };

    const sendImages = async () => {
        if (!images.length) return
        setLoader(true);
        const formData = new FormData();

        images.forEach((image, index) => {
            formData.append(`photos[${index}]`, image.file);
        });

        if (documentType) {
            formData.append('document_type', documentType);
            const response = await PostService.storeDocuments(formData);
        }

        setImages([]);
        setLoader(false);
        dispatch(updateDocumentsModal(true))
        navigate(getRoute('documents'))
    }

    return (
        <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
            maxFileSize={10000000}
            acceptType={extensions}
        >
            {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                errors,
            }) => (
                <>
                    <div className='flex button_wrapper'>
                        <MyButton
                            onClick={onImageUpload}
                            className={"my_button active medium full_height" + ((activeDocumentOpeartion?.status === "pending" || activeDocumentOpeartion?.status === 'success') ? " is-blocked" : "")}
                        >
                            Вибір файла
                        </MyButton>
                        <MyButton
                            onClick={sendImages}
                            className={"my_button active medium full_height" + (!images.length ? " is-blocked" : "")}
                        >
                            Відправити документи
                        </MyButton>
                    </div>
                    {
                        errors &&
                        <div className='errors_block'>
                            {errors.maxNumber && <span>Количество изображений превышает максимальный лимит ({maxNumber})</span>}
                            {errors.acceptType && <span>Выбранный вами тип файла недопустим. Выберете файлы с одним из следующих расширений: {extensions.map(item => item + " ")}</span>}
                            {errors.maxFileSize && <span>Размер выбранного файла превышает максимальный допустимый размер (10Mb)</span>}
                        </div>
                    }
                    {
                        loader
                            ?
                            <div className='loader_wrapper'>
                                <Loader />
                            </div>
                            :
                            <div className='images_upload_list'>
                                {
                                    imageList.map((image, index) =>
                                        <ImageUpload
                                            key={index}
                                            index={index}
                                            image={image}
                                            onImageRemove={onImageRemove}
                                        />
                                    )
                                }
                            </div>
                    }
                </>
            )}
        </ImageUploading>
    )
}

export default ImagesUpload;
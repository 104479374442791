import { ThemeReducer } from "./ThemeReducer";
import { CategoryReducer } from './CategoryReducer';
import { BetReducer } from './BetReducer';
import { AccountReducer } from './AccountReducer';
import { MainSliderReducer } from './MainSliderReducer';
import { HelpInfoReducer } from './HelpInfoReducer';
import { InfoPageReducer } from './InfoPageReducer';
import { LinksDataReducer } from './LinksDataReducer';
import { LoadingReducer } from './LoadingReducer';
import { MainMatchesReducer } from './MainMatchesReducer';
import { FutureMatchesReducer } from './FutureMatchesReducer';
import { CurrentMatchReducer } from './CurrentMatchReducer';
import { OddsTypesReducer } from './OddsTypesReducer';
import { BalanceReducer } from './BalanceReducer';
import { SearchReducer } from './SearchReducer';
import { DocumentsReducer } from './DocumentsReducer';
import { composeWithDevTools } from "redux-devtools-extension";
const { createStore, combineReducers } = require("redux");

const rootReducer = combineReducers({
    ThemeReducer,
    CategoryReducer,
    BetReducer,
    AccountReducer,
    MainSliderReducer,
    HelpInfoReducer,
    InfoPageReducer,
    LinksDataReducer,
    LoadingReducer,
    FutureMatchesReducer,
    MainMatchesReducer,
    CurrentMatchReducer,
    OddsTypesReducer,
    BalanceReducer,
    SearchReducer,
    DocumentsReducer,
})

export const store = createStore(rootReducer, composeWithDevTools())
import React from 'react';
import "./MatchHeader.scss";
import Live from 'svg/Live';
import LiveGrid from 'components/LiveGrid/LiveGrid';

const LiveMatchHeader = ({ currentCategory, currentMatch }) => {
    return (
        <div className="match_header flex column live">
            <div className='flex space_between align_center match_content'>
                <img className='img_background team_1' src={require(`assets/team/${currentMatch.mainInfo.teamLogo_1}`)} alt="team_1" />
                <img className='img_background team_2' src={require(`assets/team/${currentMatch.mainInfo.teamLogo_2}`)} alt="team_2" />

                <div className="team_wrapper flex align_center column-if_sm_size">
                    <div className="team flex align_center column-if_sm_size">
                        <img src={require(`assets/team/${currentMatch.mainInfo.teamLogo_1}`)} alt="team_1" />
                        <span className="team_name">{currentMatch.mainInfo.teamName_1}</span>
                    </div>
                    <span className="coef">{currentMatch.mainInfo.coef_1}</span>
                </div>

                <div className="block_info flex column align_center">
                    <span className="category live">{currentCategory.title}</span>
                    <div className="live">
                        <Live />
                        <span>Live</span>
                    </div>
                    <span className="time">{currentMatch.mainInfo.time}</span>
                    <span className="title">{currentMatch.mainInfo.matchTitle}</span>
                </div>

                <div className="team_wrapper flex align_center row_reverse column-if_sm_size">
                    <div className="team flex align_center row_reverse column-if_sm_size">
                        <img src={require(`assets/team/${currentMatch.mainInfo.teamLogo_2}`)} alt="team_2" />
                        <span className="team_name">{currentMatch.mainInfo.teamName_2}</span>
                    </div>
                    <span className="coef">{currentMatch.mainInfo.coef_2}</span>
                </div>
            </div>
            <div className='flex space_between live_block'>
                <div className='team_block flex column column-if_sm_size'>
                    <span className="live_match">
                        {currentMatch.liveMatch}
                    </span>
                    <div className="team flex">
                        <img src={require("assets/team/team_1.png")} alt="team_1" />
                        <span className="team_name">{currentMatch.mainInfo.teamName_1}</span>
                    </div>
                    <div className="team flex">
                        <img src={require("assets/team/team_2.png")} alt="team_2" />
                        <span className="team_name">{currentMatch.mainInfo.teamName_2}</span>
                    </div>
                </div>
                <LiveGrid gridList={currentMatch.live} />
            </div>
        </div>
    )
}

export default LiveMatchHeader;
import React from 'react';

const Telegram = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.66344 0.347168C3.27241 0.347168 0.523438 3.09614 0.523438 6.48717V21.1613C0.523438 24.5523 3.27241 27.3013 6.66344 27.3013H21.3375C24.7286 27.3013 27.4775 24.5523 27.4775 21.1613V6.48717C27.4775 3.09614 24.7286 0.347168 21.3375 0.347168H6.66344ZM6.183 12.8058C6.41175 12.6896 19.8749 7.50992 21.377 6.96023C21.712 6.83762 22.0521 7.00654 22.2044 7.37101C22.2216 7.4122 22.238 7.44246 22.2515 7.474C22.3575 7.72322 22.2751 8.05256 20.9005 14.4865C20.2484 17.5389 19.6569 20.1596 19.586 20.3102C19.4067 20.6911 19.0614 20.8227 18.67 20.6592C18.5042 20.5899 17.6309 19.9912 16.7293 19.3289C15.8278 18.6666 14.9686 18.0388 14.82 17.9338L14.5497 17.7431L13.5073 18.7364C12.934 19.2827 12.3629 19.7685 12.2383 19.8158C12.016 19.9004 12.0118 19.8951 12.0144 19.5356C12.0158 19.334 12.07 18.4719 12.1347 17.6197C12.2506 16.0945 12.2564 16.0665 12.5007 15.8326C12.6371 15.702 14.2627 14.2389 16.113 12.5812C17.9633 10.9236 19.4618 9.52294 19.4431 9.46883C19.4244 9.41463 19.3153 9.37145 19.2006 9.37283C19.0805 9.37422 17.2114 10.4916 14.7867 12.0115C12.4735 13.4615 10.46 14.7193 10.312 14.8066L10.0431 14.9654L8.05744 14.3507C5.91808 13.6884 5.57252 13.5082 5.76225 13.1535C5.81645 13.0523 6.00575 12.8959 6.183 12.8058Z" fill="#3D3F44" />
        </svg>
    )
}

export default Telegram;
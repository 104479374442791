import api from '../services/api';

export default class AccountService {
    // static logOut() {
    //     localStorage.removeItem('accessToken');
    // }

    static async register(data) {
        const request = await api.post('register', data, { withoutAuth: true })
            .then((response) => {
                if (response.data.token) {
                    localStorage.setItem('accessToken', response.data.token);
                }

                return response;
            })
            .catch((error) => {
                return error.response;
            });

        return request;
    }

    static async logIn(data) {
        const request = await api.post('login', data, { withoutAuth: true })
            .then((response) => {
                if (response.data.token) {
                    localStorage.setItem('accessToken', response.data.token);
                }

                return response;
            })
            .catch((error) => {
                return error.response;
            });

        return request;
    }

    static async getUserData() {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            throw new Error('No access token!');
        }

        const request = await api.get('user')
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }

    static async saveThemeMode(data) {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            return;
        }

        const request = await api.put('theme-mode', data)
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }

    static async updateAutoAcceptOddsMode(data) {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            return;
        }

        const request = await api.put('auto-accept-odds', data)
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }

    static async forgotPassword(data) {
        const request = await api.post('forgot-password', data, { withoutAuth: true })
            .catch((error) => {
                return error.response;
            });

        return request;
    }

    static async resetPassword(data) {
        const request = await api.post('reset-password', data, { withoutAuth: true })
            .catch((error) => {
                return error.response;
            });

        return request;
    }

    static async logInSocial(data) {
        const request = await api.post('login-social', data, { withoutAuth: true })
            .then((response) => {
                if (response.data.token) {
                    localStorage.setItem('accessToken', response.data.token);
                }

                return response;
            })
            .catch((error) => {
                return error.response;
            });

        return request;
    }

    static async logOut() {
        const request = await api.post('logout')
            .catch((error) => {
                return error.response;
            });

        localStorage.removeItem('accessToken');

        return request;
    }

    static async updateUserData(data) {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            return;
        }

        const request = await api.post('user-data', data)
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }

    static async updateUserPassword(data) {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            return;
        }

        const request = await api.put('user-password', data)
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }
}
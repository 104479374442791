import BetCoef from 'components/BetCoef/BetCoef';
import React from 'react';
import { updateOddsObjects } from 'utils/odds';

const GoalsOverUnder = ({ match, oddTypeId, oddsGroup }) => {
    const totalOddText = [
        { name: "over", title: "Більше" },
        { name: "under", title: "Менше" },
    ];

    return (
        <div className="item_submenu flex full_width column">
            {
                oddsGroup.map((oddsSubgroup, i) =>
                    <div
                        key={oddTypeId + '_' + i}
                        className="flex full_width space_between bet-coef_wrapper"
                    >
                        <BetCoef
                            key={oddsSubgroup.odds[0].id + "_condition"}
                            value={oddsSubgroup.group_param}
                            totalBlock={true}
                        />
                        {
                            updateOddsObjects(totalOddText, oddsSubgroup.odds).length === 1 &&
                            <div className={
                                'cap' +
                                (updateOddsObjects(totalOddText, oddsSubgroup.odds)[0].name === 'Under' ? " under" : "")
                            }></div>
                        }
                        {updateOddsObjects(totalOddText, oddsSubgroup.odds).map(odd =>
                            <BetCoef
                                key={odd.id}
                                coefTitle={odd.coefTitle}
                                odd={odd}
                                match={match}
                            />
                        )}
                    </div>
                )
            }
        </div>
    )
}

export default GoalsOverUnder;
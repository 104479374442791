import React, { useState } from 'react';
import "./BalanceForm.scss";
import MyButton from 'components/UI/MyButton/MyButton';
import SwitchBlock from 'components/UI/SwitchBlock/SwitchBlock';
import { useDispatch, useSelector } from 'react-redux';
import PostService from 'api/PostService';
import { updateUserDataAction } from 'store/AccountReducer';
import { updateDepositHistoryAction, updateWithdrawalHistoryAction } from 'store/BalanceReducer';

const BalanceForm = ({ sumTitle, btnTitle, CashWithdrawalForm = false, setAlert, setErors, value, setValue }) => {
    const dispatch = useDispatch();
    const minDeposit = useSelector(state => state.BalanceReducer.min_deposit);
    const maxDeposit = useSelector(state => state.BalanceReducer.max_deposit);

    const addBet = (e) => {
        setValue(Number(e.target.innerText))
    }
    const depositValues = useSelector(state => state.BetReducer.depositValues);


    const regex = /^\d*\.?\d{0,2}$/;
    const changeValue = (e) => {
        if (!regex.test(e.target.value)) {
            return;
        }

        setValue(e.target.value)
    }

    const [cardValue, setCardValue] = useState('');
    const changeCardValue = (e) => {
        if (!regex.test(e.target.value)) {
            return;
        }

        setCardValue(e.target.value)
    }


    const handleResponse = (response) => {
        if (response.status === 200) {
            if (response.data.status === 'error') {
                setAlert(false)
                setErors("Це може бути пов'язано з різними факторами, такими як недостатні кошти на рахунку, помилки у введенні інформації, або проблеми з платіжною системою.")
            } else {
                setErors('')
                setAlert(true)
                setValue('')
                setCardValue('')

                if (response.data.user) {
                    dispatch(updateUserDataAction(response.data.user));
                }
                if (response.data.balance_transactions) {
                    dispatch(updateDepositHistoryAction(response.data.balance_transactions))
                    dispatch(updateWithdrawalHistoryAction(response.data.balance_transactions))
                }
            }
        } else {
            setAlert(false)
            setErors(response.data.message)
        }
    }

    const submitForm = (e) => {
        e.preventDefault();

        if (!value) return

        if (String(value).startsWith('.')) {
            setValue('0' + value)
        }

        if (Number(value) < minDeposit) {
            setAlert(false)
            setErors(`Ви ввели менше, ніж мінімальний депозит. Мінімальний депозит: ${minDeposit}`)
            return
        }
        if (Number(value) > maxDeposit) {
            setAlert(false)
            setErors(`Ви ввели більше, ніж максимальній депозит. Максимальній депозит: ${maxDeposit}`)
            return
        }


        const data = {
            amount: value,
            card_number: cardValue,
        }


        if (CashWithdrawalForm) {
            PostService.withdraw(data)
                .then(handleResponse)
            return
        }


        PostService.refill(data)
            .then(handleResponse)
    }

    return (
        <div className='balance_form flex column'>
            {
                CashWithdrawalForm &&
                <div className="bet_summa flex column">
                    <span className='input_title'>Номер карти</span>
                    <div className="input_block flex align_center">
                        <input
                            type="number"
                            name='number'
                            placeholder='5168********0556'
                            value={cardValue}
                            onChange={(e) => changeCardValue(e)}
                        />
                    </div>
                </div>
            }
            <div className="bet_summa flex column">
                <span className='input_title'>{sumTitle}</span>
                <div className="input_block flex align_center">
                    <input
                        type="text"
                        name='number'
                        placeholder='Введіть сумму'
                        value={value}
                        onChange={(e) => changeValue(e)}
                    />
                </div>
            </div>
            <div className='flex align_center edit_bet_wrapper'>
                <SwitchBlock bckColor2={true}>
                    {
                        depositValues.map(item =>
                            <MyButton
                                key={item.id}
                                onClick={(e) => addBet(e)}
                                className={"my_button" + (item.id === 1 ? " active" : "")}
                            >
                                {item.value}
                            </MyButton>
                        )
                    }
                </SwitchBlock>
            </div>
            <MyButton
                onClick={submitForm}
                className={"active my_button" + (!value ? " blocked" : "")}
            >
                {btnTitle}
            </MyButton>
        </div>
    )
}

export default BalanceForm;
import React, { useEffect, useState } from 'react';
import "./MyBets.scss"
import MainLayout from 'layouts/MainLayout';
import Footer from 'components/Footer/Footer';
import AsideMenu from 'components/AsideMenu/AsideMenu';
import { useDispatch, useSelector } from 'react-redux';
import { updatePagesRedirectAction } from 'store/AccountReducer';
import { useNavigate } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import PageSortHead from 'components/SortHead/SortHead';
import HistoryDataTable from 'components/DataTable/HistoryDataTable';
import Pagination from 'components/UI/Pagination/Pagination';
import NoEvents from 'components/NoEvents/NoEvents';
import FiltersEmpty from 'svg/FiltersEmpty';
import FiltersEmptyLight from 'svg/FiltersEmptyLight';
import NoEventsIconLight from 'svg/NoEventsIconLight';
import NoEventsIcon from 'svg/NoEventsIcon';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';

const MyBets = () => {
    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth === false) {
            dispatch(updatePagesRedirectAction('my-bets'));
            navigate(getRoute("login"));
        }
    }, [isAuth, dispatch, navigate])



    const minDeposit = useSelector(state => state.BalanceReducer.min_deposit);
    const getSummOptions = () => {
        const array = [
            { id: 'all_summ_bets', name: 'Будь-яка сума ставки' },
            { id: 'less_10', name: '<10' },
            { id: 'more_10', name: '>10' },
            { id: 'more_50', name: '>50' },
            { id: 'more_100', name: '>100' },
            { id: 'less_500', name: '<500' },
            { id: 'less_1000', name: '<1000' },
        ];
        const newArray = array.filter(item => {
            const number = parseInt(item.name.replace(/[<>]=?/, ''), 10);
            return number >= minDeposit
        })
        return newArray
    }

    const sortingData = [
        {
            id: 1,
            title: 'Стан',
            filterType: 'state',
            options: [
                { id: 'all_transactions', name: 'Усі ставки' },
                { id: 'win', name: 'Виграш' },
                { id: 'loss', name: 'Програш' },
                { id: 'return', name: 'Повернено' },
                { id: 'unknown', name: 'Невизначено' },
            ],
        },
        {
            id: 2,
            title: 'Сума',
            filterType: 'summ',
            options: getSummOptions(),
        },
        {
            id: 3,
            title: 'Дата',
            filterType: 'date',
            options: [
                { id: 'all_date', name: 'Будь-яка дата' },
                { id: 'today_yesterday', name: 'Сьогодні та вчора' },
                { id: 'for_7_days', name: 'Протягом 7 днів' },
                { id: 'for_30_days', name: 'Протягом 30 днів' },
                { id: 'for_90_days', name: 'Протягом 90 днів' },
            ],
        },
    ]
    const [filters, setFilters] = useState({
        state: null,
        summ: null,
        date: null,
    })
    const filterHundler = (data) => {
        let filtersItems = data;
        if (filters.state && filters.state !== "all_transactions") {
            filtersItems = filtersItems.filter(item => item.status === filters.state)
        }
        if (filters.summ && filters.summ !== "all_summ_bets") {
            const name = sortingData.find(item => item.filterType === 'summ').options.find(option => option.id === filters.summ).name;
            const sign = name.match(/[<>]=?/);

            if (sign) {
                const number = parseInt(name.replace(/[<>]=?/, ''), 10);

                if (sign[0] === '<') {
                    filtersItems = filtersItems.filter(item => item.amount <= number);
                } else if (sign[0] === '>') {
                    filtersItems = filtersItems.filter(item => item.amount >= number);
                }
            }
        }
        if (filters.date && filters.date !== "all_date") {
            const date = sortingData.find(item => item.filterType === 'date').options.find(option => option.id === filters.date).name;
            const match = date.match(/(\d+) дн/);
            const daysAgo = match ? parseInt(match[1], 10) : null;

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0)

            if (filters.date === 'today_yesterday') {
                filtersItems = filtersItems.filter(item => {
                    const itemDate = new Date(item.placed_timestamp_utc);
                    itemDate.setHours(0, 0, 0, 0);

                    return itemDate >= currentDate || itemDate >= new Date(currentDate - 24 * 60 * 60 * 1000);
                });
            } else if (daysAgo !== null) {
                filtersItems = filtersItems.filter(item => {
                    const itemDate = new Date(item.placed_timestamp_utc);
                    itemDate.setHours(0, 0, 0, 0);

                    return itemDate >= new Date(currentDate - (daysAgo - 1) * 24 * 60 * 60 * 1000) &&
                        itemDate <= currentDate;
                });
            }
        }
        return filtersItems;
    }

    const dataTable = useSelector(state => state.BetReducer.historyBets);

    const paginationMaxItem = 10;
    const [currentPagePagination, setCurrentPagePagintaion] = useState(1);

    const pageRedirectMatchId = useSelector(state => state.BetReducer.myBetsPageRedirectMatchId)
    const [matchIndexOpen, setMatchIndexOpen] = useState(null)
    const [tableItems, setTableItems] = useState([]);
    useEffect(() => {
        const filtersItems = filterHundler(dataTable);
        const pages = Math.ceil(filtersItems.length / paginationMaxItem);
        let currentPageVar = currentPagePagination;
        if (pages < currentPagePagination) {
            currentPageVar = pages || 1;
            setCurrentPagePagintaion(pages || 1);
        }

        const startIndex = (currentPageVar - 1) * paginationMaxItem;
        const endIndex = Math.min(currentPageVar * paginationMaxItem, dataTable.length);

        setTableItems(filtersItems.slice(startIndex, endIndex))
        if (pageRedirectMatchId) {
            const currentMatch = filtersItems.slice(startIndex, endIndex).find(item => item.id === pageRedirectMatchId)
            const indexCurrentMatch = filtersItems.slice(startIndex, endIndex).indexOf(currentMatch);
            if (indexCurrentMatch >= 0) {
                setMatchIndexOpen(indexCurrentMatch)
            }
        }
    }, [dataTable, currentPagePagination, filters, pageRedirectMatchId])



    useEffect(() => {
        if (pageRedirectMatchId && dataTable.find(item => item.id === pageRedirectMatchId)) {
            let index = 0;
            for (; index < dataTable.length; index++) {
                const element = dataTable[index];
                if (element.id === pageRedirectMatchId) {
                    index++;
                    break;
                }
            }
            const decadeIndex = Math.ceil(index / paginationMaxItem);
            setCurrentPagePagintaion(decadeIndex);
        }
    }, [pageRedirectMatchId, dataTable])


    return (
        <MainLayout pageName="profile">
            <main className="content_block_wrapper small_width">
                <Breadcrumb pages={[
                    { name: "Мої ставки", routeName: '' },
                ]} prevPage={true} profile={true} />
                <section className='content_block'>
                    {
                        !!dataTable.length
                            ?
                            <div className="history_container history_table">
                                <PageSortHead
                                    setFilters={setFilters}
                                    headTitle="Мої ставки "
                                    sortingData={sortingData}
                                />
                                {
                                    !!dataTable.length &&
                                    (!!filterHundler(dataTable).length
                                        ?
                                        <HistoryDataTable dataTable={tableItems} matchIndexOpen={matchIndexOpen} />
                                        :
                                        <NoEvents
                                            svg={<FiltersEmpty />}
                                            svgLight={<FiltersEmptyLight />}
                                            title="На жаль, сортування не дало жодних результатів"
                                            subtitle="Можливо, немає об'єктів, що відповідають вашим критеріям. Будь ласка, перевірте параметри сортування або спробуйте інші, щоб отримати потрібні результати."
                                            buttonHide={true}
                                        />)
                                }
                                {
                                    filterHundler(dataTable).length > paginationMaxItem
                                        ?
                                        <Pagination
                                            currentPagePagination={currentPagePagination}
                                            setCurrentPagePagintaion={setCurrentPagePagintaion}
                                            paginationMaxItem={paginationMaxItem}
                                            items={filterHundler(dataTable)}
                                        />
                                        :
                                        <></>
                                }
                            </div>
                            :
                            <NoEvents
                                svg={<NoEventsIcon />}
                                svgLight={<NoEventsIconLight />}
                                title="Історія ставок порожня"
                                subtitle="Для наповнення історії зробить одну або декілька ставок."
                            />
                    }
                </section>
                <Footer />
                <AsideMenu />
            </main>
        </MainLayout>
    )
}

export default MyBets;
import React from 'react';

const FiltersEmptyLight = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="64" viewBox="0 0 55 64" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.83984 6.15552H52.8398C53.9438 6.15552 54.8398 5.25952 54.8398 4.15552V2.15552C54.8398 1.05152 53.9438 0.155518 52.8398 0.155518H2.83984C1.73584 0.155518 0.839844 1.05152 0.839844 2.15552V4.15552C0.839844 5.25952 1.73584 6.15552 2.83984 6.15552ZM21.7738 32.1555H33.9058L53.8398 9.15552H1.83984L21.7738 32.1555ZM21.8398 63.1555L33.8398 56.0155V35.1555H21.8398V63.1555ZM22.1043 21.8747C23.6365 20.3424 25.674 19.4985 27.8409 19.4985C30.008 19.4985 32.0453 20.3424 33.5776 21.8747C34.1239 22.421 34.1239 23.3067 33.5776 23.8529C33.0313 24.3991 32.1456 24.3991 31.5995 23.8529C30.5955 22.849 29.2607 22.2961 27.8409 22.2961C26.4212 22.2961 25.0864 22.8489 24.0824 23.8529C23.8094 24.126 23.4514 24.2626 23.0934 24.2626C22.7354 24.2626 22.3774 24.126 22.1043 23.8529C21.558 23.3066 21.558 22.4209 22.1043 21.8747ZM23.1914 16.4507C23.5393 16.1038 23.7379 15.6226 23.7379 15.1321C23.7379 14.6416 23.5393 14.1604 23.1914 13.8135C22.8445 13.4666 22.3634 13.2671 21.8729 13.2671C21.3814 13.2671 20.9012 13.4666 20.5543 13.8135C20.2074 14.1604 20.0078 14.6416 20.0078 15.1321C20.0078 15.6226 20.2074 16.1038 20.5543 16.4507C20.9012 16.7976 21.3823 16.9972 21.8729 16.9972C22.3634 16.9972 22.8445 16.7976 23.1914 16.4507ZM32.4898 13.8135C32.8367 13.4666 33.3169 13.2671 33.8083 13.2671C34.2989 13.2671 34.78 13.4666 35.1269 13.8135C35.4748 14.1604 35.6734 14.6416 35.6734 15.1321C35.6734 15.6226 35.4748 16.1038 35.1269 16.4507C34.78 16.7975 34.2989 16.9972 33.8083 16.9972C33.3178 16.9972 32.8367 16.7976 32.4898 16.4507C32.1419 16.1038 31.9433 15.6226 31.9433 15.1321C31.9433 14.6416 32.1419 14.1604 32.4898 13.8135Z" fill="#28292E" />
        </svg>
    )
}

export default FiltersEmptyLight;
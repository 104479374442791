const defaultState = {
    match: {}
}

const UPDATE_CURRENT_MATCH = 'UPDATE_CURRENT_MATCH';

export const CurrentMatchReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_CURRENT_MATCH:
            return { ...state, match: action.payload }
        default:
            return state;
    }
}

export const updateCurrentMatchAction = (payload) => ({ type: UPDATE_CURRENT_MATCH, payload });
import React, { useEffect } from 'react';
import "./DocumentsUpload.scss"
import MainLayout from 'layouts/MainLayout';
import Footer from 'components/Footer/Footer';
import AsideMenu from 'components/AsideMenu/AsideMenu';
import { useDispatch, useSelector } from 'react-redux';
import { updatePagesRedirectAction } from 'store/AccountReducer';
import { useNavigate, useParams } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ImagesUpload from '../../components/ImagesUpload/ImagesUpload';

const DocumentsUpload = () => {
    const params = useParams();
    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth === false) {
            dispatch(updatePagesRedirectAction('documents'));
            navigate(getRoute("login"));
        }
    }, [isAuth, dispatch, navigate])

    const documentsInfo = {
        passport: {
            breadcrumbTitle: "Паспорт",
            title: "Завантаж фото першого розвороту паспорта/ID картки.",
            imgFileName: 'passport.png',
        },
        'driving-license': {
            breadcrumbTitle: "Водійське посвідчення",
            title: "Завантаж фото лицьової сторони водійського посвідчення.",
            imgFileName: 'driversLicense.png',
        },
        'international-passport': {
            breadcrumbTitle: "Закордонний паспорт",
            title: "Завантаж фото закордоного паспорта, що засвідчує особу.",
            imgFileName: 'foreignPassport.png',
        },
    }

    const activeDocumentOpeartion = useSelector(state => state.DocumentsReducer.activeDocumentOpeartion);
    useEffect(() => {
        if (activeDocumentOpeartion?.status !== "fail" && activeDocumentOpeartion) {
            navigate(getRoute('documents'))
        }
    }, [activeDocumentOpeartion])

    return (
        <MainLayout pageName="documents">
            <main className="content_block_wrapper small_width document_upload_page">
                <Breadcrumb pages={[
                    { name: "Підтверди особу", routeName: 'documents' },
                    { name: documentsInfo[params.document].breadcrumbTitle, routeName: '' },
                ]} prevPage={true} profile={false} />
                <section className='content_block profile_content_block'>
                    <div className="img_wrapper">
                        <img src={require(`assets/documents/${documentsInfo[params.document].imgFileName}`)} alt="passport" />
                    </div>
                    <div className="inner_block">
                        <span className="title">
                            {documentsInfo[params.document].title}
                        </span>
                        <div className="criteria">
                            <p>Переконайтеся, що фото документа відповідає наступним критеріям:</p>
                            <ul>
                                <li>Фото чітке</li>
                                <li>Документ повністю у кадрі</li>
                                <li>Документ не перекрито відблиском чи пальцями</li>
                                <li>Фото кольорове та без обробки</li>
                                {
                                    params.document === 'foreignPassport'
                                        ?
                                        <li>Завантажити  першу сторінку</li>
                                        :
                                        <li>Завантажити  сторінки 1-2 і.т.д.</li>
                                }
                            </ul>
                        </div>
                        <ImagesUpload documentType={params.document} />
                    </div>
                </section>
                <Footer />
                <AsideMenu />
            </main>
        </MainLayout>
    )
}

export default DocumentsUpload;
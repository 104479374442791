const defaultState = {
    autoAcceptOdds: 'no',
    maxBetWin: '',
    minBetWin: '',
    betValues: [
        { id: 1, value: 100 },
        { id: 2, value: 300 },
        { id: 3, value: 500 },
        { id: 4, value: 1000 },
    ],
    depositValues: [
        { id: 1, value: 100 },
        { id: 2, value: 250 },
        { id: 3, value: 500 },
        { id: 4, value: 750 },
        { id: 5, value: 1000 },
    ],
    couponOdds: [],
    historyBets: [],
    myBetsPageRedirectMatchId: null,
}

const UPDATE_MIN_BET_WIN = 'UPDATE_MIN_BET_WIN';
const UPDATE_MAX_BET_WIN = 'UPDATE_MAX_BET_WIN';
const UPDATE_AUTO_ACCEPT_ODDS = 'UPDATE_AUTO_ACCEPT_ODDS';
const UPDATE_BET_VALUE = 'UPDATE_BET_VALUE';
const UPDATE_COUPON_ODDS = 'UPDATE_COUPON_ODDS';
const UPDATE_HISTORY_BETS = 'UPDATE_HISTORY_BETS';
const ADD_MY_BETS_PAGE_MATCH_ID = 'ADD_MY_BETS_PAGE_MATCH_ID';
const REMOVE_MY_BETS_PAGE_MATCH_ID = 'REMOVE_MY_BETS_PAGE_MATCH_ID';

export const BetReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_MIN_BET_WIN:
            return { ...state, minBetWin: action.payload }
        case UPDATE_MAX_BET_WIN:
            return { ...state, maxBetWin: action.payload }
        case UPDATE_AUTO_ACCEPT_ODDS:
            return { ...state, autoAcceptOdds: action.payload }
        case UPDATE_BET_VALUE:
            return { ...state, betValues: [...action.payload] }
        case UPDATE_COUPON_ODDS:
            return { ...state, couponOdds: action.payload };
        case UPDATE_HISTORY_BETS:
            return { ...state, historyBets: action.payload };
        case ADD_MY_BETS_PAGE_MATCH_ID:
            return { ...state, myBetsPageRedirectMatchId: action.payload };
        case REMOVE_MY_BETS_PAGE_MATCH_ID:
            return { ...state, myBetsPageRedirectMatchId: null };

        default:
            return state;
    }
}

export const updateMinBetWin = (payload) => ({ type: UPDATE_MIN_BET_WIN, payload });
export const updateMaxBetWin = (payload) => ({ type: UPDATE_MAX_BET_WIN, payload });
export const updateAutoAcceptOddsAction = (payload) => ({ type: UPDATE_AUTO_ACCEPT_ODDS, payload });
export const updateBetValuesAction = (payload) => ({ type: UPDATE_BET_VALUE, payload });
export const updateCouponOddsAction = (payload) => ({ type: UPDATE_COUPON_ODDS, payload });
export const updateHistoryBetsAction = (payload) => ({ type: UPDATE_HISTORY_BETS, payload });
export const addMyBetsPageMatchId = (payload) => ({ type: ADD_MY_BETS_PAGE_MATCH_ID, payload });
export const removeMyBetsPageMatchId = (payload) => ({ type: REMOVE_MY_BETS_PAGE_MATCH_ID, payload });
const defaultState = {
    searchValue: null,
}

const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';

export const SearchReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_SEARCH_VALUE:
            return { ...state, searchValue: action.payload }
        default:
            return state;
    }
}

export const setSearchValue = (payload) => ({ type: SET_SEARCH_VALUE, payload });
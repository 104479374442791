import React from 'react';
import BetSuccess from 'svg/bet/BetSuccess';
import BetUnSuccess from 'svg/bet/BetUnSuccess';
import Remove from 'svg/Remove';
import "./BalanceAlert.scss";

const BalanceAlert = ({success = true, title, subtitle, setAlert}) => {
    return (
        <div className="balance_alert_wrapper">
            <div className={'balance_alert' + (success ? '' : ' unsuccess')}>
                {
                    success ? <BetSuccess /> : <BetUnSuccess />
                }
                
                <div className='flex column justify_center'>
                    <span className='title'>
                        {title}
                    </span>
                    <span className="subtitle">
                        {subtitle}
                    </span>
                </div>
                <Remove onClick={() => setAlert(null)} />
            </div>
        </div>
    )
}

export default BalanceAlert;
import React, { useEffect, useRef } from 'react';
import "./DataTable.scss";
import moment from 'moment-timezone';
import { getTimezone } from 'utils/timezone';
import ArrowDown from 'svg/ArrowDown';
import { getOddName } from 'utils/odds';
import HistoryExpressIndex from 'svg/bet/HistoryExpressIndex';
import { useDispatch } from 'react-redux';
import { removeMyBetsPageMatchId } from 'store/BetReducer';

const HistoryDataTable = ({ dataTable, matchIndexOpen = null }) => {
    const accordionRefs = useRef([]);
    const dispatch = useDispatch()

    const accordionHundler = (index) => {
        const itemWrapper = accordionRefs.current[index];
        if (!itemWrapper) return;

        const accordion = Array.from(itemWrapper.children).find(item => item.classList.contains('accordion'));
        accordion.classList.toggle('open');
        if (accordion.classList.contains('open')) {
            accordion.style.maxHeight = `${accordion.scrollHeight + 16}px`;
        } else accordion.style.maxHeight = null;
    }

    useEffect(() => {
        if (matchIndexOpen === null) return
        accordionHundler(matchIndexOpen);
        dispatch(removeMyBetsPageMatchId())

    }, [accordionRefs, dispatch, matchIndexOpen])

    return (
        <div className='table_data'>
            {
                dataTable.map((item, index) =>
                    <div
                        className='flex column item_wrapper'
                        key={item.id}
                        ref={(el) => (accordionRefs.current[index] = el)}
                    >
                        <div className="item space_between">
                            <div className="col col-team">
                                <h5>
                                    {moment.utc(item.placed_timestamp_utc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('D MMMM')}&nbsp;
                                    {moment.utc(item.placed_timestamp_utc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('H:mm')}
                                </h5>
                                <span>
                                    {
                                        item.placed_bet_odds.length > 1
                                            ?
                                            <>
                                                Експрес {(item.total_odd).toFixed(2)}
                                            </>
                                            :
                                            <>
                                                {item.placed_bet_odds[0].sport_match.local_team.translation.title}
                                                &nbsp;-&nbsp;
                                                {item.placed_bet_odds[0].sport_match.visitor_team.translation.title}
                                            </>
                                    }
                                </span>
                            </div>
                            <div className="col col-small">
                                <h5>Стан</h5>
                                {
                                    item.status === 'processing'
                                        ?
                                        <span>Передано на реалізацію</span>
                                        :
                                        item.status === 'processing'
                                        ?
                                        <span>Сталася помилка</span>
                                        :
                                        <span>{item.status}</span>
                                }
                            </div>
                            <div className="col col-date">
                                <h5>Дата</h5>
                                <span>
                                    {moment.utc(item.placed_timestamp_utc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('D MMMM YYYY')}
                                    &nbsp;
                                    {moment.utc(item.placed_timestamp_utc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('H:mm')}
                                </span>
                            </div>
                            <div className="col col-small">
                                <h5>Коефіцієнт</h5>
                                <span>{(item.total_odd).toFixed(2)}</span>
                            </div>
                            <div className="col col-small">
                                <h5>Сума</h5>
                                <span>{(item.amount).toFixed(2)}</span>
                            </div>
                            <div className="col col-small">
                                <h5>Сума виплати</h5>
                                <span className={
                                    "payment" +
                                    (
                                        item.status === 'unknown'
                                            ?
                                            " unknown"
                                            :
                                            item.status === 'loss'
                                                ?
                                                " loss"
                                                :
                                                ""
                                    )
                                }
                                >
                                    {
                                        item.status === "unknown"
                                            ?
                                            'Невідомо'
                                            :
                                            (item.win_amount).toFixed(2)
                                    }
                                </span>
                            </div>
                            <ArrowDown className="accordion_btn" onClick={() => accordionHundler(index)} />
                        </div>
                        {
                            item.placed_bet_odds.length > 1
                                ?
                                <div className="accordion">
                                    {
                                        item.placed_bet_odds.map((bet, index) =>
                                            <div key={bet.id} className="item">
                                                <div className="col col-team">
                                                    <h5>
                                                        <HistoryExpressIndex />&nbsp;
                                                        {index + 1}.
                                                    </h5>
                                                    <span>
                                                        {bet.sport_match.local_team.translation.title}
                                                        &nbsp;-&nbsp;
                                                        {bet.sport_match.visitor_team.translation.title}
                                                    </span>
                                                </div>
                                                <div className="col col-medium">
                                                    <h5>Тип ставки</h5>
                                                    <span>{bet.odds_type.translation.title}</span>
                                                </div>
                                                <div className="col col-medium">
                                                    <h5>Ставка</h5>
                                                    <span>{getOddName(bet)}</span>
                                                </div>
                                                <div className="col col-medium">
                                                    <h5>Статус</h5>
                                                    <span>{bet.status}</span>
                                                </div>
                                                <div className="col col-medium">
                                                    <h5>Коефіцієнт</h5>
                                                    <span>{bet.formatted_value}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                :
                                <div className="accordion item">
                                    <div className="col col-medium">
                                        <h5>Тип ставки</h5>
                                        <span>{item.placed_bet_odds[0].odds_type.translation.title}</span>
                                    </div>
                                    <div className="col col-medium">
                                        <h5>Ставка</h5>
                                        <span>{getOddName(item.placed_bet_odds[0])}</span>
                                    </div>
                                </div>
                        }
                    </div>
                )
            }
        </div>
    )
}

export default HistoryDataTable;
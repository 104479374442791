import React from 'react';

const NoLiveEventsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="67" height="73" viewBox="0 0 67 73" fill="none">
            <path d="M32.4388 63.1143C32.4389 63.1143 32.4387 63.1143 32.4388 63.1143C30.5461 63.1143 28.7665 63.8514 27.4282 65.1898C26.951 65.6668 26.951 66.4404 27.4282 66.9176C27.6667 67.1561 27.9794 67.2755 28.2921 67.2755C28.6048 67.2755 28.9175 67.1561 29.156 66.9176C30.0329 66.0407 31.1987 65.5578 32.4388 65.5578C33.6789 65.5578 34.8448 66.0408 35.7216 66.9176C36.1987 67.3946 36.9723 67.3946 37.4494 66.9176C37.9266 66.4405 37.9266 65.6669 37.4494 65.1898C36.1111 63.8514 34.3316 63.1143 32.4388 63.1143Z" fill="white" />
            <path d="M28.3784 60.4521C28.6822 60.1491 28.8557 59.7288 28.8557 59.3004C28.8557 58.872 28.6822 58.4517 28.3784 58.1486C28.0754 57.8457 27.6551 57.6714 27.2267 57.6714C26.7974 57.6714 26.3779 57.8457 26.075 58.1486C25.772 58.4517 25.5977 58.872 25.5977 59.3004C25.5977 59.7288 25.772 60.1491 26.075 60.4521C26.3779 60.7551 26.7982 60.9294 27.2267 60.9294C27.6551 60.9294 28.0754 60.7551 28.3784 60.4521Z" fill="white" />
            <path d="M37.6524 57.6714C37.2232 57.6714 36.8037 57.8457 36.5007 58.1486C36.1969 58.4517 36.0234 58.872 36.0234 59.3004C36.0234 59.7288 36.1969 60.1491 36.5007 60.4521C36.8037 60.7551 37.224 60.9294 37.6524 60.9294C38.0809 60.9294 38.5011 60.755 38.8041 60.4521C39.1079 60.1491 39.2814 59.7288 39.2814 59.3004C39.2814 58.872 39.1079 58.4517 38.8041 58.1486C38.5011 57.8457 38.0809 57.6714 37.6524 57.6714Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.1698 0.843018C26.1208 1.25602 25.9198 3.61802 25.7228 6.09302C25.2368 12.189 23.0958 17.879 18.7038 24.745L15.0278 30.492L12.8928 26.094C10.1448 20.432 8.67281 20.058 7.79281 24.799C7.40581 26.886 5.66481 31.968 3.92381 36.093C1.37281 42.136 0.757812 44.969 0.757812 50.678C0.757812 56.92 1.11481 58.281 3.75781 62.104C7.10981 66.953 14.4258 72.093 17.9768 72.093C20.2478 72.093 20.2638 72.031 18.6138 69.514C15.6058 64.923 16.4218 59.117 21.2078 51.061C25.6648 43.558 27.1768 43.192 27.2718 49.593L27.3168 52.593L30.2158 49.407C31.8108 47.655 33.9048 43.717 34.8688 40.657C35.8338 37.597 36.9128 35.093 37.2658 35.093C38.4928 35.093 42.0848 39.616 44.3648 44.033C47.2018 49.526 47.9348 58.189 46.2398 66.176C45.5438 69.457 45.2628 72.431 45.6158 72.784C46.7608 73.929 56.8458 66.195 60.0778 61.694C69.8588 48.075 68.7478 31.883 57.1258 18.681C52.4818 13.404 50.2638 12.812 50.2438 16.843C50.2268 20.247 49.2388 24.102 48.3908 24.066C48.0428 24.051 46.4198 21.014 44.7848 17.316C41.2618 9.35002 32.6238 0.0930176 28.7128 0.0930176C27.3628 0.0930176 26.2178 0.430018 26.1698 0.843018Z" fill="white" />
        </svg>
    )
}

export default NoLiveEventsIcon;
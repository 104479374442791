import React from 'react';

const Confirmed = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
            <rect width="11.1721" height="1" rx="0.5" transform="matrix(0.709169 -0.705039 0.709169 0.705039 4.52576 8.69531)" fill="white" />
            <path d="M1.11667 4.6533C1.3125 4.45861 1.63001 4.45861 1.82584 4.6533L5.53641 8.34226C5.73224 8.53695 5.73224 8.85261 5.53641 9.0473C5.34057 9.24199 5.02307 9.24199 4.82724 9.0473L1.11667 5.35834C0.920836 5.16365 0.920836 4.84799 1.11667 4.6533Z" fill="white" />
        </svg>
    )
}

export default Confirmed;
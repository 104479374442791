import React from 'react';
import ArrowNext from 'svg/ArrowNext';

const NextSlider = ({ className, style, onClick }) => {
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <ArrowNext />
        </div>
    )
}

export default NextSlider;
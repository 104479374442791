import React, { useState } from 'react';
import "./ProfileBlock.scss"
import MyButton from '../MyButton/MyButton';
import ProfileIcon from 'svg/ProfileIcon';
import { Link } from 'react-router-dom';
import SignOut from 'svg/login/SignOut';
import { getRoute } from 'utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useLogOut } from 'hooks/useAccount';
import { updateBalanceCashTabAction } from 'store/AccountReducer';

const ProfileBlock = () => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.AccountReducer.userData);

    const [modal, setModal] = useState(false);

    const clickFunc = (e) => {
        if (!e.target.closest('.profile_block')) {
            setModal(false);
            document.removeEventListener('click', clickFunc)
        }
    }

    const modalHandler = (e) => {
        setModal(!modal)
        document.addEventListener('click', clickFunc)
    }

    const logOut = useLogOut();

    return (
        <div className={'profile_block flex align_center relative' + (modal ? " open" : '')}>
            <div className="modal_window flex column">
                <div className='flex align_center info_container'>
                    <Link to={getRoute('profile')} className='flex align_center'>
                        {
                            userData.avatar_url
                                ?
                                <img src={userData.avatar_url} alt="avatar" className='avatar_image' />
                                :
                                <ProfileIcon />
                        }
                        <div className='flex column name_wrapper'>
                            <span className="name" title={
                                userData.first_name
                                ?
                                userData.last_name
                                    ?
                                    userData.first_name + " " + userData.last_name
                                    :
                                    userData.first_name
                                :
                                userData.email
                            }>
                                {
                                    userData.first_name
                                        ?
                                        userData.last_name
                                            ?
                                            userData.first_name + " " + userData.last_name
                                            :
                                            userData.first_name
                                        :
                                        userData.email
                                }
                            </span>
                            <span className="user_id">id: {userData.id}</span>
                        </div>
                    </Link>
                    <div className='balance_wrapper'>
                        <span className="title">Баланс:</span>
                        <span className="balance" title={`${userData.balance} UAH`}>{userData.balance} UAH</span>
                    </div>
                </div>
                <hr />
                <div className="profile_items flex column">
                    <Link to={getRoute('personal-info')} className='item'>
                        Персональні дані
                    </Link>
                    <Link to={getRoute('documents')} className='item'>
                        Документи
                    </Link>
                    <Link to={getRoute('help-info')} className='item'>
                        Допомога та інформація
                    </Link>
                    <Link to={getRoute('my-bets')} className='item'>
                        Мої ставки
                    </Link>
                    <Link to={getRoute('balance')} className='item'>
                        Баланс
                    </Link>
                </div>
                <hr />
                <MyButton className="my_button medium" onClick={logOut}>
                    Вийти
                    <SignOut />
                </MyButton>
            </div>
            <div className='avatar flex pointer' onClick={modalHandler}>
                {
                    userData.avatar_url
                        ?
                        <img src={userData.avatar_url} alt="avatar" className='avatar_image' />
                        :
                        <ProfileIcon />
                }
            </div>
            <div className='flex column balance_wrapper hide-if_sm_size'>
                <span className='title'>Баланс:</span>
                <span className='balance'>{userData.balance} UAH</span>
            </div>
            <MyButton
                href={getRoute('balance')}
                onClick={() => dispatch(updateBalanceCashTabAction(false))}
                className="my_button small full_height active hide-if_sm_size"
                minWidth={true}
            >
                Поповинити
            </MyButton>
        </div>
    )
}

export default ProfileBlock;
import React from 'react';
import './MatchList.scss';
import Match from './Match/Match';
import { useSelector } from 'react-redux';

const MatchList = ({ currentCategory, currentLeague, live = false }) => {
    const leagueMatches = useSelector(state => state.FutureMatchesReducer.matches)
        .filter(match => match.sport_id === currentCategory.id && match.league_id === currentLeague.id)

    return (
        <div className="match_list flex column">
            <span className="list_title">
                {currentLeague.translation.title}
            </span>
            {
                leagueMatches.map(match =>
                    <Match
                        live={live}
                        match={match}
                        key={match.id}
                        currentCategory={currentCategory}
                    />
                )
            }
        </div>
    )
}

export default MatchList;
import React, { useEffect, useState } from 'react';
import "./PersonalInfo.scss"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updatePagesRedirectAction } from 'store/AccountReducer';
import { getRoute } from 'utils/routes';
import MainLayout from 'layouts/MainLayout';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Footer from 'components/Footer/Footer';
import AsideMenu from 'components/AsideMenu/AsideMenu';
import PersonalInfoTabMenu from 'components/PagePersonalInfo/PersonalInfoTabMenu/PersonalInfoTabMenu';
import { handleFooterScroll } from 'utils/footer';
import GeneralInfo from 'components/PagePersonalInfo/GeneralInfo';
import ChangePassword from 'components/PagePersonalInfo/ChangePassword';

const PersonalInfo = () => {
    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth === false) {
            dispatch(updatePagesRedirectAction('personal-info'));
            navigate(getRoute("login"));
        }
    }, [isAuth, navigate, dispatch])

    const [generalInfo, setGeneralInfo] = useState(1);
    useEffect(() => {
        handleFooterScroll()
    }, [generalInfo])


    return (
        <MainLayout pageName="personal-info">
            <main className="content_block_wrapper small_width personal-info_page">
                <Breadcrumb pages={[
                    { name: "Персональні дані", routeName: '' },
                ]} prevPage={true} profile={true} />
                <section className='content_block'>
                    <PersonalInfoTabMenu setActiveTab={setGeneralInfo} />
                    {
                        !!generalInfo
                            ?
                            <GeneralInfo />
                            :
                            <ChangePassword />
                    }
                </section>
                <Footer />
                <AsideMenu />
            </main >
        </MainLayout>
    )
}

export default PersonalInfo;
import React, { useEffect, useRef } from 'react';
import "./CategoryMatchesLive.scss";
import ArrowDown from 'svg/ArrowDown';
import MatchList from 'components/MatchList/MatchList';
import { handleFooterScroll } from 'utils/footer';

const CategoryMatchesLive = ({categoryMatches, categories}) => {
    const contentBlock = useRef();
    useEffect(() => {
        console.log(contentBlock);
        const items = contentBlock.current.querySelectorAll(".has_submenu");
        Array.from(items).forEach(element => {
            element.addEventListener("click", (e) => {
                e.target.closest(".item_live_wrapper").classList.toggle("active");
                handleFooterScroll();
            })
        })
    }, [])

    const category = categories.find(category => category.id === categoryMatches[0].categoryId);
    
    return (
        <div className="item_live_wrapper flex column active" ref={contentBlock}>
            <div className="item flex space_between align_center has_submenu">
                <div className="title_box flex align_center">
                    {category.icon}
                    <span className='item_title'>
                        {category.translation.title}
                    </span>
                </div>
                <ArrowDown />
            </div>
            <div className="item_submenu flex full_width column">
                <MatchList
                    live={true}
                    currentCategory={category}
                    listTitle='Ліга чемпіонів УЄФА. Груповий етап'
                />
            </div>
        </div>
    )
}

export default CategoryMatchesLive;
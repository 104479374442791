import React from 'react';

const NoSearchLight = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M45.9705 27.0614C45.9705 37.7581 37.2991 46.4295 26.6024 46.4295C15.9057 46.4295 7.23425 37.7581 7.23425 27.0614C7.23425 16.3646 15.9057 7.69323 26.6024 7.69323C37.2991 7.69323 45.9705 16.3646 45.9705 27.0614ZM42.5572 47.8498C38.1385 51.2461 32.6063 53.2653 26.6024 53.2653C12.1303 53.2653 0.398438 41.5334 0.398438 27.0614C0.398438 12.5893 12.1303 0.857422 26.6024 0.857422C41.0744 0.857422 52.8063 12.5893 52.8063 27.0614C52.8063 33.0653 50.7872 38.5975 47.3908 43.0162L62.059 57.6843C63.3937 59.0191 63.3937 61.1832 62.059 62.518C60.7242 63.8527 58.5601 63.8527 57.2253 62.518L42.5572 47.8498Z" fill="#313439" />
        </svg>
    )
}

export default NoSearchLight;
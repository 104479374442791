import React from 'react';

const SidebarCloseAll = ({...props}) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.1165 31.7109C7.51364 31.7109 0.539642 24.8082 0.539642 16.2932C0.539642 7.77824 7.51364 0.875488 16.1165 0.875488C24.7194 0.875488 31.6934 7.77824 31.6934 16.2932C31.6934 24.8082 24.7194 31.7109 16.1165 31.7109ZM11.9355 10.3511L16.2195 14.635L20.2962 10.5583L21.7104 11.9725L17.6337 16.0492L22.1968 20.6123L20.7826 22.0265L16.2195 17.4634L11.4474 22.2355L10.0332 20.8213L14.8053 16.0492L10.5213 11.7653L11.9355 10.3511Z" fill="#6E7074" />
        </svg>
    )
}

export default SidebarCloseAll;
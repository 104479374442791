import PostService from "api/PostService";
import TemplateTotal from "components/PageMatch/BetsContainer/OddsTypesTemplate/TemplateTotal";
import Handicap from "components/PageMatch/BetsContainer/OddsTypesTemplate/Handicap";
import TemplateCommon from "components/PageMatch/BetsContainer/OddsTypesTemplate/TemplateCommon";
import { OddsTypesConst } from "constants/OddsTypesConst";
import { useDispatch, useSelector } from "react-redux";
import { updateCouponOddsAction } from "store/BetReducer";
import CorrectScore from "components/PageMatch/BetsContainer/OddsTypesTemplate/CorrectScore";

const useOddsText = () => {
    const currentMatch = useSelector(state => state.CurrentMatchReducer.match);

    return (oddTypeId) => {
        switch (oddTypeId) {
            case 1:
                return [
                    { name: "home", title: currentMatch.local_team.translation.title },
                    { name: "draw", title: "Нічия" },
                    { name: "away", title: currentMatch.visitor_team.translation.title },
                ]
            case 23:
                return [
                    { name: "home", title: `${currentMatch.local_team.translation.title} (Так)` },
                    { name: "away", title: `${currentMatch.visitor_team.translation.title} (Так)` },
                ]
            case 14:
                return [
                    { name: "home", title: currentMatch.local_team.translation.title },
                    { name: "draw", title: "Без гола" },
                    { name: "away", title: currentMatch.visitor_team.translation.title },
                ]
            case 30:
                return [
                    { name: "home", title: `${currentMatch.local_team.translation.title}` },
                    { name: "away", title: `${currentMatch.visitor_team.translation.title}` },
                ]
            case 3:
                return [
                    { name: "home", title: currentMatch.local_team.translation.title },
                    { name: "draw", title: "Нічия" },
                    { name: "away", title: currentMatch.visitor_team.translation.title },
                ]
            case 13:
                return [
                    { name: "home", title: currentMatch.local_team.translation.title },
                    { name: "draw", title: "Нічия" },
                    { name: "away", title: currentMatch.visitor_team.translation.title },
                ]
            case 9:
                return [
                    { name: "yes", title: 'Так' },
                    { name: "no", title: 'Ні' },
                ]
            case 21:
                return [
                    { name: "yes", title: 'Так' },
                    { name: "no", title: 'Ні' },
                ]
            case 22:
                return [
                    { name: "yes", title: 'Так' },
                    { name: "no", title: 'Ні' },
                ]
            case 12:
                return [
                    { name: "Home/Draw", title: '1X' },
                    { name: "Home/Away", title: "12" },
                    { name: "Draw/Away", title: 'X2' },
                ]
            case 19:
                return [
                    { name: "Home/Draw", title: '1X' },
                    { name: "Home/Away", title: "12" },
                    { name: "Draw/Away", title: 'X2' },
                ]
            case 20:
                return [
                    { name: "Home/Draw", title: '1X' },
                    { name: "Home/Away", title: "12" },
                    { name: "Draw/Away", title: 'X2' },
                ]
            default:
                return;
        }
    }
}

export const useMatchBetsBody = () => {
    const currentMatch = useSelector(state => state.CurrentMatchReducer.match);
    const getOddText = useOddsText();

    return (oddsTypeId, oddsGroup) => {
        if (oddsTypeId === OddsTypesConst.goals_over_under || oddsTypeId === OddsTypesConst.goals_over_under_1_half || oddsTypeId === OddsTypesConst.goals_over_under_2_half) {
            return <TemplateTotal
                match={currentMatch}
                oddTypeId={oddsTypeId}
                oddsGroup={oddsGroup}
            />
        } else if (oddsTypeId === OddsTypesConst.handicap || oddsTypeId === OddsTypesConst.handicap_1_half || oddsTypeId === OddsTypesConst.handicap_2_half) {
            return <Handicap
                match={currentMatch}
                oddTypeId={oddsTypeId}
                oddsGroup={oddsGroup}
            />
        } else if (oddsTypeId === OddsTypesConst.correct_score || oddsTypeId === OddsTypesConst.correct_score_1_half) {
            return <CorrectScore
                match={currentMatch}
                oddTypeId={oddsTypeId}
                oddsGroup={oddsGroup}
            />
        } else if (Object.keys(OddsTypesConst).find(key => OddsTypesConst[key] === oddsTypeId) && getOddText(oddsTypeId)) {
            return <TemplateCommon
                match={currentMatch}
                oddTypeId={oddsTypeId}
                oddsGroup={oddsGroup}
                sortOrder={getOddText(oddsTypeId)}
            />
        } else return <></>
    }
}



export const useUpdateOdds = () => {
    const dispatch = useDispatch();
    const couponOdds = useSelector(state => state.BetReducer.couponOdds);

    return (response) => {
        const coupon_odds = response.data.coupon_odds;

        if (coupon_odds.length) {
            const updatedCouponOdds = couponOdds.map(bet => {
                const currentUpdateBet = coupon_odds.find(updateBet => updateBet.id === bet.id);
                const { formatted_value, value, ...another_field } = currentUpdateBet;

                const betFormattedValue = bet.formatted_value;
                return {
                    ...another_field,
                    formatted_value: currentUpdateBet.formatted_value,
                    prevFormattedValue: betFormattedValue,
                    value: currentUpdateBet.value,
                };
            });
            dispatch(updateCouponOddsAction(updatedCouponOdds));
        } else {
            dispatch(updateCouponOddsAction([]));
        }
    }
}

export const useAddBet = () => {
    const dispatch = useDispatch();
    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);

    return (odd, match = null) => {
        if (match?.is_stopped || !!odd?.stop) {
            console.log('Bet is block!');
            return;
        }
        if (!isAuth) {
            document.querySelector("body").classList.add("no_scroll");
            document.querySelector('.auth-modal').classList.remove('hide');
            return;
        }
        if (odd) {
            odd.match = match;
            const data = { odd_id: odd.id };
            PostService.storeBetOdd(data)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(updateCouponOddsAction(response.data.coupon_odds))
                    }
                })
        }
    }
}



export const useCheckBet = () => {
    const couponOdds = useSelector(state => state.BetReducer.couponOdds);
    const balance = useSelector(state => state.AccountReducer.userData.balance);

    const maxBetWin = useSelector(state => state.BetReducer.maxBetWin);
    const minBetWin = useSelector(state => state.BetReducer.minBetWin);

    return (value, backendBetErrors = [], setBackendBetErrors) => {
        const coefValue = couponOdds.reduce((maxValue, obj) => (maxValue * obj.value), 1);
        const result = value * coefValue;

        let message = [];

        if (result > maxBetWin) {
            message = [{ type: 'max_amount', message: 'Перевищано ліміт на суму данної ставки' }]
        } else if (value < minBetWin && value.length) {
            message = [{ type: 'min_amount', message: 'Менше ніж мінімальна сумма ставки' }]
        }

        let newBackendError = backendBetErrors;
        if (backendBetErrors.find(obj => obj.type === "invalid_coupon") && couponOdds.length) {
            newBackendError = backendBetErrors.filter(obj => obj.type !== "invalid_coupon")
            setBackendBetErrors(newBackendError)
        }

        if (newBackendError.find(obj => obj.type === message[0]?.type)) {
            const newError = newBackendError.filter(obj => !message.some(secondObj => secondObj.type === obj.type))
            setBackendBetErrors(newError)
            return [...message, ...newError]
        } else {
            if (Number(value) <= Number(balance)) {
                const newError = newBackendError.filter(obj => obj.type !== 'balance')
                setBackendBetErrors(newError)
                return [...message, ...newError]
            }
            return [...message, ...newBackendError]
        }
    }
}

export const useSetMaxBet = () => {
    const balance = useSelector(state => state.AccountReducer.userData.balance);
    const couponOdds = useSelector(state => state.BetReducer.couponOdds);

    const maxBetWin = useSelector(state => state.BetReducer.maxBetWin);
    const minBetWin = useSelector(state => state.BetReducer.minBetWin);

    return (backendBetErrors = [], setBackendBetErrors) => {
        const coefValue = couponOdds.reduce((maxValue, obj) => (maxValue * obj.value), 1);
        const maxBetWinFloor = Math.floor(maxBetWin);
        const result = (maxBetWinFloor / coefValue);

        let message = [];
        let maxBet = null;

        if (result >= minBetWin) {
            if (balance >= result) {
                maxBet = result;
            } else if (balance >= minBetWin) {
                maxBet = balance;
            } else {
                maxBet = balance;
                message = [{ type: 'min_amount', message: 'Менше ніж мінімальна сумма ставки' }]
            }
        } else {
            maxBet = result;
            message = [{ type: 'min_amount', message: 'Менше ніж мінімальна сумма ставки' }]
        }

        maxBet = Math.floor(maxBet * 100) / 100;
        if (backendBetErrors.find(obj => obj.type === message[0]?.type)) {
            const newBackendError = backendBetErrors.filter(obj => !message.some(secondObj => secondObj.type === obj.type))
            setBackendBetErrors(newBackendError);
            return { maxBet, error: [...message, ...newBackendError] }
        } else {
            if (maxBet <= Number(balance)) {
                const newBackendError = backendBetErrors.filter(obj => obj.type !== 'balance')
                setBackendBetErrors(newBackendError)
                return { maxBet, error: [...message, ...newBackendError] }
            }
            return { maxBet, error: [...message, ...backendBetErrors] }
        }
    }
}

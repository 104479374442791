import React from 'react';
import "./CoefBox.scss";
import { useSelector } from 'react-redux';
import { useAddBet } from 'hooks/useOdds';
import Block from 'svg/bet/Block';

const CoefBox = ({ coefTitle, value = null, odd = null, match = null }) => {
    const couponOdds = useSelector(state => state.BetReducer.couponOdds);
    const addBet = useAddBet();

    const matchStopped = match ? !!match.is_stopped : false;

    return (
        <div
            className={
                "coef_box" +
                (coefTitle === 'Т' ? ' T-block' : '') +
                (couponOdds.find(bet => bet.id === odd?.id) ? ' active' : '') +
                (matchStopped || !!odd?.stop || !!odd?.expired ? " is_stoped" : "")
            }
            onClick={() => addBet(odd, match)}
        >
            <span className='coefTitle'>
                {coefTitle}
            </span>
            <span className="value">
                {
                    matchStopped || !!odd?.stop || !!odd?.expired
                        ?
                        <Block />
                        :
                        odd ? odd.formatted_value : value
                }
            </span>
        </div>
    )
}

export default CoefBox;
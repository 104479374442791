import React, { useEffect, useState } from 'react';
import "./FilterMenu.scss";
import FiltersClear from 'svg/FiltersClear';
import ArrowPrev from 'svg/ArrowPrev';
import Filters from './Filters/Filters';
import SidebarCloseAll from 'svg/SidebarCloseAll';
import { closeAllSidebars, sidebarBack } from 'utils/sidebar';
import SidebarBack from 'svg/SidebarBack';
import { useDispatch } from 'react-redux';
import { updateFilterLeagueIdAction } from 'store/FutureMatchesReducer';

const FilterMenu = React.forwardRef(({ leaguesGroups, currentCategoryId, hideFilterMenu, sidebar = false }, ref) => {
    const listener = function (e) {
        e.target.closest(".filter").classList.toggle("active");
    };

    useEffect(() => {
        const filters = ref.current.querySelectorAll(".has_submenu");

        Array.from(filters).forEach(element => {
            element.addEventListener("click", listener);
        })

        return () => {
            Array.from(filters).forEach(element => {
                element.removeEventListener("click", listener);
            });
        }
    }, [leaguesGroups, ref])

    const dispatch = useDispatch()
    const clearFilters = () => {
        dispatch(updateFilterLeagueIdAction([]))

        setLeaguesValue(prevLeaguesValue => {
            return prevLeaguesValue.map(categoryFilters => {
                if (categoryFilters.id === currentCategoryId) {
                    return {
                        ...categoryFilters,
                        filters: categoryFilters.filters.map(filter => {
                            return {
                                ...filter,
                                leagues: filter.leagues.map(league => {
                                    return {
                                        ...league,
                                        value: false,
                                    }
                                })
                            }
                        })
                    }
                }
                return categoryFilters;
            });
        });
    }

    const [leaguesValue, setLeaguesValue] = useState([]);
    useEffect(() => {
        if (
            !(leaguesValue.find(item => item.categoryId === currentCategoryId))
            && currentCategoryId
            && leaguesGroups?.length
        ) {
            setLeaguesValue(prevState => (
                [...prevState, {
                    id: currentCategoryId, filters: leaguesGroups.map(item => (
                        {
                            filterId: item.id, leagues: item.leagues.map(league => {
                                league.value = false; 
                                return league;
                            })
                        }
                    ))
                }]
            ));
        }
    }, [currentCategoryId])

    return (
        <div className="filter_menu" ref={ref}>
            <div className='flex align_center space_between'>
                <SidebarBack className="sidebar_button hide block-if_lg_size" onClick={() => sidebarBack('.filter_sidebar')} />
                <div
                    className='pointer flex align_center filter_clear_wrapper full_width width_auto-if_lg_size'
                    onClick={clearFilters}
                >
                    <FiltersClear />
                    <span className='filter_clear'>Очищення фільтрів</span>
                    <div className="close_filter_menu pointer flex hide-if_lg_size" onClick={hideFilterMenu} >
                        <ArrowPrev />
                    </div>
                </div>

                <SidebarCloseAll className="sidebar_button hide block-if_lg_size" onClick={closeAllSidebars} />
            </div>
            <Filters
                sidebar={sidebar}
                leaguesGroups={leaguesGroups}
                leaguesValue={leaguesValue}
                setLeaguesValue={setLeaguesValue}
                currentCategoryId={currentCategoryId}
            />
        </div>
    )
})

export default FilterMenu;
import React from 'react';

const Chat = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.16265 11.7683C5.76259 11.7683 4.62765 12.9032 4.62455 14.307C4.62765 15.7071 5.76259 16.8451 7.16265 16.8451C8.56643 16.8451 9.70448 15.7071 9.70448 14.307C9.70448 12.9039 8.56581 11.7683 7.16265 11.7683ZM13.9984 11.7683C12.5952 11.7683 11.4603 12.9032 11.4603 14.307C11.4603 15.7071 12.5952 16.8451 13.9984 16.8451C15.4028 16.8451 16.5377 15.7071 16.5377 14.307C16.5377 12.9039 15.4028 11.7683 13.9984 11.7683ZM20.8317 11.7683C19.4316 11.7683 18.2936 12.9032 18.2936 14.307C18.2936 15.7071 19.4316 16.8451 20.8317 16.8451C22.2348 16.8451 23.3704 15.7071 23.3704 14.307C23.3704 12.9039 22.2348 11.7683 20.8317 11.7683ZM13.9984 0.864624C21.4426 0.864624 27.4414 6.666 27.4414 14.307C27.4414 17.977 26.0538 21.2204 23.7932 23.6001L24.5954 27.1962C24.8598 28.3796 24.0391 29.1755 22.9755 28.5832L19.4639 26.6244C17.795 27.3489 15.9492 27.7494 13.9984 27.7494C6.55172 27.7494 0.556623 21.9517 0.556623 14.307C0.556623 6.666 6.55172 0.864624 13.9984 0.864624Z" fill="#3D3F44" />
        </svg>
    )
}

export default Chat;
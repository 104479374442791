import React from 'react';
import './MainSlider.scss';
import Slider from 'react-slick';
import NextSlider from 'components/UI/SliderArrows/NextSlider';
import PrevSlider from 'components/UI/SliderArrows/PrevSlider';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getRoute } from 'utils/routes';

const MainSlider = () => {
    const ligthMode = useSelector(state => state.ThemeReducer.ligthMode);
    const slides = useSelector(state => state.MainSliderReducer.slides);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
        nextArrow: <NextSlider />,
        prevArrow: <PrevSlider />
    }

    return !!slides.length && (
        <Slider {...settings} className='main_slider'>
            <div>
                <div className='slide flex column justify_end'>
                    <video
                        poster={require('assets/main_slider/video_poster.jpg')}
                        type="video/mp4"
                        src={require('assets/main_slider/video.mp4')}
                        autoPlay
                        muted
                        playsInline
                        className={ligthMode ? "hide" : ""}
                    >
                        {/* <source src={require('assets/main_slider/video.mp4')} type="video/mp4" /> */}
                    </video>
                    <video
                        poster={require('assets/main_slider/video_poster_light.jpg')}
                        type="video/mp4"
                        src={require('assets/main_slider/video_light.mp4')}
                        autoPlay
                        muted
                        playsInline
                        className={!ligthMode ? "hide" : ""}
                    >
                        {/* <source src={require('assets/main_slider/video_light.mp4')} type="video/mp4" /> */}
                    </video>
                    <Link to={getRoute('info-page', ['about-us'])} className='read_more'>
                        <span>
                            Докладніше
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.1873 4.1861C9.85221 3.85105 9.30899 3.85105 8.97394 4.1861C8.6389 4.52115 8.6389 5.06436 8.97394 5.39941L12.6574 9.08282H3.57474H2.7168V10.7987H3.57474H12.6566L8.97394 14.4814C8.6389 14.8164 8.6389 15.3596 8.97394 15.6947C9.30899 16.0297 9.85221 16.0297 10.1873 15.6947L15.3349 10.5471C15.6699 10.212 15.6699 9.66879 15.3349 9.33374L10.1873 4.1861Z" fill="white" />
                        </svg>
                    </Link>
                </div>
            </div>
            {
                slides.map((slide, i) =>
                    <div key={i}>
                        <div
                            className='slide flex column space_between'
                            style={{
                                background: ligthMode
                                    ?
                                    `linear-gradient(0deg, rgba(255, 255, 255, 0.26) -9.13%, rgba(255, 255, 255, 0.78) 100%), url(${slide.image_url}) center / cover no-repeat`
                                    :
                                    `linear-gradient(0deg, rgba(49, 51, 57, 0.26) 0%, #313339 100%), url(${slide.image_url}) center / cover no-repeat`
                            }}
                        >
                            <div className='text_wrapper'>
                                {slide.translation.title && (
                                    <h1>{slide.translation.title}</h1>
                                )}
                                <p>{slide.translation.subtitle}</p>
                            </div>
                            {(slide.translation.link_href || slide.link) && (
                                <Link to={slide.translation.link_href ? slide.translation.link_href : slide.link} className='read_more'>
                                    <span>
                                        {slide.translation.link_text}
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.1873 4.1861C9.85221 3.85105 9.30899 3.85105 8.97394 4.1861C8.6389 4.52115 8.6389 5.06436 8.97394 5.39941L12.6574 9.08282H3.57474H2.7168V10.7987H3.57474H12.6566L8.97394 14.4814C8.6389 14.8164 8.6389 15.3596 8.97394 15.6947C9.30899 16.0297 9.85221 16.0297 10.1873 15.6947L15.3349 10.5471C15.6699 10.212 15.6699 9.66879 15.3349 9.33374L10.1873 4.1861Z" fill="white" />
                                    </svg>
                                </Link>
                            )}

                        </div>
                    </div>
                )
            }
        </Slider>
    )
}

export default MainSlider;
import React, { useEffect, useRef, useState } from 'react';
import "./CouponSidebar.scss";
import Coupon from 'components/AsideMenu/Coupon/Coupon';
import Bet from 'components/AsideMenu/Bet/Bet';
import { useSelector } from 'react-redux';

const CouponSidebar = () => {
    const betBlock = useRef();

    const couponOdds = useSelector(state => state.BetReducer.couponOdds);
    const [betCards, setBetCards] = useState(couponOdds)

    useEffect(() => {
        setBetCards(couponOdds)
    }, [couponOdds])

    return (
        <aside className='sidebar coupon_sidebar flex column'>
            <Coupon
                betCards={betCards}
                setBetCards={setBetCards}
                ref={betBlock}
            />
            <Bet
                ref={betBlock}
                betCards={betCards}
                setBetCards={setBetCards}
            />
        </aside>
    )
}

export default CouponSidebar;
export const OddsTypesConst = {
    match_winner: 1,
    goals_over_under: 5,
    handicap: 6,
    win_to_nil: 23,
    team_to_score_first: 14,
    team_to_score_in_both_halves: 30,
    second_half_winner: 3,
    first_half_winner: 13,

    // asian_handicap: 4,
    // asian_handicap_1_half: 17,
    // asian_handicap_2_half: 24,

    goals_over_under_1_half: 7,
    goals_over_under_2_half: 8,
    both_teams_to_score: 9,
    both_teams_to_score_1_half: 21,
    both_teams_to_score_2_half: 22,
    correct_score: 10,
    correct_score_1_half: 11,

    double_chance: 12,
    double_chance_1_half: 19,
    double_chance_2_half: 20,
    
    handicap_1_half: 18,
    handicap_2_half: 25,

    // total_home: 15,
    // total_home_1_half: 26,
    // total_home_2_half: 28,
    // total_away: 16,
    // total_away_1_half: 27,
    // total_away_2_half: 29,
    // odd_even: 31,
    // odd_even_1_half: 32,
    // odd_even_2_half: 33,
}
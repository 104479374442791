import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import "./BetsContainer.scss";
import BetsBlockTitle from './BetsBlockTitle';
import { handleFooterScroll } from 'utils/footer';
import { OddsTypesConst } from 'constants/OddsTypesConst';
import { useMatchBetsBody } from 'hooks/useOdds';

const BetsContainer = ({ oddsGroups }) => {
    const betsContainer = useRef();
    const getMatchBetsBody = useMatchBetsBody();
    const oddsTypes = useSelector(state => state.OddsTypesReducer.odds_types);

    useEffect(() => {
        const betsItems = betsContainer.current.querySelectorAll(".has_submenu > svg");
        Array.from(betsItems).forEach(element => {
            element.addEventListener("click", (e) => {
                e.target.closest(".item_wrapper").classList.toggle("active");
                handleFooterScroll();
            })
        })
    }, [])

    return (
        <div className="bets_container flex column" ref={betsContainer}>
            {
                Object.entries(oddsGroups).map(([oddTypeId, oddsGroup]) => {
                    let oddsType = oddsTypes.find(type => type.id == oddTypeId);
                    if (!Object.values(OddsTypesConst).includes(oddsType.id)) return;

                    oddsGroup.sort((a, b) =>
                        parseFloat(a.group_param ? a.group_param : 0) -
                        parseFloat(b.group_param ? b.group_param : 0)
                    );

                    return (
                        <div
                            key={oddsType.id}
                            className="item_wrapper flex column active"
                        >
                            <BetsBlockTitle item_title={oddsType.translation.title} item_description={oddsType.translation.description} />
                            {getMatchBetsBody(oddsType.id, oddsGroup)}
                        </div>
                    )
                })
            }

        </div >
    )
}

export default BetsContainer;
import React, { useEffect, useState } from 'react';
import "./MatchPage.scss";
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Footer from 'components/Footer/Footer';
import AsideMenu from 'components/AsideMenu/AsideMenu';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import MatchHeader from 'components/PageMatch/MatchHeader/MatchHeader';
import TabMenu from 'components/PageMatch/TabMenu/TabMenu';
import BetsContainer from 'components/PageMatch/BetsContainer/BetsContainer';
import { updateParamAction } from 'store/CategoryReducer';
import { getRoute } from 'utils/routes';
import MainLayout from 'layouts/MainLayout';

const MatchPage = () => {
    const dispatch = useDispatch();
    const loadingPage = useSelector(state => state.LoadingReducer.loading);
    const categories = useSelector(state => state.CategoryReducer.categories);
    const params = useParams();
    const currentCategory = categories.find(category => category.slug === params.category);

    const currentMatch = useSelector(state => state.CurrentMatchReducer.match);
    const [oddsList, setOddsList] = useState(currentMatch.odds_groups || {})

    useEffect(() => {
        dispatch(updateParamAction(params.category));
    }, [])

    useEffect(() => () => {
        dispatch(updateParamAction(''));
    }, [])

    return (
        <MainLayout pageName="matches" pageSlug={params.match}>
            {!loadingPage && (
                currentMatch.id != params.match ? (<Navigate to={getRoute("error-404")} />) : (
                    <main className="content_block_wrapper small_width">
                        <Breadcrumb pages={[
                            { name: currentCategory.translation.title, routeName: 'category', param: [currentCategory.slug] },
                            { name: `${currentMatch.local_team.translation.title} x ${currentMatch.visitor_team.translation.title}`, routeName: '' },
                        ]} prevPage={true} />
                        <section className='content_block'>
                            <MatchHeader
                                currentCategory={currentCategory}
                                match={currentMatch}
                            />
                            <TabMenu
                                setOddsList={setOddsList}
                                oddsGroups={currentMatch.odds_groups}
                            />
                            <BetsContainer oddsGroups={oddsList} />
                        </section>
                        <Footer />
                        <AsideMenu />
                    </main>
                )
            )}



        </MainLayout>
    )
}

export default MatchPage;
import React, { useState } from 'react';
import "./MySelect.scss";
import ArrowDown from 'svg/ArrowDown';

const MySelect = ({ title, options, filterType, setFilters }) => {

    const clickFunc = (e) => {
        if (!e.target.closest('.sort_block')) {
            if (!document.querySelector('.history_table')) {
                return
            }
            const sortBlocks = document.querySelector('.history_table').querySelectorAll('.sort_block');
            Array.from(sortBlocks).forEach(element => element.classList.remove('open'))
            document.removeEventListener('click', clickFunc)
        }
    }

    const authHandler = (e) => {
        const sortBlocks = document.querySelector('.history_table').querySelectorAll('.sort_block');
        Array.from(sortBlocks).forEach(element => {
            if (e.currentTarget.parentElement !== element) {
                element.classList.remove('open')
            }
        })

        e.currentTarget.parentElement.classList.toggle('open');
        if (e.currentTarget.parentElement.classList.contains('open')) {
            document.addEventListener('click', clickFunc)
        } else document.removeEventListener('click', clickFunc)
    }



    const resultObject = options.reduce((acc, current) => {
        acc[current.id] = false;
        return acc;
    }, {});

    const [values, setValues] = useState(resultObject)

    const changeValues = (e) => {
        const sortBlocks = document.querySelector('.history_table').querySelectorAll('.sort_block');
        Array.from(sortBlocks).forEach(element => {
            if (e.currentTarget.parentElement !== element) {
                element.classList.remove('open')
            }
        })
        
        const { id, checked } = e.target;

        setFilters(prevFilters => ({
            ...prevFilters,
            [filterType]: checked ? id : null,
        }));

        setValues((prevValues) => {
            const newValues = { ...resultObject };
            newValues[id] = checked;

            return newValues;
        });
    }

    return (
        <div className='sort_block'>
            <div
                className='sort_btn'
                onClick={(e) => authHandler(e)}
            >
                <span>{title}</span>
                <ArrowDown />
            </div>
            <div className="sort_window">
                {
                    options.map(option =>
                        <label
                            key={option.id}
                            htmlFor={option.id}
                            className='flex align_center input_wrapper'
                        >
                            <input
                                type="checkbox"
                                name='checkbox'
                                id={option.id}
                                onChange={changeValues}
                                checked={values[option.id] ? true : false}
                            />
                            <span className='input_title'>{option.name}</span>
                        </label>
                    )
                }
            </div>
        </div>
    )
}

export default MySelect;
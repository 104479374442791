import React, { useRef } from 'react';
import "./FilterSidebar.scss";
import FilterMenu from 'components/NavMenu/FilterMenu/FilterMenu';
import { useSelector } from 'react-redux';
import { openSidebar } from 'utils/sidebar';

const FilterSidebar = () => {
    const filterMenuRef = useRef();
    const categoryParam = useSelector(state => state.CategoryReducer.categoryParam);
    const categories = useSelector(state => state.CategoryReducer.categories)
    const currentCategoryId = categories.find(category => category.slug === categoryParam)?.id;

    const leaguesGroups = categories.filter(item => item.id === Number(currentCategoryId))[0]?.leagues_groups;

    return (
        <aside className='sidebar filter_sidebar flex column'>
            <FilterMenu
                sidebar={true}
                hideFilterMenu={e => openSidebar(e, ".filter_sidebar")}
                ref={filterMenuRef}
                leaguesGroups={leaguesGroups}
                currentCategoryId={currentCategoryId}
            />
        </aside>
    )
}

export default FilterSidebar;
import React from 'react';
import Remove from 'svg/Remove';
import './BetCard.scss';
import Live from 'svg/Live';
import Clock from 'svg/bet/Clock';
import { getOddName } from 'utils/odds';
import Block from 'svg/bet/Block';

const BetCard = ({ remove, item, live = false, ...props }) => {
    return (
        <div className="bet_cart flex column">
            <div className="flex space_between">
                <span className="title">
                    {item.sport_match.local_team.translation.title} - {item.sport_match.visitor_team.translation.title}
                </span>
                <Remove onClick={() => remove(item)} />
            </div>
            <hr />
            {
                live &&
                <div className="live_wrapper flex space_between align_center">
                    <div className="live flex align_center">
                        <Live />
                        <span>Live</span>
                    </div>
                    <div className="time flex align_center">
                        <Clock />
                        <span>91'</span>
                    </div>
                </div>
            }
            <span className="subtitle">
                {item.odds_type.translation.title}
            </span>
            <div className='flex space_between'>
                <span className="odd_name">
                    {getOddName(item)}
                </span>
                <span className={
                    "coef " + (
                        item.prevFormattedValue !== undefined
                            &&
                            Number(item.formatted_value) > Number(item.prevFormattedValue)
                            ?
                            " rais"
                            :
                            Number(item.formatted_value) < Number(item.prevFormattedValue)
                            &&
                            " fall"
                    )
                }>
                    {
                        item.stop || item.sport_match.is_stopped || !!item.expired
                            ?
                            <Block />
                            :
                            <>
                                {
                                    item.prevFormattedValue !== undefined
                                        &&
                                        Number(item.formatted_value) > Number(item.prevFormattedValue)
                                        ?
                                        "↑ "
                                        :
                                        Number(item.formatted_value) < Number(item.prevFormattedValue)
                                        &&
                                        "↓ "
                                }
                                {item.formatted_value}
                            </>
                    }
                </span>
            </div>
        </div>
    )
}

export default BetCard;
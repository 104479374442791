import React from 'react';

const HistoryExpressIndex = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="18" viewBox="0 0 8 18" fill="none">
            <path d="M0.828125 13.502H0.328125V14.002H0.828125V13.502ZM7.67582 13.8555C7.87108 13.6602 7.87108 13.3437 7.67582 13.1484L4.49384 9.96642C4.29857 9.77116 3.98199 9.77116 3.78673 9.96642C3.59147 10.1617 3.59147 10.4783 3.78673 10.6735L6.61516 13.502L3.78673 16.3304C3.59147 16.5256 3.59147 16.8422 3.78673 17.0375C3.98199 17.2327 4.29857 17.2327 4.49384 17.0375L7.67582 13.8555ZM0.328125 0.744293V13.502H1.32812V0.744293H0.328125ZM0.828125 14.002H7.32226V13.002H0.828125V14.002Z" fill="#6E7074" />
        </svg>
    )
}

export default HistoryExpressIndex;
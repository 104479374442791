import React, { useEffect, useState } from 'react';
import "./DepositOutputHistory.scss";
import PageSortHead from '../../SortHead/SortHead';
import DepositDataTable from '../../DataTable/DepositDataTable';
import Pagination from 'components/UI/Pagination/Pagination';
import { useSelector } from 'react-redux';
import NoEvents from 'components/NoEvents/NoEvents';
import NoEventsIcon from 'svg/NoEventsIcon';
import NoEventsIconLight from 'svg/NoEventsIconLight';
import FiltersEmpty from 'svg/FiltersEmpty';
import FiltersEmptyLight from 'svg/FiltersEmptyLight';

const DepositHistory = ({ sortingData }) => {
    const [filters, setFilters] = useState({
        state: null,
        summ: null,
        date: null,
    })
    const filterHundler = (data) => {
        let filtersItems = data;
        if (filters.state && filters.state !== "all_transactions") {
            filtersItems = filtersItems.filter(item => item.status === filters.state)
        }
        if (filters.summ && filters.summ !== "all_summ_bets") {
            const name = sortingData.find(item => item.filterType === 'summ').options.find(option => option.id === filters.summ).name;
            const sign = name.match(/[<>]=?/);

            if (sign) {
                const number = parseInt(name.replace(/[<>]=?/, ''), 10);

                if (sign[0] === '<') {
                    filtersItems = filtersItems.filter(item => item.amount <= number);
                } else if (sign[0] === '>') {
                    filtersItems = filtersItems.filter(item => item.amount >= number);
                }
            }
        }
        if (filters.date && filters.date !== "all_date") {
            const date = sortingData.find(item => item.filterType === 'date').options.find(option => option.id === filters.date).name;
            const match = date.match(/(\d+) дн/);
            const daysAgo = match ? parseInt(match[1], 10) : null;

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0)

            if (filters.date === 'today_yesterday') {
                filtersItems = filtersItems.filter(item => {
                    const itemDate = new Date(item.timestamp_utc);
                    itemDate.setHours(0, 0, 0, 0);

                    return itemDate >= currentDate || itemDate >= new Date(currentDate - 24 * 60 * 60 * 1000);
                });
            } else if (daysAgo !== null) {
                filtersItems = filtersItems.filter(item => {
                    const itemDate = new Date(item.timestamp_utc);
                    itemDate.setHours(0, 0, 0, 0);

                    return itemDate >= new Date(currentDate - (daysAgo - 1) * 24 * 60 * 60 * 1000) &&
                        itemDate <= currentDate;
                });
            }
        }
        return filtersItems;
    }


    const dataTable = useSelector(state => state.BalanceReducer.deposit_history)

    const paginationMaxItem = 4;
    const [currentPagePagination, setCurrentPagePagintaion] = useState(1);

    const [tableItems, setTableItems] = useState([]);
    useEffect(() => {
        const filtersItems = filterHundler(dataTable);
        const pages = Math.ceil(filtersItems.length / paginationMaxItem);
        let currentPageVar = currentPagePagination;
        if (pages < currentPagePagination) {
            currentPageVar = pages || 1;
            setCurrentPagePagintaion(pages || 1);
        }

        const startIndex = (currentPageVar - 1) * paginationMaxItem;
        const endIndex = Math.min(currentPageVar * paginationMaxItem, dataTable.length);

        setTableItems(filtersItems.slice(startIndex, endIndex))
    }, [dataTable, currentPagePagination, filters])

    if (!dataTable.length) {
        return (
            <NoEvents
                svg={<NoEventsIcon />}
                svgLight={<NoEventsIconLight />}
                title="Історія депозиту порожня"
                subtitle="Для наповнення історії поповніть рахунок балансу."
            />
        )
    }

    return (
        <div className="history_container history_table">
            <PageSortHead
                setFilters={setFilters}
                headTitle="Історія депозитів"
                sortingData={sortingData}
            />
            {
                !!dataTable.length &&
                (!!tableItems.length
                    ?
                    <DepositDataTable dataTable={tableItems} />
                    :
                    <NoEvents
                        svg={<FiltersEmpty />}
                        svgLight={<FiltersEmptyLight />}
                        title="На жаль, сортування не дало жодних результатів"
                        subtitle="Можливо, немає об'єктів, що відповідають вашим критеріям. Будь ласка, перевірте параметри сортування або спробуйте інші, щоб отримати потрібні результати."
                        buttonHide={true}
                    />)
            }
            {
                filterHundler(dataTable).length > paginationMaxItem
                    ?
                    <Pagination
                        currentPagePagination={currentPagePagination}
                        setCurrentPagePagintaion={setCurrentPagePagintaion}
                        paginationMaxItem={paginationMaxItem}
                        items={filterHundler(dataTable)}
                    />
                    :
                    <></>
            }
        </div>
    )
}

export default DepositHistory;
import React, { useEffect, useState } from 'react';
import './SignForms.scss';
import { Link, useNavigate } from 'react-router-dom';
import MyButton from 'components/UI/MyButton/MyButton';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ViewPassword from 'svg/login/ViewPassword';
import SignInMethods from '../../components/Form/SignInMethods/SignInMethods';
import { showHidePassword } from 'utils/form';
import AccountService from '../../api/AccountService';
import { useLogin } from 'hooks/useFetching';
import ErrorBlock from '../../components/Form/ErrorBlock/ErrorBlock';
import Loader from '../../components/UI/Loader/Loader';
import MainLayout from 'layouts/MainLayout';
import { useSelector } from 'react-redux';
import { getRoute } from 'utils/routes';

const Register = () => {
    const navigate = useNavigate();
    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);
    useEffect(() => {
        if (isAuth) {
            navigate(getRoute("home"))
        }
    }, [isAuth, navigate])

    const [showErrors, setShowErrors] = useState([]);
    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
        contract_offer: false,
    });

    const handleChange = (event) => {
        let { name, type, value, checked } = event.target;

        if (type === 'checkbox') {
            value = checked;
        }

        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        fetchRegister(
            AccountService.register,
            formData,
            setShowErrors,
            // redirectPage,
        );
    }

    const [fetchRegister, isRegisterLoading] = useLogin();

    return (
        <MainLayout pageName="register">
            <main className="content_block_wrapper flex sign_form column-if_sm_size">
                <div className='half_width content'>

                    <div className="content_inner">
                        {
                            isRegisterLoading
                                ?
                                <div className="flex_centered_item">
                                    <Loader />
                                </div>
                                :
                                <>
                                    <Breadcrumb pages={[
                                        { name: 'Реєстрація', routeName: '' },
                                    ]} />
                                    <form onSubmit={handleSubmit}>
                                        <h1 className='title'>Реєстрація</h1>

                                        <ErrorBlock errors={showErrors} />

                                        <div className="fieldset">
                                            <label htmlFor="email">E-mail</label>
                                            <input type="email" id='email' name='email' placeholder='Введіть E-mail' value={formData.email} onChange={handleChange} />
                                        </div>
                                        <div className="fieldset">
                                            <label htmlFor="phone">Введіть номер</label>
                                            <input
                                                type="text"
                                                id='phone'
                                                name='phone'
                                                placeholder='+380 (__) ___-__-__'
                                                value={formData.phone}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="fieldset">
                                            <label htmlFor="password">Пароль</label>
                                            <div className='password_wrapper'>
                                                <input type="password" id='password' name='password' placeholder='Введіть пароль' value={formData.password} onChange={handleChange} />
                                                <ViewPassword onClick={e => showHidePassword(e)} />
                                            </div>
                                        </div>
                                        <div className="fieldset">
                                            <label htmlFor="password_confirmation">Повторити пароль</label>
                                            <div className='password_wrapper'>
                                                <input type="password" id='password_confirmation' name='password_confirmation' placeholder='Введіть пароль' value={formData.password_confirmation} onChange={handleChange} />
                                                <ViewPassword onClick={e => showHidePassword(e)} />
                                            </div>
                                        </div>
                                        <MyButton type='submit' className="my_button active">
                                            Реєстрація
                                        </MyButton>
                                        <div className="sign-in-block flex space_between">
                                            <label className='flex align_center pointer' htmlFor="contract_offer">
                                                <input type="checkbox" name='contract_offer' id='contract_offer' checked={formData.contract_offer} onChange={handleChange} />
                                                <span>
                                                    Мені більше 18 років і я&nbsp;
                                                    <Link to={getRoute('info-page', ['offers'])}>
                                                        <u>
                                                            погоджуюсь з договором оферти
                                                        </u>
                                                    </Link>
                                                </span>
                                            </label>
                                        </div>
                                        <SignInMethods
                                            fetchLogin={fetchRegister}
                                            setShowErrors={setShowErrors}
                                        />
                                    </form>
                                </>
                        }
                    </div>

                </div>
                <div className='half_width poster_wrapper'>
                    <img src={require("assets/register_poster.png")} alt="poster" />
                </div>
            </main>
        </MainLayout>
    )
}

export default Register;
import React from 'react';
import "./ProfilePageTitle.scss";

const ProfilePageTitle = ({ title, subtitle }) => {
    return (
        <>
            <h1 className='profile_page_title'>{title}</h1>
            {
                subtitle &&
                <p className="profile_page_subtitle">{subtitle}</p>
            }
        </>
    )
}

export default ProfilePageTitle;
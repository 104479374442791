import React, { useEffect, useState } from 'react';
import "./CoefBlock.scss";
import { useDispatch, useSelector } from 'react-redux';
import { updateAutoAcceptOddsAction } from 'store/BetReducer';
import AccountService from 'api/AccountService';

const CoefBlock = () => {
    const dispatch = useDispatch();

    const autoAcceptOdds = useSelector(state => state.BetReducer.autoAcceptOdds);

    function generateUniqueID() {
        return `input-${Date.now()}-${Math.random()}`;
    }
    const noId = generateUniqueID();
    const allId = generateUniqueID();
    const higherId = generateUniqueID();

    const changeValues = (e, value) => {
        AccountService.updateAutoAcceptOddsMode({auto_accept_odds: value})
            .then((response) => {
                dispatch(updateAutoAcceptOddsAction(value))
            })
    }

    return (
        <div className="coef_block flex column">
            <span className="title">Авт. приймати коефіціенти</span>
            <div className="input_block flex space_between align_center">
                <label className='flex align_center input_wrapper' htmlFor={noId}>
                    <input
                        type="radio"
                        name='radio'
                        id={noId}
                        onChange={(e) => changeValues(e, 'no')}
                        checked={autoAcceptOdds === 'no'}
                    />
                    <span className='input_title'>Ні</span>
                </label>
                <label className='flex align_center input_wrapper' htmlFor={allId}>
                    <input
                        type="radio"
                        name='radio'
                        id={allId}
                        onChange={(e) => changeValues(e, 'all')}
                        checked={autoAcceptOdds === 'all'}
                    />
                    <span className='input_title'>Bci</span>
                </label>
                <label className='flex align_center input_wrapper' htmlFor={higherId}>
                    <input
                        type="radio"
                        name='radio'
                        id={higherId}
                        onChange={(e) => changeValues(e, 'higher')}
                        checked={autoAcceptOdds === 'higher'}
                    />
                    <span className='input_title'>Вище</span>
                </label>
            </div>
        </div>
    )
}

export default CoefBlock;
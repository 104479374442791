import AccountService from "api/AccountService";
import { useDispatch } from "react-redux";
import { updateIsLoggedInAction, updateUserDataAction } from "store/AccountReducer";
import { updateCouponOddsAction } from "store/BetReducer";

export const useLogOut = () => {
    const dispatch = useDispatch();

    return async () => {
        await AccountService.logOut();
        dispatch(updateIsLoggedInAction(false));
        dispatch(updateUserDataAction({}));
        dispatch(updateCouponOddsAction([]));
    }
}
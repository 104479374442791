import React from 'react';
import "./Logo.scss";
import { Link } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import { useSelector } from 'react-redux';

const Logo = () => {
    const ligthMode = useSelector(state => state.ThemeReducer.ligthMode);

    return (
        <Link to={getRoute('home')} className="logo">
            {
                ligthMode
                    ?
                    <img src={require('assets/logo_light.svg').default} alt="logo" />
                    :
                    <img src={require('assets/logo.svg').default} alt="logo" />
            }
        </Link>
    )
}

export default Logo;
import BetCoef from 'components/BetCoef/BetCoef';
import React from 'react';
import { updateOddsObjects } from 'utils/odds';

const TemplateCommon = ({ match, oddTypeId, oddsGroup, sortOrder}) => {
    return (
        <div className="item_submenu flex full_width column">
            {
                oddsGroup.map((oddsSubgroup, i) =>
                    <div
                        key={oddTypeId + '_' + i}
                        className="flex full_width space_between bet-coef_wrapper"
                    >
                        {updateOddsObjects(sortOrder, oddsSubgroup.odds).map(odd =>
                            <BetCoef
                                key={odd.id}
                                coefTitle={odd.coefTitle}
                                odd={odd}
                                match={match}
                            />
                        )}
                    </div>
                )
            }
        </div>
    )
}

export default TemplateCommon;
import React, { useState } from 'react';
import './MySearch.scss';
import Search from "svg/Search";
import { useLocation, useNavigate } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import { useDispatch } from 'react-redux';
import { setSearchValue } from 'store/SearchReducer';
import MyButton from '../MyButton/MyButton';
import { closeAllSidebars } from 'utils/sidebar';

const MySearch = ({ className = "" }) => {
    const [value, setValue] = useState("");
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const location = useLocation()
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && value.trim() !== '') {
            dispatch(setSearchValue(value));
            closeAllSidebars(null, true)
            if (location.pathname !== getRoute('search')) {
                navigate(getRoute('search'))
            }
        }
    };

    const handleClick = () => {
        if (value.trim() !== '') {
            dispatch(setSearchValue(value));
            closeAllSidebars(null, true)
            if (location.pathname !== getRoute('search')) {
                navigate(getRoute('search'))
            }
        }
    };

    return (
        <div className={"search_block flex align_center half_width " + className}>
            <Search />
            <input
                type="search"
                name='search'
                id='search'
                placeholder='Пошук'
                value={value}
                onChange={e => setValue(e.target.value)}
                onKeyDown={handleKeyPress}
            />
            <MyButton
                onClick={handleClick}
                className="small my_button active">
                Пошук
            </MyButton>
        </div>
    )
}

export default MySearch;
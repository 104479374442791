import React, { useEffect, useRef } from 'react';
import "./BalanceTabMenu.scss";

const BalanceTabMenu = ({activeTab, setActiveTab}) => {
    const toggleTab = (e) => {
        Array.from(e.target.parentElement.children).forEach(tab => {
            tab.classList.remove("active");
        })
        e.target.classList.add("active");
        setActiveTab(Number(e.target.dataset.tabId));
    }

    const ref = useRef()
    useEffect(() => {
        Array.from(ref.current.children).forEach(item => item.classList.remove('active'))
        ref.current.children[activeTab - 1].classList.add('active');
    }, [activeTab])

    return (
        <div className="tab_menu-balance">
            <div className="tab_list flex" ref={ref}>
                <div data-tab-id="1" onClick={e => toggleTab(e)} className="tab">Поповнення рахунку</div>
                <div data-tab-id="2" onClick={e => toggleTab(e)} className="tab">Виведення коштів</div>
                <div data-tab-id="3" onClick={e => toggleTab(e)} className="tab">Історія депозитів</div>
                <div data-tab-id="4" onClick={e => toggleTab(e)} className="tab">Історія виведення</div>
            </div>
        </div>
    )
}

export default BalanceTabMenu;
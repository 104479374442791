import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDocumentsModal } from 'store/DocumentsReducer';

const DocumentsUploadModal = () => {
    const dispatch = useDispatch()
    const documentsModal = useSelector(state => state.DocumentsReducer.documentsModal)
    
    const closeModal = (e) => {
        if (e.target.classList.contains('modal_wrapper') || e.currentTarget.classList.contains('close_modal')) {
            document.querySelector("body").classList.remove("no_scroll");
            e.currentTarget.closest('.modal_wrapper').classList.add('hide');
            dispatch(updateDocumentsModal(false))
        }
    }

    useEffect(() => {
        if (documentsModal) {
            document.querySelector("body").classList.add("no_scroll");
            document.querySelector('.documents-upload-modal').classList.remove('hide');
        }
    }, [documentsModal])

    return (
        <div onClick={e => closeModal(e)} className="modal_wrapper fixed documents-upload-modal hide">
            <div className="modal flex column">
                <div className='close_modal pointer' onClick={e => closeModal(e)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39" viewBox="0 0 40 39" fill="none">
                        <path d="M39.1333 19.4742C39.1333 30.1467 30.3922 38.7985 19.6096 38.7985C8.82698 38.7985 0.0859375 30.1467 0.0859375 19.4742C0.0859375 8.80177 8.82698 0.150024 19.6096 0.150024C30.3922 0.150024 39.1333 8.80177 39.1333 19.4742Z" fill="#6E7074" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.1437 11.9016C12.7525 11.5118 12.1182 11.5118 11.727 11.9016C11.3358 12.2915 11.3358 12.9235 11.727 13.3133L18.1928 19.7562L12.009 25.9181C11.6177 26.308 11.6177 26.94 12.009 27.3298C12.4002 27.7197 13.0345 27.7197 13.4257 27.3299L19.6095 21.1679L25.7917 27.3282C26.1829 27.718 26.8172 27.718 27.2084 27.3282C27.5996 26.9384 27.5996 26.3063 27.2084 25.9165L21.0262 19.7562L27.4904 13.315C27.8816 12.9251 27.8816 12.2931 27.4904 11.9033C27.0991 11.5134 26.4649 11.5134 26.0736 11.9033L19.6095 18.3445L13.1437 11.9016Z" fill="#313439" />
                    </svg>
                </div>
                <div className="modal-content">
                    <h1 className="title">Успішний запит на пітвреження особи</h1>
                    <p className="subtitle">
                        Персональні документи успішно прислались на сервер для перевірки адміністрацією сайту. Звертаю увагу, що обробка особистої інформації може підлягати правилам конфіденційності та політиці безпеки відповідного сайту. Якщо у вас є додаткові питання або потрібна допомога, будь ласка, зверніться до адміністрації сайту.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default DocumentsUploadModal;
import React, { useEffect, useRef } from 'react';
import './SwitchBlock.scss';

const SwitchBlock = ({ children, isSwitch = true, blockClass = "", bckColor2 = false, mr = 0, mb = 0, ml = 0, mt = 0 }) => {
    const style = {
        marginRight: mr,
        marginBottom: mb,
        marginLeft: ml,
        marginTop: mt
    }
    const switchBlock = useRef();

    useEffect(() => {
        const switchBlockBtn = switchBlock.current.children;

        if (isSwitch) {
            Array.from(switchBlockBtn).forEach(item => {
                item.addEventListener("click", (e) => {
                    const datasetOff = e.currentTarget.dataset.off;
                    if (datasetOff === "true") return;
                    Array.from(switchBlockBtn).forEach(element => {
                        element.classList.remove("active");
                    })
                    item.classList.add("active");
                })
            })
        }
    }, [isSwitch])

    return (
        <div
            ref={switchBlock}
            style={{ ...style }}
            className={
                "switch-block flex" +
                (bckColor2 ? " bckColor2 " : " ") +
                blockClass
            }
        >
            {children}
        </div>
    )
}

export default SwitchBlock;
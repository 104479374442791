const defaultState = {
    deposit_history: [],
    output_history: [],
    min_deposit: '',
    max_deposit: '',
    withdrawal_commission: null,
    taxes: [],
}

const UPDATE_DEPOSIT_HISTORY = 'UPDATE_DEPOSIT_HISTORY';
const UPDATE_WITHDRAWAL_HISTORY = 'UPDATE_WITHDRAWAL_HISTORY';
const UPDATE_MIN_DEPOSIT = 'UPDATE_MIN_DEPOSIT';
const UPDATE_MAX_DEPOSIT = 'UPDATE_MAX_DEPOSIT';
const SET_WITHDRAWAL_COMMISION = 'SET_WITHDRAWAL_COMMISION';
const SET_TAXES = 'SET_TAXES';

export const BalanceReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_DEPOSIT_HISTORY:
            return { ...state, deposit_history: action.payload.filter(item => item.is_deposit) }
        case UPDATE_WITHDRAWAL_HISTORY:
            return { ...state, output_history: action.payload.filter(item => !item.is_deposit) }
        case UPDATE_MIN_DEPOSIT:
            return { ...state, min_deposit: action.payload }
        case UPDATE_MAX_DEPOSIT:
            return { ...state, max_deposit: action.payload }
        case SET_WITHDRAWAL_COMMISION:
            return { ...state, withdrawal_commission: action.payload }
        case SET_TAXES:
            return { ...state, taxes: action.payload }
        default:
            return state;
    }
}

export const updateDepositHistoryAction = (payload) => ({ type: UPDATE_DEPOSIT_HISTORY, payload });
export const updateWithdrawalHistoryAction = (payload) => ({ type: UPDATE_WITHDRAWAL_HISTORY, payload });
export const updateMinDepositAction = (payload) => ({ type: UPDATE_MIN_DEPOSIT, payload });
export const updateMaxDepositAction = (payload) => ({ type: UPDATE_MAX_DEPOSIT, payload });
export const setWithdrawalCommisionAction = (payload) => ({ type: SET_WITHDRAWAL_COMMISION, payload });
export const setTaxesAction = (payload) => ({ type: SET_TAXES, payload });
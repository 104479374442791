import React from 'react';

const Email = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="22" viewBox="0 0 34 22" fill="none">
            <path d="M17.4503 14.7945C17.3194 14.9068 17.1591 14.9632 16.9988 14.9632C16.8385 14.9632 16.6782 14.9068 16.5473 14.7945L11.8619 10.7676L1.32812 21.6229H32.6695L22.1357 10.7676L17.4503 14.7945Z" fill="#3D3F44" />
            <path d="M0.324219 0.851868V20.5941L10.7703 9.82909L0.324219 0.851868Z" fill="#3D3F44" />
            <path d="M33.6726 20.5941V0.851868L23.2266 9.82909L33.6726 20.5941Z" fill="#3D3F44" />
            <path d="M12.2733 9.21981L16.9992 13.2815L21.7248 9.22021C21.729 9.21661 21.7333 9.21261 21.7379 9.20901L32.4242 0.0255127H1.57422L12.2609 9.20901C12.2648 9.21261 12.2691 9.21621 12.2733 9.21981Z" fill="#3D3F44" />
        </svg>
    )
}

export default Email;
import React from 'react';
import "./Filters.scss";
import ArrowDown from 'svg/ArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import FiltersIcon from 'svg/FiltersIcon';
import { updateFilterLeagueIdAction } from 'store/FutureMatchesReducer';
import { handleFooterScroll } from 'utils/footer';

const Filters = ({ leaguesGroups, currentCategoryId, leaguesValue, setLeaguesValue, sidebar }) => {
    const dispatch = useDispatch();
    const filterLeagueId = useSelector(state => state.FutureMatchesReducer.filter_league_id);
    const ligthMode = useSelector(state => state.ThemeReducer.ligthMode);

    const getCurrentLeagues = (filter) => {
        if (!leaguesValue.length) return filter.leagues;

        const filterId = filter.id;
        const leagues = leaguesValue.find(item => item.id === currentCategoryId)?.filters.find(filter => filter.filterId === filterId).leagues;
        if (!leagues) return filter.leagues;
        return leagues;
    }

    const handleLeagueChange = (leagueId, filterId, leagueValue) => {
        if (!leagueValue) {
            dispatch(updateFilterLeagueIdAction([...filterLeagueId, leagueId]))
        } else {
            dispatch(updateFilterLeagueIdAction([...filterLeagueId.filter(filter => filter !== leagueId)]))
        }

        setLeaguesValue(prevLeaguesValue => {
            return prevLeaguesValue.map(categoryFilters => {
                if (categoryFilters.id === currentCategoryId) {
                    return {
                        ...categoryFilters,
                        filters: categoryFilters.filters.map(filter => {
                            if (filter.filterId === filterId) {
                                return {
                                    ...filter,
                                    leagues: filter.leagues.map(league => {
                                        if (league.id === leagueId) {
                                            return { ...league, value: !league.value };
                                        }
                                        return league;
                                    })
                                }
                            }
                            return filter;
                        })
                    }
                }
                return categoryFilters;
            });
        });
    };

    return (
        !!leaguesGroups && leaguesGroups.length
            ?
            leaguesGroups.map(filter =>
                <div key={filter.id} className={'filter' + (filter.isActive ? " active" : "")}>
                    <div className='head flex align_center has_submenu'>
                        {
                            ligthMode
                                ?
                                <img src={require(`assets/filters/${filter.icon_dark}`)} alt="filter_icon" />
                                :
                                <img src={require(`assets/filters/${filter.icon}`)} alt="filter_icon" />
                        }
                        <span className='title'>
                            {filter.translation.title}
                        </span>
                        <ArrowDown />
                    </div>
                    <div className="tags_wrapper submenu">
                        {
                            getCurrentLeagues(filter).map((league) =>
                                <label
                                    key={league.id}
                                    className='flex align_center input_wrapper'
                                    htmlFor={(sidebar ? "sidebar_" : "") + 'tag' + league.id + "_" + filter.id}
                                >
                                    <input
                                        type="checkbox"
                                        id={(sidebar ? "sidebar_" : "") + 'tag' + league.id + "_" + filter.id}
                                        data-id={filter.id}
                                        checked={league.value ? league.value : false}
                                        onChange={() => handleLeagueChange(league.id, filter.id, league.value)}
                                    />
                                    <span className='input_title'>{league.translation.title}</span>
                                </label>
                            )
                        }
                    </div>
                </div>
            )
            :
            <div className='flex column align_center no_filters'>
                <FiltersIcon />
                <span className="title">
                    Фільтрів немає
                </span>
                <span className="subtitle">
                    В фільтрах поки, що пусто, але згодом вони тут з'являться
                </span>
            </div>
    )
}

export default Filters;
import React from 'react';

const BetUnSuccess = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <rect x="0.551367" width="1.2028" height="19.5614" rx="0.601398" transform="matrix(0.691626 -0.722256 0.691626 0.722256 10.6114 11.5703)" fill="white" stroke="white" strokeWidth="0.797204" />
            <rect x="-0.551367" width="1.2028" height="19.5614" rx="0.601398" transform="matrix(-0.691626 -0.722256 -0.691626 0.722256 24.5966 10.7739)" fill="white" stroke="white" strokeWidth="0.797204" />
            <rect x="0.551367" width="1.2028" height="19.5614" rx="0.601398" transform="matrix(0.691626 -0.722256 0.691626 0.722256 10.6114 11.5702)" fill="white" stroke="white" strokeWidth="0.797204" />
            <rect x="-0.551367" width="1.2028" height="19.5614" rx="0.601398" transform="matrix(-0.691626 -0.722256 -0.691626 0.722256 24.5966 10.7738)" fill="white" stroke="white" strokeWidth="0.797204" />
            <path d="M34.5449 18.0001C34.5449 27.4164 27.1078 35.0066 17.9863 35.0066C8.86486 35.0066 1.42773 27.4164 1.42773 18.0001C1.42773 8.58382 8.86486 0.993652 17.9863 0.993652C27.1078 0.993652 34.5449 8.58382 34.5449 18.0001Z" stroke="white" strokeWidth="2" />
        </svg>
    )
}

export default BetUnSuccess;
import React from 'react';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Footer from 'components/Footer/Footer';
import AsideMenu from 'components/AsideMenu/AsideMenu';
import NoEvents from 'components/NoEvents/NoEvents';
import Error404Icon from 'svg/Error404Icon';
import Error404IconLight from 'svg/Error404IconLight';
import MainLayout from 'layouts/MainLayout';

const Error404 = () => {
    return (
        <MainLayout pageName="404">
            <main className="content_block_wrapper small_width">
                <Breadcrumb pages={[
                    { name: "Сторінка 404", routeName: '' },
                ]} prevPage={true} />
                <section className='content_block'>
                    <NoEvents
                        svg={<Error404Icon />}
                        svgLight={<Error404IconLight />}
                        title="Сторінку 404 не знайдено"
                        subtitle="Можливо, ви ввели неправильну адресу або сторінка, яку ви шукаєте, більше не існує. Будь ласка, перевірте правильність введеної адреси або перейдіть на головну сторінку, щоб знайти іншу інформацію."
                    />
                </section>
                <Footer />
                <AsideMenu />
            </main >
        </MainLayout>
    )
}

export default Error404;
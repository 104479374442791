import React, { useEffect, useState } from 'react';
import "./Search.scss";
import { useSelector } from 'react-redux';
import MainLayout from 'layouts/MainLayout';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Footer from 'components/Footer/Footer';
import AsideMenu from 'components/AsideMenu/AsideMenu';
import { handleFooterScroll } from 'utils/footer';
import NoEvents from 'components/NoEvents/NoEvents';
import NoSearch from 'svg/NoSearch';
import NoSearchLight from 'svg/NoSearchLight';
import PostService from 'api/PostService';
import Match from 'components/MatchList/Match/Match';
import Loader from 'components/UI/Loader/Loader';
import MatchList from 'components/MatchList/MatchList';
import MySearch from 'components/UI/MySearch/MySearch';

const Search = () => {
    const categories = useSelector(state => state.CategoryReducer.categories)
    const searchValue = useSelector(state => state.SearchReducer.searchValue)

    const [activeTab, setActiveTab] = useState(null);
    const [loader, setLoader] = useState(true);
    const [searchMatches, setSearchMatches] = useState([])
    const [currentCategory, setCurrentCategory] = useState();
    useEffect(() => {
        setLoader(true);
        if (activeTab) {
            // console.log(activeTab);
            const data = {
                search: searchValue,
                sport_id: activeTab
            }
            PostService.sendSearch(data)
                .then((response) => {
                    setLoader(false)
                    if (response.status === 200) {
                        console.log(response);
                        setSearchMatches(response.data.future_matches)
                        setCurrentCategory(response.data.sport)
                    }
                })
        }
        handleFooterScroll()
    }, [activeTab, searchValue])


    useEffect(() => {
        const defaultId = categories[0]?.id;
        if (defaultId) {
            setActiveTab(defaultId);
        }
    }, [categories])

    const toggleTab = (e) => {
        Array.from(e.target.parentElement.children).forEach(tab => {
            tab.classList.remove("active");
        })
        e.target.classList.add("active");
        setActiveTab(Number(e.target.dataset.categoryId));
    }

    return (
        <MainLayout pageName="search">
            <main className="content_block_wrapper small_width search_page">
                <Breadcrumb pages={[
                    { name: "Пошук", routeName: '' },
                ]} prevPage={true} />
                <section className='content_block'>
                    <MySearch />
                    <div className="tab_menu-search">
                        <div className="tab_list flex">
                            {
                                categories.map((category, index) =>
                                    <div
                                        key={category.id}
                                        data-category-id={category.id}
                                        onClick={toggleTab}
                                        className={"tab" + (index === 0 ? " active" : "")}
                                    >
                                        {category.translation.title}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {
                        loader
                            ?
                            <div className='flex justify_center align_center' style={{ height: '300px' }}>
                                <Loader />
                            </div>
                            :
                            !!searchMatches.length
                                ?
                                <div className='match_list'>
                                    {
                                        currentCategory?.leagues_groups.map(leagues_group =>
                                            leagues_group.leagues.map(league => {
                                                const leagueMatches = searchMatches
                                                    .filter(match => match.sport_id === currentCategory.id && match.league_id === league.id)

                                                return (
                                                    <div key={league.id} className="match_list flex column">
                                                        <span className="list_title">
                                                            {league.translation.title}
                                                        </span>
                                                        {
                                                            leagueMatches.map(match =>
                                                                <Match
                                                                    live={false}
                                                                    match={match}
                                                                    key={match.id}
                                                                    currentCategory={currentCategory}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                            )
                                        )
                                    }
                                </div>
                                :
                                <>
                                    <span className='result_search'>Результат пошуку слова "{searchValue}"</span>
                                    <NoEvents
                                        svg={<NoSearch />}
                                        svgLight={<NoSearchLight />}
                                        title="Нічого не знайдено"
                                        subtitle="Спробуйте змінити критерії пошуку або скористайтесь іншими ключовими словами для отримання результатів. Якщо у вас є конкретні питання або потребуєте додаткової допомоги, будь ласка, зверніться до нашої служби підтримки"
                                    />
                                </>
                    }
                </section>
                <Footer />
                <AsideMenu />
            </main >
        </MainLayout>
    )
}

export default Search;
import React from 'react';
import "./LinkBlock.scss"
import { Link } from 'react-router-dom';
import ArrowNext from 'svg/ArrowNext';
import { getRoute } from 'utils/routes';
import { useSelector } from 'react-redux';

const LinkBlock = () => {
    const infoPages = useSelector(state => state.HelpInfoReducer.info_pages);
    return (
        <div className="link_block">
            {
                infoPages.map((infoPage, i) =>
                    <Link to={getRoute('info-page', [infoPage.slug])} className="link" key={i}>
                        <span className="title">{infoPage.translation.title}</span>
                        <ArrowNext />
                    </Link>
                )
            }
        </div>
    )
}

export default LinkBlock;
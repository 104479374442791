const defaultState = {
    isLoggedIn: null,
    userData: {},
    pagesRedirect: '',
    balanceCashTab: false,
}

const UPDATE_IS_LOGGED_IN = 'UPDATE_IS_LOGGED_IN';
const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
const UPDATE_PAGES_REDIRECT = 'UPDATE_PAGES_REDIRECT';
const UPDATE_BALANCE_CASH_TAB = 'UPDATE_BALANCE_CASH_TAB';

export const AccountReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_IS_LOGGED_IN:
            return { ...state, isLoggedIn: action.payload }
        case UPDATE_USER_DATA:
            return { ...state, userData: action.payload }
        case UPDATE_PAGES_REDIRECT:
            return { ...state, pagesRedirect: action.payload }
        case UPDATE_BALANCE_CASH_TAB:
            return { ...state, balanceCashTab: action.payload }
        default:
            return state;
    }
}

export const updateIsLoggedInAction = (payload) => ({ type: UPDATE_IS_LOGGED_IN, payload });
export const updateUserDataAction = (payload) => ({ type: UPDATE_USER_DATA, payload });
export const updatePagesRedirectAction = (payload) => ({ type: UPDATE_PAGES_REDIRECT, payload });
export const updateBalanceCashTabAction = (payload) => ({ type: UPDATE_BALANCE_CASH_TAB, payload });
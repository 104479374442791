import React, { useEffect, useState } from 'react';
import './Header.scss';
import MyButton from 'components/UI/MyButton/MyButton';
import SwitchBlock from 'components/UI/SwitchBlock/SwitchBlock';
import MySearch from 'components/UI/MySearch/MySearch';
import ShapeFire from 'svg/ShapeFire';
import LoginLang from 'components/UI/LoginLang/LoginLang';
import { getRoute } from 'utils/routes';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Lang from 'components/UI/Lang/Lang';
import ProfileBlock from 'components/UI/ProfileBlock/ProfileBlock';

const Header = () => {
    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);
    let location = useLocation();
    const [liveBets, setLiveBets] = useState(false);

    useEffect(() => {
        if (location.pathname.includes('live-bets')) {
            setLiveBets(true);
        } else setLiveBets(false);
    }, [location]);

    if (!!isAuth) {
        return (
            <header className='flex grow desktop_header hide-if_lg_size'>
                <MySearch className="auth_search" />
                {/* <Lang /> */}
                <SwitchBlock ml="10px" blockClass="full_width">
                    <MyButton
                        href={getRoute('home')}
                        className={"my_button full_height" + (!liveBets ? " active" : "")}
                    >
                        Ставки Спорт
                    </MyButton>
                    <MyButton
                        href={getRoute('live-bets')}
                        svg={<ShapeFire />}
                        className={"my_button" + (liveBets ? " active" : "")}
                    >
                        Лайв Ставки
                    </MyButton>
                </SwitchBlock>
                <ProfileBlock />
            </header>
        )
    }

    return (
        <header className='flex grow desktop_header hide-if_lg_size'>
            <MySearch />
            <SwitchBlock ml="22px" blockClass="half_width">
                <MyButton
                    href={getRoute('home')}
                    className={"my_button full_height" + (!liveBets ? " active" : "")}
                >
                    Ставки Спорт
                </MyButton>
                <MyButton
                    href={getRoute('live-bets')}
                    svg={<ShapeFire />}
                    className={"my_button" + (liveBets ? " active" : "")}
                >
                    Лайв Ставки
                </MyButton>
            </SwitchBlock>
            <LoginLang />
        </header>
    )
}

export default Header;
import React from 'react';
import "./NoEvents.scss";
import MyButton from 'components/UI/MyButton/MyButton';
import { getRoute } from 'utils/routes';
import { useSelector } from 'react-redux';

const NoEvents = ({ svg, svgLight, title, subtitle, buttonHide = false }) => {
    const ligthMode = useSelector(state => state.ThemeReducer.ligthMode);
    
    return (
        <div className='no-events_wrapper flex justify_center align_center'>
            <div className="no-events flex column align_center-if_sm_size">
                {
                    ligthMode
                        ?
                        svgLight
                        :
                        svg
                }
                <span className="title text_center-if_sm_size">
                    {title}
                </span>
                <span className="subtitle text_center-if_sm_size">
                    {subtitle}
                </span>
                {
                    !buttonHide &&
                    <MyButton href={getRoute("home")} className="medium my_button active">
                        Повернутися назад на головну
                    </MyButton>
                }
            </div>
        </div>
    )
}

export default NoEvents;
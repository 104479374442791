import React from 'react';

const SidebarBack = ({...props}) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.4056 31.7109C7.8027 31.7109 0.828705 24.8082 0.828705 16.2932C0.828705 7.77824 7.8027 0.875488 16.4056 0.875488C25.0084 0.875488 31.9824 7.77824 31.9824 16.2932C31.9824 24.8082 25.0084 31.7109 16.4056 31.7109ZM17.1422 10.3L17.853 9.59655L19.2599 11.018L18.5492 11.7215L13.9309 16.2926L18.5492 20.8637L19.2599 21.5671L17.853 22.9886L17.1422 22.2851L11.8059 17.0033L11.0878 16.2926L11.8059 15.5818L17.1422 10.3Z" fill="#6E7074" />
        </svg>
    )
}

export default SidebarBack;
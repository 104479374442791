import React from 'react';

const IconHokey = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 25 21" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.632 0.557837C17.7858 0.835114 20.3097 1.61224 22.2782 2.91195C23.9304 4.00294 24.8281 5.39437 24.8281 6.86431C24.8281 8.03804 24.367 9.02097 23.3578 9.99885C21.9719 11.3416 19.825 12.3309 17.1176 12.8743C15.6222 13.1745 14.4503 13.2843 12.7439 13.2843C11.0374 13.2843 9.86551 13.1745 8.37013 12.8743C5.66278 12.3309 3.51589 11.3416 2.12998 9.99885C1.38765 9.2796 0.887522 8.45598 0.745579 7.71889C0.716972 7.57015 0.675293 7.42825 0.652966 7.40361C0.605949 7.35159 0.596792 6.26057 0.643761 6.30758C0.66099 6.32481 0.720749 6.16375 0.776545 5.94958C0.979757 5.16991 1.35649 4.5556 2.07456 3.83319C3.58127 2.31737 6.25637 1.18693 9.43604 0.722391C11.0207 0.490902 13.1161 0.424533 14.632 0.557837ZM24.8101 6.88201C24.81 7.19356 24.8024 7.31487 24.7931 7.15159C24.7839 6.98831 24.7839 6.73341 24.7933 6.58514C24.8026 6.43687 24.8102 6.57046 24.8101 6.88201ZM24.8275 12.3369C24.8281 14.0843 24.8217 14.2299 24.728 14.6027C24.526 15.4065 24.1404 16.0438 23.4132 16.7754C21.3674 18.8335 17.2606 20.1216 12.7439 20.1216C8.22719 20.1216 4.12029 18.8335 2.07456 16.7754C1.36702 16.0636 0.961725 15.4044 0.780415 14.6707C0.735523 14.489 0.67935 14.3034 0.655607 14.2583C0.631533 14.2126 0.612982 13.3459 0.613689 12.2993L0.615011 10.4223L0.873266 10.7122C1.39218 11.2946 2.34358 12.0153 3.27945 12.5348C7.7333 15.0076 15.0269 15.3851 20.2797 13.4148C22.0679 12.7441 23.7347 11.7083 24.5937 10.734L24.8268 10.4695L24.8275 12.3369ZM24.8066 12.3813C24.8066 13.4328 24.8004 13.8629 24.7928 13.3372C24.7852 12.8115 24.7852 11.9512 24.7928 11.4254C24.8004 10.8997 24.8066 11.3298 24.8066 12.3813Z" fill="#6E7074" />
        </svg>
    )
}

export default IconHokey;
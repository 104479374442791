import React from 'react';
import "./Category.scss";
import { Link } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { updateParamAction } from 'store/CategoryReducer';
import ArrowNext from 'svg/ArrowNext';

const Category = ({ category, openFilterMenu }) => {
    /*const categoryMatchesList = useSelector(state => state.MatchesReducer.matches)
        .filter(match => match.categoryId === category.id)*/

    const dispatch = useDispatch();
    const categoryParam = useSelector(state => state.CategoryReducer.categoryParam);

    const classActive = (e) => {
        const navCategories = e.currentTarget.closest("nav").querySelectorAll('.category');
        navCategories.forEach(navcategory => {
            navcategory.classList.remove('active');
        });
        e.currentTarget.parentElement.classList.add("active");
        dispatch(updateParamAction(e.currentTarget.id));
    }

    return (
        <div
            className={
                'category flex' + ((category.slug === categoryParam) ? ' active' : '')
            }
        >
            <Link
                to={getRoute('category', [category.slug])}
                id={category.slug}
                onClick={e => classActive(e)}
                className='inner_box pointer'
            >
                {category.icon}
                <span className="title grow">
                    {category.translation.title}
                </span>
                <span className="quantity">
                    {category.count_live_matches}
                </span>
            </Link>
            <ArrowNext
                id={category.id}
                className="filter_btn pointer"
                onClick={openFilterMenu}
            />
        </div>
    )
}

export default Category;
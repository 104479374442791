import AsideMenu from 'components/AsideMenu/AsideMenu';
import Footer from 'components/Footer/Footer';
import React, { useEffect } from 'react';
import './CategoryPage.scss';
import { Navigate, useParams } from 'react-router-dom';
import CategorySlider from 'components/CategorySlider/CategorySlider';
import { useDispatch, useSelector } from 'react-redux';
import { updateParamAction } from 'store/CategoryReducer';
import MatchList from 'components/MatchList/MatchList';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import NoEvents from 'components/NoEvents/NoEvents';
import NoEventsIcon from 'svg/NoEventsIcon';
import NoEventsIconLight from 'svg/NoEventsIconLight';
import { getRoute } from 'utils/routes';
import { openSidebar } from 'utils/sidebar';
import FiltersIcon from 'svg/FiltersIcon';
import MainLayout from 'layouts/MainLayout';
import { handleFooterScroll } from 'utils/footer';

const CategoryPage = () => {
    const dispatch = useDispatch();
    const filterLeagueId = useSelector(state => state.FutureMatchesReducer.filter_league_id);
    useEffect(() => {
        handleFooterScroll()
    }, [filterLeagueId])

    const categories = useSelector(state => state.CategoryReducer.categories);
    const params = useParams();
    const param = useSelector(state => state.CategoryReducer.categoryParam);
    const currentCategory = categories.find(category => category.slug === params.category);

    const allMatches = useSelector(state => state.MainMatchesReducer.matches);
    let categoriesMatches = allMatches[currentCategory?.id];


    useEffect(() => {
        dispatch(updateParamAction(params.category));

        return () => {
            dispatch(updateParamAction(''));
        }
    }, [params, dispatch])

    /*if (!currentCategory) {
        return <Navigate to={getRoute("error-404")} />
    }*/

    return (
        <MainLayout pageName="categories" pageSlug={params.category}>
            {!currentCategory ? (<Navigate to={getRoute("error-404")} />) : (
                <main className="content_block_wrapper small_width category_page">
                    <div className='flex'>
                        <Breadcrumb pages={[
                            { name: currentCategory.translation.title, routeName: '' },
                        ]} />
                        {
                            param &&
                            <div
                                className='column mobile_filter_btn hide flex-if_lg_size'
                                onClick={e => openSidebar(e, ".filter_sidebar")}
                            >
                                <FiltersIcon />
                                <span className='title'>Фільтр</span>
                            </div>
                        }
                    </div>
                    <section className='content_block'>
                        <CategorySlider categoryMatches={categoriesMatches} categories={categories} />
                        {
                            categoriesMatches && categoriesMatches.length
                                ?
                                <>

                                    <h2 className="page_title">
                                        <span>НАЙБЛИЖЧІ МАТЧІ</span>&nbsp;
                                        {currentCategory.translation.title}
                                    </h2>

                                    {
                                        !!filterLeagueId.length
                                            ?
                                            currentCategory.leagues_groups.map(leagues_group =>
                                                leagues_group.leagues.map(league => {
                                                    if (filterLeagueId.find(filter => filter === league.id)) {
                                                        return <MatchList
                                                            key={league.id}
                                                            currentCategory={currentCategory}
                                                            currentLeague={league}
                                                        />
                                                    }
                                                }
                                                )
                                            )
                                            :
                                            currentCategory.leagues_groups.map(leagues_group =>
                                                leagues_group.leagues.map(league =>
                                                    <MatchList
                                                        key={league.id}
                                                        currentCategory={currentCategory}
                                                        currentLeague={league}
                                                    />
                                                )
                                            )
                                    }
                                </>
                                :
                                <NoEvents
                                    svg={<NoEventsIcon />}
                                    svgLight={<NoEventsIconLight />}
                                    title="Подій немає"
                                    subtitle="Подій ставок немає або закінчились: Відстежуйте Новини та Оновлення на головній сторінці"
                                />
                        }
                    </section>
                    <Footer />
                    <AsideMenu />
                </main>
            )}
        </MainLayout>
    )
}

export default CategoryPage;
import React from 'react';
import './CategoryCard.scss';
import { Link } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import { getTimezone } from 'utils/timezone';
import { OddsTypesConst } from 'constants/OddsTypesConst';
import moment from 'moment-timezone';
import { updateOddsObjects } from 'utils/odds';
import { useAddBet } from 'hooks/useOdds';
import { useSelector } from 'react-redux';
import Block from 'svg/bet/Block';

const CategoryCard = ({ categoryMatch, currentCategoryHref }) => {
    const addBet = useAddBet();
    const couponOdds = useSelector(state => state.BetReducer.couponOdds);

    const matchStopped = categoryMatch ? !!categoryMatch.is_stopped : false;

    const mainOdds = categoryMatch.main_odds_groups[OddsTypesConst.match_winner][0].odds;
    const sortOrderMatchWinnerOdds = [
        { name: "home", title: "П1" },
        { name: "draw", title: "X" },
        { name: "away", title: "П2" },
    ];

    const updateMainOdds = updateOddsObjects(sortOrderMatchWinnerOdds, mainOdds)

    return (
        <div className='category_card flex column'>
            <span className="card_name">
                {categoryMatch.league.translation.title}
            </span>
            <Link to={getRoute('match', [currentCategoryHref, categoryMatch.id])} className="main_info flex space_between">
                <div className="team flex_centered_item column">
                    {categoryMatch.local_team.image_url && (
                        <img src={categoryMatch.local_team.image_url} alt="team_1" />
                    )}
                    <span className='team_name'>{categoryMatch.local_team.translation.title}</span>
                </div>
                <div className="date_wrapper flex column align_center">
                    <span className="date">{moment.utc(categoryMatch.start_timestamp_utc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('D MMMM')}</span>
                    <span className='time grow'>{moment.utc(categoryMatch.start_timestamp_utc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('H:mm')}</span>
                    <span className="vs">X</span>
                </div>
                <div className="team flex_centered_item column">
                    {categoryMatch.visitor_team.image_url && (
                        <img src={categoryMatch.visitor_team.image_url} alt="team_2" />
                    )}
                    <span className='team_name'>{categoryMatch.visitor_team.translation.title}</span>
                </div>
            </Link>
            <hr />
            <div className="coef_wrapper flex space_between grow">
                {
                    updateMainOdds.map(odd =>
                        <div
                            key={odd.id}
                            className={
                                "coef_block align_center" +
                                (couponOdds.find(bet => bet.id === odd?.id) ? ' active' : '') +
                                (matchStopped || !!odd?.stop || !!odd?.expired ? " is_stoped" : "")
                            }
                            onClick={() => addBet(odd, categoryMatch)}
                        >
                            <span className='title'>{odd.coefTitle}</span>
                            <span className="coef">
                                {
                                    matchStopped || !!odd?.stop || !!odd?.expired
                                        ?
                                        <Block />
                                        :
                                        odd.formatted_value
                                }
                            </span>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default CategoryCard;
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import MyButton from 'components/UI/MyButton/MyButton';
import React, { useState } from 'react';
import ViewPassword from 'svg/login/ViewPassword';
import { inputHandleChange, pushError, showHidePassword } from 'utils/form';
import ErrorBlock from '../../components/Form/ErrorBlock/ErrorBlock';
import { useFetching } from 'hooks/useFetching';
import AccountService from '../../api/AccountService';
import Loader from '../../components/UI/Loader/Loader';
import { useNavigate, useLocation } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import MainLayout from 'layouts/MainLayout';

const PasswordRecovery = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const searchParams = new URLSearchParams(location.search);

    const [showErrors, setShowErrors] = useState([]);
    const [formData, setFormData] = useState({
        token: searchParams.get('token'),
        email: searchParams.get('email'),
        password: '',
        password_confirmation: '',
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchResetPassword();
    }

    const [fetchResetPassword, isResetPasswordLoading] = useFetching(async () => {
        const response = await AccountService.resetPassword(formData);

        let errors = [];

        if (response.status === 200) {
            navigate(getRoute("password-recovery-success"));
        } else errors = pushError(response);

        setShowErrors(errors);
    });

    return (
        <MainLayout pageName="password-recovery">
            <main className="content_block_wrapper flex sign_form column-if_sm_size">
                <div className='half_width content'>

                    <div className="content_inner">
                        {isResetPasswordLoading ? (
                            <div className="flex_centered_item">
                                <Loader />
                            </div>
                        ) : (
                            <>
                                <Breadcrumb pages={[
                                    { name: 'Авторизація', routeName: 'login' },
                                    { name: 'Відновлення пароля', routeName: '' },
                                ]} />
                                <form onSubmit={handleSubmit}>
                                    <h1 className='title'>Встановлення пароля</h1>

                                    <ErrorBlock errors={showErrors} />

                                    <div className="fieldset">
                                        <label htmlFor="password">Ввести новий пароль</label>
                                        <div className='password_wrapper'>
                                            <input
                                                type="password"
                                                id='password'
                                                name='password'
                                                placeholder='Введіть пароль'
                                                value={formData.password}
                                                onChange={e => inputHandleChange(e, setFormData)}
                                            />
                                            <ViewPassword onClick={e => showHidePassword(e)} />
                                        </div>
                                    </div>
                                    <div className="fieldset">
                                        <label htmlFor="password_confirmation">Підтвердити пароль</label>
                                        <div className='password_wrapper'>
                                            <input
                                                type="password"
                                                id='password_confirmation'
                                                name='password_confirmation'
                                                placeholder='Введіть пароль'
                                                value={formData.password_confirmation}
                                                onChange={e => inputHandleChange(e, setFormData)}
                                            />
                                            <ViewPassword onClick={e => showHidePassword(e)} />
                                        </div>
                                    </div>
                                    <MyButton type='submit' className="my_button active">
                                        Підтвердити відновлення
                                    </MyButton>
                                </form>
                            </>
                        )}
                    </div>

                </div>
                <div className='half_width poster_wrapper'>
                    <img src={require("assets/login_poster.png")} alt="poster" />
                </div>
            </main>
        </MainLayout>
    )
}

export default PasswordRecovery;
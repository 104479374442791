import ProfilePageTitle from 'components/ProfilePageTitle/ProfilePageTitle';
import React, { useState } from 'react';
import BalanceForm from '../BalanceForm/BalanceForm';
import BalanceAlert from '../BalanceAlert/BalanceAlert';
import "./CashWithdraw.scss"
import Taxes from './Taxes';

const CashWithdrawal = () => {
    const [alert, setAlert] = useState(null)
    const [errors, setErors] = useState('');
    const [value, setValue] = useState('');

    return (
        <div className="inner_block profile_content_block cash-withdrawal">
            <ProfilePageTitle
                title="Виведення коштів"
            />
            {
                alert
                    ?
                    <BalanceAlert
                        title="Ігровий рахунок успішно поповнений. Вдалої гри!"
                        subtitle="Вітаємо вас із успішним поповненням вашого ігрового рахунку! Тепер у вас є більше можливостей для захопливих пригод та вражаючих перемог у своїх улюблених іграх."
                        setAlert={setAlert}
                    />
                    :
                    alert === false &&
                    <BalanceAlert
                        success={false}
                        title="Кошти не виведено"
                        subtitle={errors}
                        setAlert={setAlert}
                    />
            }
            <div className='flex form_wrapper column-if_sm_size align_center-if_sm_size'>
                <BalanceForm
                    sumTitle="Сума виведення"
                    btnTitle="Вивести кошти"
                    CashWithdrawalForm={true}
                    setAlert={setAlert}
                    setErors={setErors}
                    value={value}
                    setValue={setValue}
                />
                <Taxes value={value} />
            </div>
        </div>
    )
}

export default CashWithdrawal;
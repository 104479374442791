import React from 'react';
import "./ErrorBlock.scss";

const ErrorBlock = ({errors = []}) => {
    if(!errors.length) return;
    return (
        <div className="error_wrapper">
            <ul className=''>
                { errors.map((error, index) => (
                    <li key={index}>{error}</li>
                ))}
            </ul>
        </div>
    )
}

export default ErrorBlock;
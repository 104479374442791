const defaultState = {
    ligthMode: false,
}

const SWITCH_THEME = 'SWITCH_THEME'

export const ThemeReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SWITCH_THEME:
            return {...state, ligthMode: !action.payload}
        default:
            return state
    }
}

export const switchThemeAction = (payload) => ({type: SWITCH_THEME, payload})
import React from 'react';
import Confirmed from 'svg/profile/Confirmed';
import IconUpload from 'svg/profile/IconUpload';
import NotConfirmed from 'svg/profile/NotConfirmed';

const ImageUpload = ({ index, image, onImageRemove }) => {
    const sizeTitle = ["B", "Kb", "Mb", "Gb"]

    const getImageSize = (size) => {
        for (let index = 0, formattedSize = size; index < 4; index++) {
            if (formattedSize / 1024 >= 1) {
                formattedSize = formattedSize / 1024;
            } else {
                formattedSize = formattedSize.toFixed(2);
                return `${formattedSize}${sizeTitle[index]}`
            }
        }

    }

    return (
        <div className="image_upload">
            <div className="icon_container">
                <div onClick={() => onImageRemove(index)} className="close_icon">
                    <NotConfirmed />
                </div>
                {
                    !!image['data_url']
                        ?
                        <div className="icon" style={{backgroundImage: `url(${image['data_url']})`}}>
                            {/* <img src={image['data_url']} alt="document_upload" width="100" /> */}
                        </div>
                        :
                        <div className="icon">
                            <IconUpload />
                        </div>
                }

            </div>
            <div className='file_info'>
                <span className='file_name'>{image.file.name}</span>
                <span className='file_size'>{getImageSize(image.file.size)}</span>
            </div>
            <div className='confirmed_wrapper'>
                <Confirmed />
            </div>
        </div>
    )
}

export default ImageUpload;
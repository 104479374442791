import CategoryPage from "pages/CategoryPage/CategoryPage";
import Error404 from "pages/Error404";
import TwitchRedirect from "pages/TwitchRedirect";
import Home from "pages/Home/Home";
import LiveBets from "pages/LiveBets/LiveBets";
import LiveMatchPage from "pages/LiveMatchPage/LiveMatchPage";
import MatchPage from "pages/MatchPage/MatchPage";
import ForgotPassword from "pages/SignForms/ForgotPassword";
import Login from "pages/SignForms/Login";
import PasswordRecovery from "pages/SignForms/PasswordRecovery";
import PasswordRecoveryRequest from "pages/SignForms/PasswordRecoveryRequest";
import PasswordRecoverySuccess from "pages/SignForms/PasswordRecoverySuccess";
import Register from "pages/SignForms/Register";
import { Navigate } from "react-router-dom";
import Profile from "pages/Profile/Profile";
import HelpInfo from "pages/HelpInfo/HelpInfo";
import InfoPage from "pages/InfoPage/InfoPage";
import Balance from "pages/Balance/Balance";
import PersonalInfo from "pages/PersonalInfo/PersonalInfo";
import MyBets from "pages/MyBets/MyBets";
import Documents from "pages/Documents/Documents";
import DocumentsUpload from "pages/DocumentsUpload/DocumentsUpload";
import Search from "pages/Search/Search";

export const publicRoutes = [
    {path: '*', element: <Navigate to="/error-404" />, exact: false, name: 'redirect'},
    {path: '/error-404', element: <Error404 />, exact: false, name: 'error-404'},
    {path: '/twitch-redirect', element: <TwitchRedirect />, exact: false, name: 'twitch-redirect'},
    {path: '/search', element: <Search />, exact: false, name: 'search'},
    {path: '/', element: <Home/>, exact: true, name: 'home'},
    {path: '/login', element: <Login/>, exact: true, name: 'login'},
    {path: '/login/forgot-password', element: <ForgotPassword/>, exact: true, name: 'forgot-password'},
    {path: '/login/password-recovery-request', element: <PasswordRecoveryRequest/>, exact: true, name: 'password-recovery-request'},
    {path: '/login/password-recovery', element: <PasswordRecovery/>, exact: true, name: 'password-recovery'},
    {path: '/login/password-recovery-success', element: <PasswordRecoverySuccess/>, exact: true, name: 'password-recovery-success'},
    {path: '/register', element: <Register/>, exact: false, name: 'register'},

    {path: '/category/:category', element: <CategoryPage/>, exact: true, name: 'category'},
    {path: '/category/:category/:match', element: <MatchPage/>, exact: true, name: 'match'},

    {path: '/live-bets', element: <LiveBets/>, exact: true, name: 'live-bets'},
    {path: '/live-bets/:category/:match', element: <LiveMatchPage/>, exact: true, name: 'live-bets-match'},

    {path: '/profile', element: <Profile />, exact: true, name: 'profile'},
    {path: '/profile/help-info', element: <HelpInfo />, exact: true, name: 'help-info'},
    {path: '/info/:infoPageSlug', element: <InfoPage />, exact: true, name: 'info-page'},
    {path: '/profile/balance', element: <Balance />, exact: true, name: 'balance'},
    {path: '/profile/personal-info', element: <PersonalInfo />, exact: true, name: 'personal-info'},
    {path: '/profile/my-bets', element: <MyBets />, exact: true, name: 'my-bets'},
    {path: '/profile/documents', element: <Documents />, exact: true, name: 'documents'},
    {path: '/profile/documents/:document', element: <DocumentsUpload />, exact: true, name: 'document-upload'},
] 
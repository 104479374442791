import React from 'react';
import "./MobileMenu.scss";
import HomeMobile from 'svg/HomeMobile';
import Profile from 'svg/ProfileIcon';
import Sport from 'svg/Sport';
import CouponIcon from 'svg/CouponIcon';
import ShapeFire from 'svg/ShapeFire';
import { openSidebar } from 'utils/sidebar';
import { Link, useLocation } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import { useState } from 'react';
import { useEffect } from 'react';
import Support from 'svg/Support';

const MobileMenu = () => {
    let location = useLocation();
    const [liveBets, setLiveBets] = useState(false);
    const [sportBets, setSportBets] = useState(false);

    useEffect(() => {
        if (location.pathname.includes('live-bets')) {
            setLiveBets(true);
            setSportBets(false);
        } else if (location.pathname === '/') {
            setLiveBets(false);
            setSportBets(true);
        } else {
            setLiveBets(false);
            setSportBets(false);
        }
    }, [location]);

    return (
        <div className="mobile_menu_wrapper menu_container hide block-if_sm_size">
            <div className='mobile_menu menu flex'>
                <Link
                    to={getRoute('profile')}
                    className="item"
                >
                    <Profile />
                    <span>Профіль</span>
                </Link>
                <Link
                    to={getRoute('live-bets')}
                    className={"item" + (liveBets ? " active" : "")}
                >
                    <ShapeFire />
                    <span>Лайв</span>
                </Link>
                <Link
                    to={getRoute('home')}
                    className={"item" + (sportBets ? " active" : "")}
                >
                    <Sport />
                    <span>Спорт</span>
                </Link>
                <div
                    className="item coupon_mobile_btn"
                    onClick={e => openSidebar(e, ".coupon_sidebar")}
                >
                    <CouponIcon />
                    <span>Купон</span>
                </div>
                {/* <div
                    className="item"
                >
                    <Support />
                    <span>Допомога</span>
                </div> */}
            </div>
        </div>
    )
}

export default MobileMenu;
import React from 'react';

const Edit = ({...props}) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path d="M9.09042 4.86678L7.86835 6.15729C7.41811 6.63274 6.96531 7.10819 6.51764 7.58907C6.41216 7.70318 6.3324 7.85532 6.30153 8.00475C6.13945 8.78992 5.98251 9.57509 5.82557 10.3603L5.73809 10.795C5.69693 11.0069 5.75096 11.2188 5.88732 11.3628C5.9928 11.4742 6.13945 11.5367 6.29382 11.5367C6.33755 11.5367 6.38128 11.5313 6.42503 11.5231L6.87783 11.4226C7.59821 11.2623 8.32116 11.0993 9.04153 10.9417C9.20877 10.9036 9.35027 10.8248 9.47119 10.6972C11.4934 8.55899 13.5156 6.42354 15.5378 4.2908C15.687 4.13323 15.7693 3.94576 15.7899 3.71483C15.7925 3.67679 15.79 3.63876 15.7822 3.60344C15.7693 3.5491 15.759 3.49205 15.7462 3.43771C15.7179 3.29915 15.687 3.14157 15.6252 2.98943C15.247 2.07656 14.6193 1.42452 13.76 1.05774C13.5877 0.984385 13.4049 0.959933 13.2455 0.938198L13.2017 0.932765C12.9701 0.902879 12.7592 0.984385 12.5766 1.18C11.4188 2.40802 10.2534 3.63876 9.09042 4.86678Z" fill="#6E7074" />
            <path d="M2.12765 3.11661H8.12702C8.36067 3.11661 8.54965 2.91705 8.54965 2.67031C8.54965 2.42357 8.36067 2.224 8.12702 2.224H2.12765C1.38889 2.224 0.791016 2.85899 0.791016 3.63549V15.5152C0.791016 16.2953 1.39233 16.9267 2.12765 16.9267H13.3739C14.1126 16.9267 14.7105 16.2917 14.7105 15.5152V9.43747C14.7105 9.19073 14.5215 8.99116 14.2879 8.99116C14.0542 8.99116 13.8653 9.19073 13.8653 9.43747V15.5152C13.8653 15.8019 13.6419 16.0377 13.3705 16.0377H2.12765C1.85619 16.0377 1.63285 15.8019 1.63285 15.5152V3.63912C1.63285 3.35247 1.85619 3.11661 2.12765 3.11661Z" fill="#6E7074" />
        </svg>
    )
}

export default Edit;
import React from 'react';
import "./LiveBets.scss";
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Footer from 'components/Footer/Footer';
import AsideMenu from 'components/AsideMenu/AsideMenu';
import { useSelector } from 'react-redux';
import NoLiveEventsIcon from 'svg/NoLiveEventsIcon';
import NoLiveEventsIconLight from 'svg/NoLiveEventsIconLight';
import NoEvents from 'components/NoEvents/NoEvents';
import CategoryMatchesLive from 'components/CategoryMatchesLive/CategoryMatchesLive';
import MainLayout from 'layouts/MainLayout';

const LiveBets = () => {
    const categories = useSelector(state => state.CategoryReducer.categories);
    /*const allMatches = useSelector(state => state.MatchesReducer.matches);
    let categoriesMatches = categories.map(category => {
        return allMatches.filter(match => category.id === match.categoryId);
    });
    categoriesMatches = categoriesMatches.filter(item => !!item.length)*/
    let categoriesMatches = [];

    return (
        <MainLayout pageName="live">
            <main className="content_block_wrapper small_width">
                <Breadcrumb pages={[
                    { name: "Ставки лайв", routeName: '' },
                ]} />
                <section className='content_block live_page' >
                    {
                        !!categoriesMatches.length
                            ?
                            categoriesMatches.map((categoryMatches, i) =>
                                <CategoryMatchesLive key={i} categoryMatches={categoryMatches} categories={categories} />
                            )
                            :
                            <NoEvents
                                svg={<NoLiveEventsIcon />}
                                svgLight={<NoLiveEventsIconLight />}
                                title="Подій немає"
                                subtitle="Подій Live-ставок немає або закінчились: Відстежуйте Новини та Оновлення на головній сторінці"
                            />
                    }
                </section>
                <Footer />
                <AsideMenu />
            </main >
        </MainLayout>
    )
}

export default LiveBets;
import React from 'react';

const IconTenis = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.0306 0.75804C12.4619 3.17402 13.7501 5.52462 15.7318 7.51229C17.6966 9.48293 20.0677 10.7879 22.4727 11.2223L22.9482 11.3082V11.0692C22.9482 10.0009 22.5326 8.23263 21.9977 7.0255C20.8733 4.48828 18.7633 2.37813 16.2289 1.25651C15.0259 0.724113 13.2484 0.306152 12.187 0.306152H11.9499L12.0306 0.75804ZM9.91755 0.415541C8.26769 0.678553 6.69723 1.28884 5.32949 2.19859C2.6808 3.96036 0.864933 6.82061 0.409064 9.94902C0.309672 10.6311 0.298747 10.596 0.621473 10.6314C1.48725 10.7266 3.04679 11.1891 4.03982 11.6452C7.58128 13.272 10.5164 16.3541 11.9024 19.9015C12.236 20.7552 12.5408 21.9119 12.6221 22.6329C12.6585 22.9556 12.6235 22.9447 13.3054 22.8453C14.909 22.6116 16.459 22.0167 17.888 21.0863C20.3 19.516 22.0752 16.9235 22.6974 14.0627C22.8419 13.3982 22.9284 12.7413 22.8774 12.6954C22.8556 12.6758 22.6585 12.6355 22.4396 12.6061C21.902 12.5337 20.8259 12.2546 20.2118 12.0285C18.7106 11.4755 17.335 10.695 16.0236 9.65199C15.3563 9.12137 14.1198 7.8766 13.5722 7.18443C12.0285 5.23299 11.0372 3.09958 10.6742 0.947536L10.5772 0.372502L10.4263 0.365513C10.3433 0.361665 10.1144 0.38418 9.91755 0.415541ZM0.300781 12.1908C0.300781 12.8615 0.519382 14.1135 0.790886 14.9978C1.74058 18.0909 4.0419 20.6779 7.02495 22.0059C8.21735 22.5367 9.99328 22.9536 11.062 22.9536H11.2991L11.2185 22.5017C10.7885 20.094 9.48477 17.7175 7.51081 15.7436C5.54884 13.7816 3.26154 12.5194 0.820523 12.0516L0.300781 11.952V12.1908Z" fill="#6E7074" />
        </svg>
    )
}

export default IconTenis;
import React from 'react';
import "./InfoPage.scss"
import MainLayout from 'layouts/MainLayout';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Footer from 'components/Footer/Footer';
import AsideMenu from 'components/AsideMenu/AsideMenu';
import ProfilePageTitle from 'components/ProfilePageTitle/ProfilePageTitle';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const InfoPage = () => {
    const pagesLinksData = useSelector(state => state.LinksDataReducer.pages_links_data);
    const helpInfoLinkData = pagesLinksData.help_info;

    const {
        belongs_to_help_info,
        image_path,
        title,
        subtitle,
        body
    } = useSelector(state => state.InfoPageReducer);

    const params = useParams();

    return (
        <MainLayout pageName="info-pages" pageSlug={params.infoPageSlug}>
            <main className="content_block_wrapper small_width text-info_page">
                {
                    belongs_to_help_info
                        ?
                        <Breadcrumb pages={[
                            { name: helpInfoLinkData?.title, routeName: 'help-info' },
                            { name: title, routeName: '' },
                        ]} prevPage={true} profile={false} />
                        :
                        <Breadcrumb pages={[
                            { name: title, routeName: '' },
                        ]} prevPage={true} profile={false} />
                }
                <section className='content_block profile_content_block'>
                    {
                        image_path &&
                        <img src={image_path} alt="poster" />
                    }
                    <div className="inner_block">
                        <ProfilePageTitle
                            title={title}
                            subtitle={subtitle}
                        />
                        <div className='body_text' dangerouslySetInnerHTML={{ __html: body }} />
                    </div>
                </section>
                <Footer />
                <AsideMenu />
            </main >
        </MainLayout>
    )
}

export default InfoPage;
import React from 'react';

const FiltersEmpty = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="64" viewBox="0 0 55 64" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.75781 6.15552H52.7578C53.8618 6.15552 54.7578 5.25952 54.7578 4.15552V2.15552C54.7578 1.05152 53.8618 0.155518 52.7578 0.155518H2.75781C1.65381 0.155518 0.757812 1.05152 0.757812 2.15552V4.15552C0.757812 5.25952 1.65381 6.15552 2.75781 6.15552ZM21.6918 32.1555H33.8238L53.7578 9.15552H1.75781L21.6918 32.1555ZM21.7578 63.1555L33.7578 56.0155V35.1555H21.7578V63.1555ZM22.0222 21.8747C23.5545 20.3424 25.5919 19.4985 27.7589 19.4985C29.926 19.4985 31.9633 20.3424 33.4956 21.8747C34.0419 22.421 34.0419 23.3067 33.4956 23.8529C32.9493 24.3991 32.0636 24.3991 31.5174 23.8529C30.5135 22.849 29.1787 22.2961 27.7589 22.2961C26.3391 22.2961 25.0043 22.8489 24.0004 23.8529C23.7273 24.126 23.3694 24.2626 23.0114 24.2626C22.6534 24.2626 22.2954 24.126 22.0222 23.8529C21.476 23.3066 21.476 22.4209 22.0222 21.8747ZM23.1094 16.4507C23.4572 16.1038 23.6559 15.6226 23.6559 15.1321C23.6559 14.6416 23.4572 14.1604 23.1094 13.8135C22.7625 13.4666 22.2813 13.2671 21.7908 13.2671C21.2994 13.2671 20.8191 13.4666 20.4722 13.8135C20.1253 14.1604 19.9258 14.6416 19.9258 15.1321C19.9258 15.6226 20.1253 16.1038 20.4722 16.4507C20.8191 16.7976 21.3003 16.9972 21.7908 16.9972C22.2813 16.9972 22.7625 16.7976 23.1094 16.4507ZM32.4077 13.8135C32.7546 13.4666 33.2349 13.2671 33.7263 13.2671C34.2168 13.2671 34.698 13.4666 35.0449 13.8135C35.3927 14.1604 35.5914 14.6416 35.5914 15.1321C35.5914 15.6226 35.3927 16.1038 35.0449 16.4507C34.698 16.7975 34.2168 16.9972 33.7263 16.9972C33.2358 16.9972 32.7546 16.7976 32.4077 16.4507C32.0599 16.1038 31.8613 15.6226 31.8613 15.1321C31.8613 14.6416 32.0599 14.1604 32.4077 13.8135Z" fill="white" />
        </svg>
    )
}

export default FiltersEmpty;
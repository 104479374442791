import React from 'react';

const Search = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.0166 10.0553C18.0166 14.1113 14.7285 17.3994 10.6725 17.3994C6.61644 17.3994 3.32837 14.1113 3.32837 10.0553C3.32837 5.99925 6.61644 2.71118 10.6725 2.71118C14.7285 2.71118 18.0166 5.99925 18.0166 10.0553ZM16.7223 17.938C15.0468 19.2258 12.9491 19.9915 10.6725 19.9915C5.1849 19.9915 0.736328 15.5429 0.736328 10.0553C0.736328 4.56771 5.1849 0.119141 10.6725 0.119141C16.1601 0.119141 20.6086 4.56771 20.6086 10.0553C20.6086 12.3319 19.843 14.4296 18.5552 16.1051L24.1171 21.6671C24.6232 22.1732 24.6232 22.9938 24.1171 23.4999C23.611 24.006 22.7904 24.006 22.2843 23.4999L16.7223 17.938Z" fill="#6E7074" />
        </svg>
    )
}

export default Search;
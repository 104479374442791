import React, { useEffect, useRef } from 'react';
import './Footer.scss';
import { Link, useLocation } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import { handleFooterScroll } from 'utils/footer';
import ArrowDown from 'svg/ArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import variables from 'styles/variables.module.scss';
import { updateBalanceCashTabAction } from 'store/AccountReducer';

const Footer = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const footer = useRef();

    const helpInfoLinkData = useSelector(state => state.LinksDataReducer.pages_links_data.help_info);

    const infoPagesLinksArray = useSelector(state => state.LinksDataReducer.info_pages_links_data);
    const resultArray = Object.keys(infoPagesLinksArray).map(key => ({
        key: key,
        data: infoPagesLinksArray[key]
    }));


    useEffect(() => {
        if (window.innerWidth <= Number(variables.smSizeMaxWidth)) {
            const submenuBlocks = footer.current.querySelectorAll(".has_submenu");
            Array.from(submenuBlocks).forEach(element => {
                element.addEventListener("click", (e) => {
                    e.target.closest(".item_container").classList.toggle("active");
                })
            })
        }


        handleFooterScroll();
        window.addEventListener('scroll', handleFooterScroll);

        return () => {
            window.removeEventListener('scroll', handleFooterScroll);
        };
    }, []);

    return (
        <footer className='' id="footer" ref={footer}>

            <div className='item_container'>
                <div className="item flex space_between align_center has_submenu">
                    <span className="title">Допомога</span>
                    <ArrowDown />
                </div>
                <div className="item_submenu flex column">
                    <Link to={getRoute('balance')} onClick={() => dispatch(updateBalanceCashTabAction(false))} className='subtitle'>
                        Поповнити рахунок
                    </Link>
                    <Link to={getRoute('balance')} onClick={() => dispatch(updateBalanceCashTabAction(true))} className='subtitle'>
                        Виведення коштів
                    </Link>
                </div>
            </div>

            <div className='item_container'>
                <div className="item flex space_between align_center has_submenu">
                    <span className="title">Ставки на спорт</span>
                    <ArrowDown />
                </div>
                <div className="item_submenu flex column">
                    <Link
                        to={getRoute('live-bets')}
                        className={"subtitle item" + (
                            location.pathname === getRoute('live-bets') ? " current_nav" : ""
                        )}
                    >
                        LIVE ставки
                    </Link>
                    <Link
                        to={getRoute('home')}
                        className={"subtitle item" + (
                            location.pathname === getRoute('home') ? " current_nav" : ""
                        )}
                    >
                        Ставки спорт
                    </Link>
                </div>
            </div>

            <div className='item_container'>
                <div className="item flex space_between align_center has_submenu">
                    <Link
                        to={getRoute('info-page', ['about-us'])}
                        className={"title" + (
                            location.pathname === getRoute('info-page', ['about-us']) ? " current_nav" : ""
                        )}
                    >
                        Про компанію
                    </Link>
                    <ArrowDown />
                </div>
                <div className="item_submenu flex column">
                    {
                        resultArray.map(item =>
                            <Link
                                key={item.key}
                                to={getRoute('info-page', [item.data.slug])}
                                className={"subtitle item" + (
                                    location.pathname === getRoute('info-page', [item.data.slug]) ? " current_nav" : ""
                                )}
                            >
                                {item.data.title}
                            </Link>
                        )
                    }
                </div>
            </div>

            <div className='item_container'>
                <div className="item flex space_between align_center has_submenu">
                    <Link
                        to={getRoute('profile')}
                        className={"title" + (location.pathname === getRoute('profile') ? " current_nav" : "")}
                    >
                        Мій кабінет
                    </Link>
                    <ArrowDown />
                </div>
                <div className="item_submenu flex column">
                    <Link
                        to={getRoute('personal-info')}
                        className={"subtitle item" + (
                            location.pathname === getRoute('personal-info') ? " current_nav" : ""
                        )}
                    >
                        Персональні дані
                    </Link>
                    <Link
                        to={getRoute('documents')}
                        className={"subtitle item" + (
                            location.pathname === getRoute('documents') ? " current_nav" : ""
                        )}
                    >
                        Документи
                    </Link>
                    <Link
                        to={getRoute('help-info')}
                        className={"subtitle item" + (
                            location.pathname === getRoute('help-info') ? " current_nav" : ""
                        )}
                    >
                        {helpInfoLinkData?.title}
                    </Link>
                    <Link
                        to={getRoute('my-bets')}
                        className={"subtitle item" + (
                            location.pathname === getRoute('my-bets') ? " current_nav" : ""
                        )}
                    >
                        Мої ставки
                    </Link>
                    <Link
                        to={getRoute('balance')}
                        className={"subtitle item" + (
                            location.pathname === getRoute('balance') ? " current_nav" : ""
                        )}
                    >
                        Баланс
                    </Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
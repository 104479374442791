import React, { useEffect } from 'react';
import "./Documents.scss"
import MainLayout from 'layouts/MainLayout';
import Footer from 'components/Footer/Footer';
import AsideMenu from 'components/AsideMenu/AsideMenu';
import { useDispatch, useSelector } from 'react-redux';
import { updatePagesRedirectAction } from 'store/AccountReducer';
import { useNavigate } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import ProfilePageTitle from 'components/ProfilePageTitle/ProfilePageTitle';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import DocumentsCardList from 'components/DocumentsCardList/DocumentsCardList';

const Documents = () => {
    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth === false) {
            dispatch(updatePagesRedirectAction('documents'));
            navigate(getRoute("login"));
        }
    }, [isAuth, dispatch, navigate])

    return (
        <MainLayout pageName="documents">
            <main className="content_block_wrapper small_width documents_page">
                <Breadcrumb pages={[
                    { name: "Підтверди особу", routeName: '' },
                ]} prevPage={true} profile={true} />
                <section className='content_block profile_content_block'>
                    <ProfilePageTitle
                        title="Підтверди особу"
                        subtitle="Для забезпечення вашої безпеки та конфіденційності, ми пропонуємо ряд методів верифікації вашого облікового запису. "
                    />
                    <DocumentsCardList />
                </section>
                <Footer />
                <AsideMenu />
            </main>
        </MainLayout>
    )
}

export default Documents;
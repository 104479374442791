import React, { useEffect, useState } from 'react';
import "./TabMenu.scss";
import { useSelector } from 'react-redux';
import { OddsTypesConst } from 'constants/OddsTypesConst';

const TabMenu = ({ oddsGroups, setOddsList }) => {
    const oddsTypes = useSelector(state => state.OddsTypesReducer.odds_types);
    
    const [tabs, setTabs] = useState([{ id: 1, title: 'Всі', oddsType: -1 }])
    useEffect(() => {
        Object.entries(oddsGroups).map(([oddTypeId], index) => {
            let oddsType = oddsTypes.find(type => type.id == oddTypeId);
            if (!Object.values(OddsTypesConst).includes(oddsType.id)) return;
            if(tabs.find(tab => tab.oddsType === oddsType.id)) return;
            setTabs(prevValue => {
                return [
                    ...prevValue,
                    {id: index + 2, title: oddsType.translation.title, oddsType: oddsType.id}
                ]
            })
        })
    }, [oddsGroups, oddsTypes])

    const toggleTab = (e) => {
        Array.from(e.target.parentElement.children).forEach(tab => {
            tab.classList.remove("active");
        })
        e.target.classList.add("active");

        const dataOdd = Number(e.target.dataset.odds);
        if (!dataOdd) return
        if (dataOdd === -1) {
            setOddsList(oddsGroups)
        } else setOddsList({ [dataOdd]: oddsGroups[dataOdd] })
    }

    useEffect(() => {
        setOddsList(oddsGroups)
    }, [])

    return (
        <div className="tab_menu">
            <div className="tab_list flex">
                {
                    tabs
                        .filter(tab => tab.oddsType)
                        .map((tab, index) =>
                            <div
                                data-odds={tab.oddsType}
                                key={tab.id}
                                onClick={toggleTab}
                                className={"tab" + (index === 0 ? " active" : "")}
                            >
                                {tab.title}
                            </div>
                        )
                }
            </div>
        </div>
    )
}

export default TabMenu;
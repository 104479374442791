const defaultState = {
    matches: {}
}

const UPDATE_MAIN_MATCHES = 'UPDATE_MAIN_MATCHES';

export const MainMatchesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_MAIN_MATCHES:
            return { ...state, matches: action.payload }
        default:
            return state;
    }
}

export const updateMainMatchesAction = (payload) => ({ type: UPDATE_MAIN_MATCHES, payload });
import React from 'react';

const Question = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.1831 30.3994C23.4693 30.3994 30.1865 23.6822 30.1865 15.396C30.1865 7.10984 23.4693 0.392578 15.1831 0.392578C6.89695 0.392578 0.179688 7.10984 0.179688 15.396C0.179688 23.6822 6.89695 30.3994 15.1831 30.3994ZM14.1606 16.6635V17.4235H15.6046L15.6806 16.8915C15.7439 16.4862 15.8896 16.0809 16.1176 15.6755C16.3456 15.2702 16.7573 14.7952 17.3526 14.2505C18.0113 13.6552 18.4673 13.1042 18.7206 12.5975C18.9866 12.0909 19.1196 11.5462 19.1196 10.9635C19.1196 10.3302 18.9486 9.7792 18.6066 9.31053C18.2773 8.84187 17.8086 8.47453 17.2006 8.20853C16.6053 7.94253 15.9086 7.80953 15.1106 7.80953C14.2999 7.80953 13.4956 7.9552 12.6976 8.24653C11.9123 8.53786 11.2536 8.9242 10.7216 9.40553L11.5196 11.2295C12.5709 10.3175 13.7173 9.86153 14.9586 9.86153C15.5159 9.86153 15.9466 9.98186 16.2506 10.2225C16.5546 10.4632 16.7066 10.7925 16.7066 11.2105C16.7066 11.5652 16.6243 11.9009 16.4596 12.2175C16.3076 12.5342 16.0289 12.9142 15.6236 13.3575C15.0916 13.9529 14.7116 14.5039 14.4836 15.0105C14.2683 15.5172 14.1606 16.0682 14.1606 16.6635ZM13.5526 18.7535V21.3945H16.2126V18.7535H13.5526Z" fill="#6E7074" />
        </svg>
    )
}

export default Question;
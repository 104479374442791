import React from 'react';

const ProcessingDocuments = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <rect width="3.11786" height="1" rx="0.5" transform="matrix(1 0 0.00584119 0.999983 6.64941 7.02539)" fill="white" />
            <path d="M7.15272 3.86036C7.42886 3.86197 7.65271 4.08714 7.65271 4.36328L7.65271 7.5245C7.65271 7.80064 7.42886 8.02319 7.15272 8.02158C6.87658 8.01997 6.65273 7.7948 6.65273 7.51866L6.65273 4.35744C6.65273 4.0813 6.87658 3.85875 7.15272 3.86036Z" fill="white" />
            <circle cx="7.15268" cy="7.17905" r="5.79037" stroke="white" />
        </svg>
    )
}

export default ProcessingDocuments;
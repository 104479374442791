import React, { useEffect, useState } from 'react';
import './Bet.scss';
import MyButton from 'components/UI/MyButton/MyButton';
import ArrowDown from "svg/ArrowDown";
import Remove from 'svg/Remove';
import BetSuccess from 'svg/bet/BetSuccess';
import BetUnSuccess from 'svg/bet/BetUnSuccess';
import { getRoute } from 'utils/routes';
import CoefBlock from './CoefBlock/CoefBlock';
import SetBet from './SetBet/SetBet';
import { useDispatch, useSelector } from 'react-redux';
import PostService from 'api/PostService';
import { updateHistoryBetsAction, updateMaxBetWin, updateMinBetWin } from 'store/BetReducer';
import { updateUserDataAction } from 'store/AccountReducer';
import { useUpdateOdds } from 'hooks/useOdds';

const Bet = React.forwardRef(({ betCards, setBetCards, ...props }, ref) => {
    const dispatch = useDispatch();
    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);
    const minBetWin = useSelector(state => state.BetReducer.minBetWin);

    const [betSuccess, setBetSuccess] = useState(false);
    const [betErrors, setBetErrors] = useState([]);
    const [backendBetErrors, setBackendBetErrors] = useState([]);

    const closeBet = (e) => {
        e.currentTarget.parentElement.classList.toggle('open');
    }

    useEffect(() => {
        if (!betCards.length) {
            setBetErrors([])
            setBackendBetErrors([])
            return
        }
        if (betCards.length) {
            setBetSuccess(false)
        }
        if (backendBetErrors.find(error => error.type === 'suspended')) {
            if (!betCards.find(card => card.stop) && !betCards.find(card => card.sport_match.is_stopped)) {
                setBackendBetErrors(backendBetErrors.filter(obj => obj.type !== 'suspended'));
            }
        }
    }, [betCards])

    const [sumValue, setSumValue] = useState('');
    const couponOdds = useSelector(state => state.BetReducer.couponOdds);

    const updateOdds = useUpdateOdds();
    const submitForm = (e) => {
        e.preventDefault();

        if (String(sumValue).startsWith('.')) {
            setSumValue('0' + sumValue)
        }

        const data = {
            amount: sumValue,
            coupon_odds: couponOdds
        }
        PostService.sendBet(data)
            .then((response) => {
                if (response.status === 200) {
                    updateOdds(response);

                    dispatch(updateMinBetWin(response.data.min_bet_amount));
                    dispatch(updateMaxBetWin(response.data.max_bet_win));
                    dispatch(updateUserDataAction(response.data.user));

                    if (response.data.status === 'error') {
                        if (response.data.messages) {
                            // console.log(response.data.messages);
                            setBetErrors(response.data.messages);
                            setBackendBetErrors(response.data.messages);
                        }
                    } else {
                        setSumValue('')
                        setBetErrors([])
                        setBackendBetErrors([]);
                        setBetSuccess(true)

                        if (response.data.history_bets) {
                            dispatch(updateHistoryBetsAction(response.data.history_bets))
                        }
                    }
                }
            })
    }

    return (
        <form
            onSubmit={submitForm}
            ref={ref}
            className={
                "bet open" +
                (!isAuth ? " no-auth" : "") +
                (betCards.length < 1 ? " no-bets" : "") +
                (
                    betSuccess || (betCards.length < 1 && betErrors[0]?.type === 'invalid_coupon')
                        ?
                        " form_success"
                        : (
                            (betErrors.length ? " bet_alert_active" : "") +
                            ((!sumValue) && isAuth ? " submit_block" : "")
                        )
                )
            }
        >
            <span onClick={(e) => closeBet(e)} className="block_title pointer">
                Налаштування ставки
                <ArrowDown />
            </span>

            <div className='scroll_wrapper'>
                <SetBet
                    betCards={betCards}
                    sumValue={sumValue}
                    setSumValue={setSumValue}
                    setBetErrors={setBetErrors}
                    backendBetErrors={backendBetErrors}
                    setBackendBetErrors={setBackendBetErrors}
                />
                {
                    !!betErrors.find(obj => obj.type === 'min_amount') &&
                    <span className='min_bet'>Мінімальна ставка {minBetWin} грн</span>
                }
                {
                    betSuccess
                        ?
                        !betCards.length &&
                        <div className='flex bet_alert success'>
                            <BetSuccess />
                            <span>
                                Ставка підтверджена!
                            </span>
                            <Remove onClick={() => setBetSuccess(false)} />
                        </div>
                        :
                        !!betErrors.length &&
                        betErrors.map((error) =>
                            <div key={error.type} className={'flex bet_alert unsuccess' + (error.type === 'invalid_coupon' ? " invalid_coupon" : "")}>
                                <BetUnSuccess />
                                <span>
                                    {error.message}
                                </span>
                            </div>
                        )
                }
                {
                    !!isAuth
                        ?
                        <MyButton
                            onClick={submitForm}
                            className={"medium active my_button" + (betCards.length < 1 ? " no-bets_btn" : "")}
                        >
                            Зробити ставку
                        </MyButton>
                        :
                        <MyButton href={getRoute('login')} className="medium active my_button">Ввійти в акаунт</MyButton>
                }

                <CoefBlock />
            </div>
        </form>
    )
});

export default Bet;
import React, { useEffect, useState } from 'react';
import "./Balance.scss"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateBalanceCashTabAction, updatePagesRedirectAction } from 'store/AccountReducer';
import { getRoute } from 'utils/routes';
import MainLayout from 'layouts/MainLayout';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Footer from 'components/Footer/Footer';
import AsideMenu from 'components/AsideMenu/AsideMenu';
import BalanceTabMenu from 'components/PageBalance/BalanceTabMenu/BalanceTabMenu';
import DepositeBlock from 'components/PageBalance/DepositeBlock';
import CashWithdrawal from 'components/PageBalance/CashWithdraw/CashWithdraw';
import DepositHistory from 'components/PageBalance/DepositOutputHistory/DepositHistory';
import { handleFooterScroll } from 'utils/footer';
import OutputHistory from 'components/PageBalance/DepositOutputHistory/OutputHistory';

const Balance = () => {
    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth === false) {
            dispatch(updatePagesRedirectAction('balance'));
            navigate(getRoute("login"));
        }
    }, [isAuth, dispatch, navigate])



    
    const minDeposit = useSelector(state => state.BalanceReducer.min_deposit);
    const getSummOptions = () => {
        const array = [
            { id: 'all_summ_bets', name: 'Будь-яка сума депозиту' },
            { id: 'less_10', name: '<10' },
            { id: 'more_10', name: '>10' },
            { id: 'more_50', name: '>50' },
            { id: 'more_100', name: '>100' },
            { id: 'less_500', name: '<500' },
            { id: 'less_1000', name: '<1000' },
        ];
        const newArray = array.filter(item => {
            const number = parseInt(item.name.replace(/[<>]=?/, ''), 10);
            return number >= minDeposit
        })
        return newArray
    }

    const sortingData = [
        {
            id: 1,
            title: 'Стан',
            filterType: 'state',
            options: [
                { id: 'all_transactions', name: 'Всі транзакції' },
                { id: 'pending', name: 'На розгляді' },
                { id: 'cancelled', name: 'Скасовано' },
                { id: 'completed', name: 'Завершено' },
            ],
        },
        {
            id: 2,
            title: 'Сума',
            filterType: 'summ',
            options: getSummOptions(),
        },
        {
            id: 3,
            title: 'Дата',
            filterType: 'date',
            options: [
                { id: 'all_date', name: 'Будь-яка дата' },
                { id: 'today_yesterday', name: 'Сьогодні та вчора' },
                { id: 'for_7_days', name: 'Протягом 7 днів' },
                { id: 'for_30_days', name: 'Протягом 30 днів' },
                { id: 'for_90_days', name: 'Протягом 90 днів' },
            ],
        },
    ]


    const balanceCashTab = useSelector(state => state.AccountReducer.balanceCashTab);
    const [activeTab, setActiveTab] = useState(balanceCashTab ? 2 : 1);
    useEffect(() => {
        handleFooterScroll();
    }, [activeTab])

    const renderTabBlock = () => {
        if (balanceCashTab === true) {
            setActiveTab(2);
            dispatch(updateBalanceCashTabAction(null))
            return <CashWithdrawal />;
        } else if (balanceCashTab === false) {
            setActiveTab(1);
            dispatch(updateBalanceCashTabAction(null))
            return <DepositeBlock />;
        }
 
        switch (activeTab) {
            case 1:
                return <DepositeBlock />;
            case 2:
                return <CashWithdrawal />;
            case 3:
                return <DepositHistory sortingData={sortingData} />;
            case 4:
                return <OutputHistory sortingData={sortingData} />;
            default:
                return null;
        }
    }

    return (
        <MainLayout pageName="balance">
            <main className="content_block_wrapper small_width balance_page">
                <Breadcrumb pages={[
                    { name: "Баланс", routeName: '' },
                ]} prevPage={true} profile={true} />
                <section className='content_block'>
                    <BalanceTabMenu activeTab={activeTab} setActiveTab={setActiveTab} />
                    {renderTabBlock()}
                </section>
                <Footer />
                <AsideMenu />
            </main >
        </MainLayout>
    )
}

export default Balance;
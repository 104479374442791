import React from 'react';

const FiltersClear = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.72638 0.389107C1.12307 0.577634 0.656443 1.15751 0.650281 1.72635L0.647583 1.97501L4.20208 5.92541L7.75654 9.87581L10.0419 9.87899L12.3273 9.88217L10.0407 10.6479L7.75416 11.4136L7.76519 14.5031L7.77623 17.5927L7.93399 17.9128C8.07157 18.1918 8.16959 18.3107 8.69921 18.8408C9.35315 19.4952 10.8676 20.7432 11.1747 20.8808C11.3102 20.9415 11.4149 20.9563 11.6255 20.9444C11.8588 20.9312 11.9228 20.9104 12.0532 20.8054C12.2283 20.6642 12.3956 20.4343 12.4735 20.2274C12.5167 20.1127 12.5293 19.1028 12.5388 14.9706L12.5507 9.85567L16.114 5.89653C18.9881 2.70322 19.6774 1.91637 19.677 1.82877C19.6763 1.67919 19.5841 1.34624 19.4963 1.17644C19.3109 0.818075 18.9376 0.504213 18.5604 0.389792C18.2867 0.306705 1.99212 0.306061 1.72638 0.389107ZM15.3479 11.5031C15.1848 11.5787 14.2151 12.5649 14.1609 12.7101C14.0583 12.9851 14.1053 13.0522 15.1109 14.0655L16.0512 15.013L15.1248 15.9383C14.131 16.9307 14.0807 17.0014 14.1605 17.2906C14.1895 17.3956 14.3475 17.5785 14.7596 17.9841L15.3192 18.5349L15.5148 18.5346C15.6397 18.5345 15.754 18.5061 15.8312 18.4562C15.8977 18.4132 16.336 17.9829 16.8052 17.4999L17.6585 16.6218L18.5773 17.5338C19.0827 18.0354 19.5358 18.4659 19.5842 18.4904C19.6326 18.5148 19.7581 18.5349 19.8632 18.5349H20.0543L20.6311 17.9581L21.2079 17.3813V17.146V16.9108L20.2529 15.9503L19.2978 14.9899L20.2529 14.0319L21.2079 13.0738V12.8574V12.6411L20.6566 12.0847C20.3021 11.727 20.0576 11.5126 19.9718 11.4843C19.6958 11.3932 19.6384 11.434 18.6296 12.4371L17.6824 13.379L16.8172 12.5107C16.3414 12.0332 15.9061 11.6091 15.85 11.5681C15.7065 11.4635 15.4928 11.4358 15.3479 11.5031Z" fill="#6E7074" />
        </svg>
    )
}

export default FiltersClear;
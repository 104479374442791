import React from 'react';

const ShapeFire = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" viewBox="0 0 20 23" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.7758 1.12861C11.6612 0.842162 11.4208 0.624638 11.1244 0.539158C10.828 0.453677 10.5087 0.509811 10.2592 0.691272C7.09749 2.99067 6.70513 6.30968 7.0434 8.88046C7.15364 9.71833 7.34203 10.4941 7.54488 11.158C7.05049 10.6523 6.62585 9.94456 6.2665 9.10609C5.92112 8.3002 5.68693 7.48379 5.53891 6.86214C5.46537 6.55324 5.41417 6.29699 5.38169 6.12014C5.36546 6.03181 5.35396 5.96357 5.34671 5.9188L5.33898 5.86974L5.33719 5.85777C5.28422 5.48909 5.03071 5.18037 4.67932 5.05671C4.32767 4.93296 3.93637 5.01509 3.66415 5.26978L4.34707 5.99969C3.66415 5.26978 3.66327 5.27061 3.66299 5.27087L3.66153 5.27224L3.65777 5.27579L3.64689 5.28615C3.63818 5.29449 3.62655 5.30572 3.61223 5.31976C3.58358 5.34785 3.54406 5.38727 3.49529 5.4376C3.3978 5.53818 3.26292 5.68271 3.10352 5.86775C2.78544 6.23697 2.3656 6.77225 1.94983 7.44593C1.12362 8.78464 0.277745 10.7289 0.347825 13.0304C0.48394 17.5005 4.17089 22.75 9.84735 22.75C15.4377 22.75 19.3474 18.5134 19.3474 13C19.3474 10.0649 17.6968 8.18523 16.0545 6.54289C15.8443 6.33269 15.6343 6.12611 15.4259 5.92116C13.9559 4.47507 12.5685 3.11021 11.7758 1.12861Z" fill="#6E7074" />
        </svg>
    )
}

export default ShapeFire;
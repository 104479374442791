import React, { useState } from 'react';
import './SignForms.scss';
import MyButton from 'components/UI/MyButton/MyButton';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { useFetching } from 'hooks/useFetching';
import AccountService from '../../api/AccountService';
import { getRoute } from '../../utils/routes';
import { useNavigate } from 'react-router-dom';
import ErrorBlock from '../../components/Form/ErrorBlock/ErrorBlock';
import Loader from '../../components/UI/Loader/Loader';
import MainLayout from 'layouts/MainLayout';
import { inputHandleChange, pushError } from 'utils/form';

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [showErrors, setShowErrors] = useState([]);
    const [formData, setFormData] = useState({
        email: '',
        url: window.location.origin + getRoute('password-recovery'),
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchForgotPassword();
    }

    const [fetchForgotPassword, isForgotPasswordLoading] = useFetching(async () => {
        const response = await AccountService.forgotPassword(formData);

        let errors = [];

        if (response.status === 200) {
            navigate(getRoute("password-recovery-request"));
        } else errors = pushError(response);

        setShowErrors(errors);
    });

    return (
        <MainLayout pageName="forgot-password">
            <main className="content_block_wrapper flex sign_form column-if_sm_size">
                <div className='half_width content'>

                    <div className="content_inner">
                        {isForgotPasswordLoading ? (
                            <div className="flex_centered_item">
                                <Loader />
                            </div>
                        ) : (
                            <>
                                <Breadcrumb pages={[
                                    { name: 'Авторизація', routeName: 'login' },
                                    { name: 'Відновлення пароля', routeName: '' },
                                ]} />
                                <form onSubmit={handleSubmit}>
                                    <h1 className='title'>Відновлення пароля</h1>

                                    <ErrorBlock errors={showErrors} />

                                    <p className='subtitle'>Для завершення процедури відновлення та повернення до користування нашими послугами, будь ласка, відкрийте свою пошту та знайдіть лист від нас. </p>
                                    <div className="fieldset">
                                        <label htmlFor="email">E-mail</label>
                                        <input
                                            type="email"
                                            id='email'
                                            name='email'
                                            placeholder='Введіть E-mail'
                                            value={formData.email}
                                            onChange={e => inputHandleChange(e, setFormData)}
                                        />
                                    </div>
                                    <MyButton type='submit' className="my_button active">
                                        Надіслати запиит на E-mail
                                    </MyButton>
                                </form>
                            </>
                        )}
                    </div>

                </div>
                <div className='half_width poster_wrapper'>
                    <img src={require("assets/login_poster.png")} alt="poster" />
                </div>
            </main>
        </MainLayout>
    )
}

export default ForgotPassword;
import React from 'react';
import "./PersonalInfoTabMenu.scss";

const PersonalInfoTabMenu = ({setActiveTab}) => {
    const toggleTab = (e) => {
        Array.from(e.target.parentElement.children).forEach(tab => {
            tab.classList.remove("active");
        })
        e.target.classList.add("active");
        setActiveTab(Number(e.target.dataset.tabId));
    }

    return (
        <div className="tab_menu-personal-info">
            <div className="tab_list flex">
                <div data-tab-id="1" onClick={e => toggleTab(e)} className="tab active">Загальна інформація </div>
                <div data-tab-id="0" onClick={e => toggleTab(e)} className="tab">Змінити пароль</div>
            </div>
        </div>
    )
}

export default PersonalInfoTabMenu;
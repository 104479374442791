import IconAmericanFootball from "svg/categories/IconAmericanFootball";
import IconBasketball from "svg/categories/IconBasketball";
import IconBeysball from "svg/categories/IconBeysball";
import IconFootball from "svg/categories/IconFootball";
import IconHokey from "svg/categories/IconHokey";
import IconKibersport from "svg/categories/IconKibersport";
import IconTenis from "svg/categories/IconTenis";
import IconVoleyball from "svg/categories/IconVoleyball";

const defaultState = {
    categoryParam: '',
    categories: []
}

const UPDATE_PARAM = 'UPDATE_PARAM';
const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';

export const CategoryReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_PARAM:
            return {...state, categoryParam: action.payload}
        case UPDATE_CATEGORIES:
            return {...state, categories: action.payload}
        default:
            return state;
    }
} 

export const updateParamAction = (payload) => ({type: UPDATE_PARAM, payload});
export const updateCategoriesAction = (payload) => ({type: UPDATE_CATEGORIES, payload});

export const categoriesIconsMapping = {
    football: <IconFootball />,
    basketball: <IconBasketball />,
    volleyball: <IconVoleyball />,
    esports: <IconKibersport />,
    tennis: <IconTenis />,
    hockey: <IconHokey />,
    'american-football': <IconAmericanFootball />,
    baseball: <IconBeysball />,
}
import React from 'react';

const Twitch = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="27" viewBox="0 0 23 27" fill="none">
            <path d="M10.8555 5.30595H12.7018V10.8317H10.8555M15.9294 5.30595H17.7757V10.8317H15.9294M4.86497 0.232056L0.255859 4.84117V21.4443H5.78163V26.0534L10.4037 21.4443H14.0832L22.3848 13.1427V0.232056M20.5385 12.2261L16.859 15.9056H13.1665L9.93887 19.1333V15.9056H5.78163V2.07828H20.5385V12.2261Z" fill="white" />
        </svg>
    )
}

export default Twitch;
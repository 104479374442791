import React from 'react';

const Question2 = ({ ...props }) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M14.936 8.00003C14.936 11.9876 11.7043 15.22 7.71799 15.22C3.73173 15.22 0.5 11.9876 0.5 8.00003C0.5 4.01241 3.73173 0.780029 7.71799 0.780029C11.7043 0.780029 14.936 4.01241 14.936 8.00003Z" stroke="white" />
            <path d="M8.2168 9.78198H7.12695C7.13086 9.40698 7.16406 9.10034 7.22656 8.86206C7.29297 8.61987 7.40039 8.39917 7.54883 8.19995C7.69727 8.00073 7.89453 7.77417 8.14062 7.52026C8.32031 7.33667 8.48438 7.16479 8.63281 7.00464C8.78516 6.84058 8.9082 6.66479 9.00195 6.47729C9.0957 6.28589 9.14258 6.05737 9.14258 5.79175C9.14258 5.52222 9.09375 5.28979 8.99609 5.09448C8.90234 4.89917 8.76172 4.74878 8.57422 4.64331C8.39062 4.53784 8.16211 4.48511 7.88867 4.48511C7.66211 4.48511 7.44727 4.52612 7.24414 4.60815C7.04102 4.69019 6.87695 4.81714 6.75195 4.98901C6.62695 5.15698 6.5625 5.37769 6.55859 5.65112H5.47461C5.48242 5.20972 5.5918 4.83081 5.80273 4.5144C6.01758 4.198 6.30664 3.95581 6.66992 3.78784C7.0332 3.61987 7.43945 3.53589 7.88867 3.53589C8.38477 3.53589 8.80664 3.62573 9.1543 3.80542C9.50586 3.98511 9.77344 4.24292 9.95703 4.57886C10.1406 4.91089 10.2324 5.30542 10.2324 5.76245C10.2324 6.11401 10.1602 6.43823 10.0156 6.73511C9.875 7.02808 9.69336 7.30347 9.4707 7.56128C9.24805 7.81909 9.01172 8.06519 8.76172 8.29956C8.54688 8.49878 8.40234 8.72339 8.32812 8.97339C8.25391 9.22339 8.2168 9.49292 8.2168 9.78198ZM7.08008 11.6394C7.08008 11.4636 7.13477 11.3152 7.24414 11.1941C7.35352 11.073 7.51172 11.0125 7.71875 11.0125C7.92969 11.0125 8.08984 11.073 8.19922 11.1941C8.30859 11.3152 8.36328 11.4636 8.36328 11.6394C8.36328 11.8074 8.30859 11.9519 8.19922 12.073C8.08984 12.1941 7.92969 12.2546 7.71875 12.2546C7.51172 12.2546 7.35352 12.1941 7.24414 12.073C7.13477 11.9519 7.08008 11.8074 7.08008 11.6394Z" fill="white" />
        </svg>
    )
}

export default Question2;
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateIsLoggedInAction, updateUserDataAction } from 'store/AccountReducer';
import { pushError } from 'utils/form';

export const useFetching = (callback) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const fetching = async (...args) => {
        try {
            setIsLoading(true);
            await callback(...args)
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }

    return [fetching, isLoading, error]
}


const TestRedirect = () => {
    console.log('test redirect');
}
export const useLogin = () => {
    const dispatch = useDispatch();

    const [fetchLogin, isLoginLoading] = useFetching(async (loginMethod, data, setShowErrors, redirectPage = TestRedirect) => {
        const response = await loginMethod(data);

        let errors = [];

        if (response.status === 200) {
            dispatch(updateIsLoggedInAction(true));
            dispatch(updateUserDataAction(response.data.user));
            redirectPage()
        } else {
            dispatch(updateIsLoggedInAction(false));
            dispatch(updateUserDataAction({}));

            errors = pushError(response);
        }

        setShowErrors(errors);
    });

    return [fetchLogin, isLoginLoading];
}



export const useUpdatePersonalInfo = () => {
    const dispatch = useDispatch();

    const [fetchLogin, isLoginLoading] = useFetching(async (loginMethod, data, setShowErrors) => {
        const response = await loginMethod(data);

        let errors = [];

        if (response.status === 200) {
            if (response.data?.user) {
                dispatch(updateUserDataAction(response.data.user));
            }
        } else {
            errors = pushError(response);
        }

        setShowErrors(errors);
    });

    return [fetchLogin, isLoginLoading];
}
import React from 'react';
import "./DataTable.scss";
import { getTimezone } from 'utils/timezone';
import moment from 'moment-timezone';

const DepositDataTable = ({ dataTable }) => {
    return (
        <div className='table_data'>
            {
                dataTable.map(item =>
                    <div className="item" key={item.id}>
                        <div className="state">
                            <h5>Стан</h5>
                            <span>{item.status}</span>
                        </div>
                        <div className="date">
                            <h5>Дата</h5>
                            <span>
                                {moment.utc(item.timestamp_utc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('D MMMM YYYY')}
                                &nbsp;
                                {moment.utc(item.timestamp_utc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('H:mm')}
                            </span>
                        </div>
                        <div className="sum">
                            <h5>Сума</h5>
                            <span>{item.amount} UAH</span>
                        </div>
                        <div className="id">
                            <h5>ID транзакції</h5>
                            <span>{item.id}</span>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default DepositDataTable;
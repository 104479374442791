const defaultState = {
    slides: []
}

const UPDATE_MAIN_SLIDER_SLIDES = 'UPDATE_MAIN_SLIDER_SLIDES';

export const MainSliderReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_MAIN_SLIDER_SLIDES:
            return { ...state, slides: action.payload }
        default:
            return state;
    }
}

export const updateMainSliderSlidesAction = (payload) => ({ type: UPDATE_MAIN_SLIDER_SLIDES, payload });
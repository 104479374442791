import React from 'react';
import "./ThemeMode.scss";
import DarkMode from 'svg/theme/DarkMode';
import LightMode from 'svg/theme/LightMode';
import { useDispatch, useSelector } from 'react-redux';
import { switchThemeAction } from 'store/ThemeReducer';
import DarkModeMobile from 'svg/theme/DarkModeMobile';

const ThemeMode = ({ children, className, version_2 = false, ...props }) => {
    const dispatch = useDispatch();
    const ligthMode = useSelector(state => state.ThemeReducer.ligthMode);

    const switchThemeMode = () => {
        dispatch(switchThemeAction(ligthMode));
        document.querySelector("body").classList.toggle("light_mode");
        const switchBlocks = document.querySelectorAll(".switch_block");
        Array.from(switchBlocks).forEach(element => {
            element.classList.toggle('light_mode');
        });

        if (document.querySelector('body').classList.contains('light_mode')) {
            localStorage.setItem('lightMode', true);
        } else localStorage.setItem('lightMode', false);
    }

    if (version_2) {
        return (
            <div
                className={"theme_mode_2 pointer " + className}
                onClick={switchThemeMode}
            >
                <DarkModeMobile />
            </div>
        )
    }

    return (
        <div
            className={"theme_mode flex pointer " + className}
            onClick={switchThemeMode}
        >
            <div className="switch_block flex space_between align_center">
                <DarkMode />
                <LightMode />
            </div>
            <span className='theme_title'>
                {children}
            </span>
        </div>
    )
}

export default ThemeMode;
import React, { useEffect, useRef } from 'react';
import "./MobileSidebar.scss";
import MySearch from 'components/UI/MySearch/MySearch';
import ArrowDown from 'svg/ArrowDown';
import Category from 'components/NavMenu/Category/Category';
import Question from 'svg/Question';
import Support from 'svg/Support';
import ThemeMode from 'components/UI/ThemeMode/ThemeMode';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getRoute } from 'utils/routes';

const MobileSidebar = () => {
    const categories = useSelector(state => state.CategoryReducer.categories);
    const mobileSidebar = useRef();

    useEffect(() => {
        const submenuBlocks = mobileSidebar.current.querySelectorAll(".has_submenu");
        Array.from(submenuBlocks).forEach(element => {
            element.addEventListener("click", (e) => {
                e.target.closest(".item_wrapper").classList.toggle("active");

                const categoryList = e.target.closest(".item_wrapper.category_list");
                if (categoryList) {
                    if (categoryList.classList.contains("active")) {
                        const submenu = categoryList.querySelector(".item_submenu");
                        submenu.style.maxHeight = `${submenu.children.length * 65}px`
                    } else {
                        const submenu = categoryList.querySelector(".item_submenu");
                        submenu.style.maxHeight = '0px'
                    }
                }
            })
        })
    }, [])

    return (
        <aside className='top-sidebar mobile_sidebar' ref={mobileSidebar}>
            <MySearch />
            <div className="menu">
                <div className="item_wrapper flex column category_list">
                    <div
                        className="item flex space_between align_center has_submenu"
                    >
                        <span className='has_submenu_title'>Категорії</span>
                        <ArrowDown />
                    </div>
                    <nav className="item_submenu">
                        {
                            categories.map(category =>
                                <Category key={category.id} category={category} />
                            )
                        }
                    </nav>
                </div>
                <div className="item_wrapper flex column">
                    <div
                        className="item flex space_between align_center has_submenu"
                    >
                        <Link to={getRoute('profile')} className='has_submenu_title'>Мій кабінет</Link>
                        <ArrowDown />
                    </div>
                    <div className="item_submenu flex column">
                        <Link to={getRoute('personal-info')} className='title'>
                            Персональні дані
                        </Link>
                        <Link to={getRoute('documents')} className='title'>
                            Документи
                        </Link>
                        <Link to={getRoute('help-info')} className='title'>
                            Допомога та інформація
                        </Link>
                        <Link to={getRoute('my-bets')} className='title'>
                            Мої ставки
                        </Link>
                        <Link to={getRoute('balance')} className='title'>
                            Баланс
                        </Link>
                    </div>
                </div>
                <div className="item_wrapper flex column">
                    <div
                        className="item flex space_between align_center has_submenu"
                    >
                        <Link to={getRoute('info-page', ['about-us'])} className='has_submenu_title'>Про компанію</Link>
                        <ArrowDown />
                    </div>
                    <div className="item_submenu flex column">
                        <Link to={getRoute('info-page', ["terms-and-conditions"])} className='title'>
                            Правила та умови
                        </Link>
                        <Link to={getRoute('info-page', ["privacy"])} className='title'>
                            Політика конфіденційності
                        </Link>
                        <Link to={getRoute('info-page', ["offers"])} className='title'>
                            Публічна оферта
                        </Link>
                    </div>
                </div>
                <div className="item_wrapper">
                    <div className="item flex space_between align_center">
                        <span className='has_submenu_title'>Питання-відповідь</span>
                        <Question />
                    </div>
                </div>
                {/* <div className="item_wrapper support">
                    <div className="item flex space_between align_center">
                        <span>Служба підтримки</span>
                        <Support />
                    </div>
                </div> */}
                <div className="item_wrapper">
                    <div className="item flex space_between align_center">
                        <ThemeMode
                            className="row_reverse full_width space_between"
                        >
                            Змінити тему
                        </ThemeMode>
                    </div>
                </div>
                {/* <div className="item_wrapper flex column">
                    <div
                        className="item flex space_between align_center has_submenu"
                    >
                        <span>Мова</span>
                        <ArrowDown />
                    </div>
                    <div className="item_submenu flex space_between lang_submenu">
                        <span className='title'>UA</span>
                        <span className='title'>EN</span>
                        <span className='title'>RU</span>
                    </div>
                </div> */}
            </div>
        </aside>
    )
}

export default MobileSidebar;
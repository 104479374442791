import React, { useEffect, useState } from 'react';
import "./AvatarUpload.scss";
import MyButton from 'components/UI/MyButton/MyButton';
import ImageUploading from 'react-images-uploading';

const AvatarUpload = ({ extensions, setFormData }) => {
    const [image, setImage] = useState([]);

    const onChange = (imageList, addUpdateIndex) => {
        // console.log("imageList: ", imageList);
        setImage(imageList);
    };

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            avatar: image[0]?.file
        }));
    }, [image])

    return (
        <ImageUploading
            value={image}
            onChange={onChange}
            dataURLKey="data_url"
            maxFileSize={1000000}
            acceptType={extensions}
        >
            {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                errors,
            }) => (
                <div className='flex column avatar_upload'>
                    <div className='flex image_upload_wrapper'>
                        <span className='image_title'>
                            {
                                image.length
                                    ?
                                    image[0].file.name
                                    :
                                    <>Файл не обрано</>
                            }
                        </span>
                        <MyButton
                            onClick={onImageUpload}
                            className="my_button active medium full_height"
                        >
                            Вибір зоображення профіля
                        </MyButton>
                    </div>
                    {errors &&
                        <div className='avatar_errors_block'>
                            {errors.acceptType && <span>Выбранный вами тип файла недопустим. Выберете файлы с одним из следующих расширений: {extensions.map(item => item + " ")}</span>}
                            {errors.maxFileSize && <span>Размер выбранного файла превышает максимальный допустимый размер (1Mb)</span>}
                        </div>
                    }
                </div>
            )}
        </ImageUploading>
    )
}

export default AvatarUpload;
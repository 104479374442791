import React from 'react';

const AgreeEdit = ({...props}) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
            <rect width="15.3948" height="2" rx="1" transform="matrix(0.699997 -0.714146 0.699997 0.714146 5.39062 11.4707)" fill="#6E7074" />
            <path d="M0.753128 5.31595C1.13973 4.92154 1.76652 4.92154 2.15312 5.31595L7.48487 10.7555C7.87147 11.1499 7.87147 11.7894 7.48487 12.1838V12.1838C7.09828 12.5782 6.47148 12.5782 6.08488 12.1838L0.753128 6.74424C0.366531 6.34983 0.36653 5.71036 0.753128 5.31595V5.31595Z" fill="#6E7074" />
        </svg>
    )
}

export default AgreeEdit;
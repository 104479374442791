import React from 'react';

const NoLiveEventsIconLight = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="67" height="73" viewBox="0 0 67 73" fill="none">
            <path d="M32.5443 63.1143C32.5442 63.1143 32.5444 63.1143 32.5443 63.1143C30.6515 63.1143 28.8719 63.8514 27.5336 65.1898C27.0565 65.6668 27.0565 66.4404 27.5336 66.9176C27.7722 67.1561 28.0849 67.2755 28.3976 67.2755C28.7103 67.2755 29.0229 67.1561 29.2614 66.9176C30.1383 66.0407 31.3042 65.5578 32.5443 65.5578C33.7843 65.5578 34.9502 66.0408 35.8271 66.9176C36.3042 67.3946 37.0778 67.3946 37.5549 66.9176C38.032 66.4405 38.032 65.6669 37.5549 65.1898C36.2165 63.8514 34.4371 63.1143 32.5443 63.1143Z" fill="#28292E" />
            <path d="M28.4838 60.4521C28.7876 60.1491 28.9611 59.7288 28.9611 59.3004C28.9611 58.872 28.7876 58.4517 28.4838 58.1486C28.1808 57.8457 27.7606 57.6714 27.3321 57.6714C26.9029 57.6714 26.4834 57.8457 26.1804 58.1486C25.8774 58.4517 25.7031 58.872 25.7031 59.3004C25.7031 59.7288 25.8774 60.1491 26.1804 60.4521C26.4834 60.7551 26.9037 60.9294 27.3321 60.9294C27.7606 60.9294 28.1808 60.7551 28.4838 60.4521Z" fill="#28292E" />
            <path d="M37.7579 57.6714C37.3287 57.6714 36.9092 57.8457 36.6062 58.1486C36.3024 58.4517 36.1289 58.872 36.1289 59.3004C36.1289 59.7288 36.3024 60.1491 36.6062 60.4521C36.9092 60.7551 37.3295 60.9294 37.7579 60.9294C38.1863 60.9294 38.6066 60.755 38.9096 60.4521C39.2134 60.1491 39.3869 59.7288 39.3869 59.3004C39.3869 58.872 39.2134 58.4517 38.9096 58.1486C38.6066 57.8457 38.1863 57.6714 37.7579 57.6714Z" fill="#28292E" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.2753 0.843018C26.2263 1.25602 26.0253 3.61802 25.8283 6.09302C25.3423 12.189 23.2013 17.879 18.8093 24.745L15.1333 30.492L12.9983 26.094C10.2503 20.432 8.77828 20.058 7.89828 24.799C7.51128 26.886 5.77028 31.968 4.02928 36.093C1.47828 42.136 0.863281 44.969 0.863281 50.678C0.863281 56.92 1.22028 58.281 3.86328 62.104C7.21528 66.953 14.5313 72.093 18.0823 72.093C20.3533 72.093 20.3693 72.031 18.7193 69.514C15.7113 64.923 16.5273 59.117 21.3133 51.061C25.7703 43.558 27.2823 43.192 27.3773 49.593L27.4223 52.593L30.3213 49.407C31.9163 47.655 34.0103 43.717 34.9743 40.657C35.9393 37.597 37.0183 35.093 37.3713 35.093C38.5983 35.093 42.1903 39.616 44.4703 44.033C47.3073 49.526 48.0403 58.189 46.3453 66.176C45.6493 69.457 45.3683 72.431 45.7213 72.784C46.8663 73.929 56.9513 66.195 60.1833 61.694C69.9643 48.075 68.8533 31.883 57.2313 18.681C52.5873 13.404 50.3693 12.812 50.3493 16.843C50.3323 20.247 49.3443 24.102 48.4963 24.066C48.1483 24.051 46.5253 21.014 44.8903 17.316C41.3673 9.35002 32.7293 0.0930176 28.8183 0.0930176C27.4683 0.0930176 26.3233 0.430018 26.2753 0.843018Z" fill="#28292E" />
        </svg>
    )
}

export default NoLiveEventsIconLight;
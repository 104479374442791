import React, { useEffect, useRef, useState } from 'react';
import './Coupon.scss';
import SwitchBlock from 'components/UI/SwitchBlock/SwitchBlock';
import MyButton from 'components/UI/MyButton/MyButton';
import Bin from "svg/bet/Bin";
import BetCard from "components/AsideMenu/Coupon/BetCard/BetCard"
import HistoryItem from './HistoryItem/HistoryItem';
import Pagination from 'components/UI/Pagination/Pagination';
import CouponIcon from 'svg/CouponIcon';
import Clock from 'svg/bet/Clock';
import SidebarBack from 'svg/SidebarBack';
import SidebarCloseAll from 'svg/SidebarCloseAll';
import { closeAllSidebars, sidebarBack } from 'utils/sidebar';
import { useDispatch, useSelector } from 'react-redux';
import PostService from 'api/PostService';
import { addMyBetsPageMatchId, updateCouponOddsAction } from 'store/BetReducer';
import ExpressItem from './HistoryItem/ExpressItem';
import { getRoute } from 'utils/routes';
import { getTotalExpressCoef } from 'utils/odds';

const Coupon = React.forwardRef(({ betCards, setBetCards, ...props }, ref) => {
    const dispatch = useDispatch();

    const [historyBlock, setHistoryBlock] = useState(false);
    const historyHandler = (bool) => {
        setHistoryBlock(bool);
        if (bool) {
            ref.current.classList.remove("open");
        } else {
            ref.current.classList.add("open");
            expressSidebarRef.current.classList.remove('active');
        }
    }

    const paginationMaxItem = 10;
    const [currentPagePagination, setCurrentPagePagintaion] = useState(1);
    const historyRef = useRef()

    const [historyItems, setHistoryItems] = useState([]);
    const historyBets = useSelector(state => state.BetReducer.historyBets);
    useEffect(() => {
        const startIndex = (currentPagePagination - 1) * paginationMaxItem;
        const endIndex = Math.min(currentPagePagination * paginationMaxItem, historyBets.length);
        setHistoryItems(historyBets.slice(startIndex, endIndex))

        if (historyRef.current) {
            historyRef.current.scrollTop = 0
        }
    }, [historyBets, currentPagePagination])

    const expressSidebarRef = useRef();
    const [expressItem, setExpressItem] = useState();
    const openExpress = (item) => {
        setExpressItem(item);
        expressSidebarRef.current.classList.add('active')
    }


    const removeBetCard = (betCard) => {
        const data = { odd_id: betCard.id };
        PostService.deleteBetOdd(data)
            .then((response) => {
                if (response?.status === 200) {
                    dispatch(updateCouponOddsAction(response.data.coupon_odds))
                }
            })
        setBetCards(betCards.filter(item => item.id !== betCard.id));
    }

    const removeAllBetCards = () => {
        PostService.deleteAllBetOdd()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updateCouponOddsAction(response.data.coupon_odds))
                }
            })
        setBetCards([]);
    }

    return (
        <div className="coupon flex column">
            <div className='flex justify_center space_between-if_lg_size align_center'>
                <SidebarBack className="sidebar_button hide block-if_lg_size" onClick={() => sidebarBack('.coupon_sidebar')} />
                <span className="block_title">
                    Купон
                </span>
                <SidebarCloseAll className="sidebar_button hide block-if_lg_size" onClick={closeAllSidebars} />
            </div>
            <SwitchBlock mt="16px" mb="18px" bckColor2='true'>
                <MyButton
                    data-off={(betCards.length <= 1 ? 'false' : 'true')}
                    onClick={() => historyHandler(false)}
                    className={
                        "small my_button" +
                        (betCards.length <= 1 && !historyBlock ? ' active' : "")
                    }
                >
                    Одинар
                </MyButton>
                <MyButton
                    data-off={(betCards.length >= 2 ? 'false' : 'true')}
                    onClick={() => historyHandler(false)}
                    className={
                        "small my_button" +
                        (betCards.length >= 2 && !historyBlock ? ' active' : "")
                    }
                >
                    Експрес
                </MyButton>
                <MyButton
                    onClick={() => historyHandler(true)}
                    className={
                        "small my_button" +
                        (historyBlock ? " active" : '')
                    }
                >
                    Історія
                </MyButton>
            </SwitchBlock>
            <div className='express_sidebar' ref={expressSidebarRef}>
                <div className="flex space_between align_center head">
                    <SidebarBack
                        className="express_button"
                        onClick={() => { expressSidebarRef.current.classList.remove('active') }}
                    />
                    <span className="total_coef">
                        Експрес {expressItem?.total_odd}
                    </span>
                </div>
                {
                    expressItem?.placed_bet_odds.map((item, index) =>
                        <ExpressItem
                            key={item.id}
                            item={item}
                            index={index + ((currentPagePagination - 1) * 10)}
                            placedTimestampUtc={expressItem.placed_timestamp_utc}
                        />
                    )
                }
                <div className="flex space_between express_sum">
                    <span className="sum_title">Сума виплати</span>
                    <span
                        className={
                            "payment" +
                            (
                                expressItem?.status === 'unknown'
                                    ?
                                    " unknown"
                                    :
                                    expressItem?.status === 'loss'
                                        ?
                                        " loss"
                                        :
                                        ""
                            )
                        }
                    >
                        {
                            expressItem?.status === 'unknown'
                                ?
                                'Невизначена'
                                :
                                expressItem?.amount + 'грн'
                        }
                    </span>
                </div>
                <MyButton
                    onClick={() => dispatch(addMyBetsPageMatchId(expressItem.id))}
                    href={getRoute('my-bets')}
                    className="medium my_button active"
                >
                    Детальніше
                </MyButton>
            </div>
            {
                historyBlock
                    ?
                    historyItems.length
                        ?
                        <div className="history_wrapper" ref={historyRef}>
                            {
                                historyItems.map((item, index) =>
                                    <HistoryItem
                                        key={item.id}
                                        item={item}
                                        index={index + ((currentPagePagination - 1) * paginationMaxItem)}
                                        openExpress={openExpress}
                                    />
                                )
                            }
                        </div>
                        :
                        <div className='coupon_no-item'>
                            <Clock />
                            <span className="title">Історія пуста</span>
                            <span className="subtitle">
                                Клікніть по коефіцієнт, щоб зробити ставку
                            </span>
                        </div>
                    :
                    betCards.length
                        ?
                        <div className="bet_cards">
                            {
                                betCards.length > 1 &&
                                <div className='total_express_coef'>
                                    <span className="title">
                                        Загальний коефіцієнт
                                    </span>
                                    <span className="value">
                                        {getTotalExpressCoef(betCards)}
                                    </span>
                                </div>
                            }
                            {
                                betCards.map((item, index) =>
                                    <BetCard
                                        remove={removeBetCard}
                                        item={item}
                                        key={item.id}
                                    // live={index % 2 == 0 ? true : false}
                                    />
                                )
                            }
                        </div>
                        :
                        <div className='coupon_no-item'>
                            <CouponIcon />
                            <span className="title">Твій купон порожній</span>
                            <span className="subtitle">
                                Клікніть по коефіцієнт, щоб зробити ставку
                            </span>
                        </div>
            }
            {
                historyBlock
                    ?
                    historyBets.length > paginationMaxItem
                        ?
                        <Pagination
                            currentPagePagination={currentPagePagination}
                            setCurrentPagePagintaion={setCurrentPagePagintaion}
                            paginationMaxItem={paginationMaxItem}
                            items={historyBets}
                        />
                        :
                        <></>
                    :
                    <div onClick={removeAllBetCards} className="remove_all flex align_center justify_center pointer">
                        <Bin />
                        <span className='title'>
                            Видалити все
                        </span>
                    </div>
            }
        </div>
    )
})

export default Coupon;
import AccountService from 'api/AccountService';
import ErrorBlock from 'components/Form/ErrorBlock/ErrorBlock';
import BalanceAlert from 'components/PageBalance/BalanceAlert/BalanceAlert';
import Loader from 'components/UI/Loader/Loader';
import MyButton from 'components/UI/MyButton/MyButton';
import { useUpdatePersonalInfo } from 'hooks/useFetching';
import React, { useState } from 'react';
import ViewPassword from 'svg/login/ViewPassword';
import { showHidePassword } from 'utils/form';

const ChangePassword = () => {
    const [showErrors, setShowErrors] = useState([]);
    const [formData, setFormData] = useState({
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
    });

    const [successBlock, setSuccessBlock] = useState(null)

    const handleChange = (event) => {
        let { name, value } = event.target;

        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        fetchChangePassword(
            AccountService.updateUserPassword,
            formData,
            setShowErrors,
        );

        setSuccessBlock(true)
    }

    const [fetchChangePassword, isChangePasswordLoading] = useUpdatePersonalInfo();

    return (
        <div className="form_container">
            <h1 className="title">
                Змінити пароль
            </h1>
            {
                isChangePasswordLoading &&
                <div className='flex justify_center' style={{marginTop: '12px'}}>
                    <Loader />
                </div>
            }

            {
                (successBlock && !showErrors.length && !isChangePasswordLoading) &&
                <BalanceAlert
                    title="Пароль успішно змінено"
                    subtitle="Вітаємо! Ваш пароль був успішно змінений і оновлений в нашій системі. Тепер ви можете використовувати новий пароль для доступу до вашого облікового запису."
                    setDepositSuccess={setSuccessBlock}
                />
            }
            <ErrorBlock errors={showErrors} />

            <form onSubmit={handleSubmit} className='flex password_tab'>
                <div className='flex column col_labels'>
                    <label htmlFor="current_password" className='input_wrapper'>
                        <span className='input_text'>Поточний пароль</span>
                        <div className='password_wrapper'>
                            <input
                                type="password"
                                id='current_password'
                                name='current_password'
                                placeholder='Введіть пароль'
                                value={formData.current_password}
                                onChange={handleChange}
                            />
                            <ViewPassword onClick={e => showHidePassword(e)} />
                        </div>
                    </label>
                    <label htmlFor="new_password" className='input_wrapper'>
                        <span className='input_text'>Новий пароль</span>
                        <div className='password_wrapper'>
                            <input
                                type="password"
                                id='new_password'
                                name='new_password'
                                placeholder='Введіть пароль'
                                value={formData.new_password}
                                onChange={handleChange}
                            />
                            <ViewPassword onClick={e => showHidePassword(e)} />
                        </div>
                    </label>
                    <label htmlFor="new_password_confirmation" className='input_wrapper'>
                        <span className='input_text'>Повторіть пароль</span>
                        <div className='password_wrapper'>
                            <input
                                type="password"
                                id='new_password_confirmation'
                                name='new_password_confirmation'
                                placeholder='Введіть пароль'
                                value={formData.new_password_confirmation}
                                onChange={handleChange}
                            />
                            <ViewPassword onClick={e => showHidePassword(e)} />
                        </div>
                    </label>
                </div>
                <MyButton type='submit' className="medium active my_button submit_btn">
                    Змінити пароль
                </MyButton>
            </form>
        </div>
    )
}

export default ChangePassword;
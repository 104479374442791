import React, { useEffect, useState } from 'react';

import NavMenu from "components/NavMenu/NavMenu";
import Header from "components/Header/Header";
import MobileHeader from "components/MobileHeader/MobileHeader";
import TabletMenu from "components/TabletMenu/TabletMenu";
import Consultant from "components/UI/Consultant/Consultant";
import MobileMenu from "components/MobileMenu/MobileMenu";
import MobileSidebar from "components/Sidebars/MobileSidebar/MobileSidebar";
import CouponSidebar from "components/Sidebars/CouponSidebar/CouponSidebar";
import PagesService from 'api/PagesService';
import { useFetching } from 'hooks/useFetching';
import { useDispatch } from 'react-redux';
import { updateIsLoggedInAction, updateUserDataAction } from 'store/AccountReducer';
import Loader from 'components/UI/Loader/Loader';
import FilterSidebar from "components/Sidebars/FilterSidebar/FilterSidebar";
import { updateBetValuesAction, updateAutoAcceptOddsAction, updateCouponOddsAction, updateMaxBetWin, updateMinBetWin, updateHistoryBetsAction } from 'store/BetReducer';
import { updateMainSliderSlidesAction } from 'store/MainSliderReducer';
import { Navigate, useLocation } from 'react-router-dom';
import { switchThemeAction } from 'store/ThemeReducer';
import { closeAllSidebars } from 'utils/sidebar';
import { getRoute } from 'utils/routes';
import { updateHelpInfoTitleAction, updateHelpInfoSubtitleAction, updateHelpInfoInfoPagesAction } from 'store/HelpInfoReducer';
import { updateHelpInfoAction } from 'store/InfoPageReducer';
import { updatePagesLinksDataAction, updateInfoPagesLinksDataAction } from 'store/LinksDataReducer';
import { updateCategoriesAction, categoriesIconsMapping } from 'store/CategoryReducer';
import { updateMainMatchesAction } from 'store/MainMatchesReducer';
import { updateLoadingAction } from 'store/LoadingReducer';
import { updateFutureMatchesAction } from 'store/FutureMatchesReducer';
import { updateOddsTypesAction } from 'store/OddsTypesReducer';
import { updateCurrentMatchAction } from 'store/CurrentMatchReducer';
import AuthModal from 'components/Modals/AuthModal';
import { setTaxesAction, setWithdrawalCommisionAction, updateDepositHistoryAction, updateMaxDepositAction, updateMinDepositAction, updateWithdrawalHistoryAction } from 'store/BalanceReducer';
import DocumentsUploadModal from 'components/Modals/DocumentsUploadModal';
import AlertDocumentsVerified from 'components/AlertDocumentsVerified/AlertDocumentsVerified';
import { updateDocumentOperation } from 'store/DocumentsReducer';

const MainLayout = ({ children, pageName = null, pageSlug = null }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [notFoundError, setNotFoundError] = useState(false)
    const [loadingPageState, setLoadingPageState] = useState(true);

    const [fetchPageData, isPageDataLoading, pageDataError] = useFetching(async () => {

        if (localStorage.getItem('lightMode') === 'true') {
            dispatch(switchThemeAction(false));
        }

        if (pageName == null) {
            setLoadingPageState(false);
            return;
        }

        let response;

        if (pageSlug) {
            response = await PagesService.getPageDataBySlug(pageName, pageSlug);
        } else {
            response = await PagesService.getPageData(pageName);
        }

        if (response.status !== 200) {
            if (response.status === 401) {
                dispatch(updateIsLoggedInAction(false));
                dispatch(updateUserDataAction({}));
            } else if (response.status === 404) {
                setNotFoundError(true);
            }

            throw new Error('Server error!');
        }

        if (response.data.user) {
            dispatch(updateIsLoggedInAction(true));
            dispatch(updateUserDataAction(response.data.user));

            dispatch(updateAutoAcceptOddsAction(response.data.user.auto_accept_odds));
        } else {
            dispatch(updateIsLoggedInAction(false));
            dispatch(updateUserDataAction({}));
        }

        if (response.data.history_bets) {
            dispatch(updateHistoryBetsAction(response.data.history_bets))
        }

        if (response.data.max_deposit) {
            dispatch(updateMaxDepositAction(response.data.max_deposit));
        }

        if (response.data.min_deposit) {
            dispatch(updateMinDepositAction(response.data.min_deposit));
        }

        if (response.data.balance_transactions) {
            dispatch(updateDepositHistoryAction(response.data.balance_transactions))
            dispatch(updateWithdrawalHistoryAction(response.data.balance_transactions))
        }

        if (response.data.withdrawal_commission) {
            dispatch(setWithdrawalCommisionAction(response.data.withdrawal_commission))
        }

        if (response.data.taxes) {
            dispatch(setTaxesAction(response.data.taxes))
        }

        if (response.data.max_bet_win) {
            dispatch(updateMaxBetWin(response.data.max_bet_win));
        }

        if (response.data.min_bet_amount) {
            dispatch(updateMinBetWin(response.data.min_bet_amount));
        }

        if (response.data.amount_buttons) {
            dispatch(updateBetValuesAction(response.data.amount_buttons.coupon));
        }

        if (response.data.main_slider_slides) {
            dispatch(updateMainSliderSlidesAction(response.data.main_slider_slides));
        }

        if (response.data.help_info_page) {
            dispatch(updateHelpInfoTitleAction(response.data.help_info_page.translation.title));
        }

        if (response.data.help_info_header) {
            dispatch(updateHelpInfoSubtitleAction(response.data.help_info_header.translation.body));
        }

        if (response.data.info_pages) {
            dispatch(updateHelpInfoInfoPagesAction(response.data.info_pages));
        }

        if (response.data.info_page) {
            dispatch(updateHelpInfoAction(response.data.info_page))
        }

        if (response.data.pages_links_data) {
            dispatch(updatePagesLinksDataAction(response.data.pages_links_data));
        }

        if (response.data.info_pages_links_data) {
            dispatch(updateInfoPagesLinksDataAction(response.data.info_pages_links_data));
        }

        if (response.data.active_document_opeartion) {
            dispatch(updateDocumentOperation(response.data.active_document_opeartion));
        }

        if (response.data.sports) {
            response.data.sports.map((sport) => {
                sport.icon = categoriesIconsMapping[sport.slug];

                sport.leagues_groups.map((leagues_group) => {
                    leagues_group.isActive = true;
                    return leagues_group;
                });

                return sport;
            });

            dispatch(updateCategoriesAction(response.data.sports));
        }

        if (response.data.main_sport_matches) {
            dispatch(updateMainMatchesAction(response.data.main_sport_matches));
        }

        if (response.data.future_matches) {
            dispatch(updateFutureMatchesAction(response.data.future_matches));
        }

        if (response.data.odds_types) {
            dispatch(updateOddsTypesAction(response.data.odds_types));
        }

        if (response.data.current_match) {
            dispatch(updateCurrentMatchAction(response.data.current_match));
        }

        if (response.data.coupon_odds) {
            dispatch(updateCouponOddsAction(response.data.coupon_odds))
        }

        setLoadingPageState(false);
    });


    useEffect(() => {
        dispatch(updateLoadingAction(true));
        fetchPageData();
    }, [location])

    useEffect(() => {
        if (!loadingPageState) {
            dispatch(updateLoadingAction(false));
            closeAllSidebars(true);
        }
    }, [loadingPageState])


    if (notFoundError) {
        return <Navigate to={getRoute("error-404")} />
    }

    if (pageDataError) {
        console.log(pageDataError);
        return (
            <div style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <h1 style={{
                    fontSize: '3rem',
                    color: 'white',
                    fontFamily: 'Nunito Sans'
                }}>
                    Произошла ошибка!
                </h1>
            </div>
        )
    }

    if (isPageDataLoading || loadingPageState) {
        return (
            <div className="flex_centered_item full_width full_height fixed">
                <Loader />
            </div>
        )
    }

    return (
        <>
            {/* <Consultant className="flex hide-if_lg_size" /> */}

            <div className="blackout"></div>
            <MobileSidebar />
            <CouponSidebar />
            <FilterSidebar />

            <AuthModal />
            <DocumentsUploadModal />

            <div className="App">
                <MobileHeader />
                <NavMenu />
                <Header />

                {/* <AlertDocumentsVerified /> */}
                {children}

                <TabletMenu />
                <MobileMenu />
            </div>
        </>
    )
}

export default MainLayout;
export const handleFooterScroll = () => {
    const footer = document.getElementById('footer');
    // const footerHeight = footer.offsetHeight;
    const windowHeight = window.innerHeight;

    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const activationPoint = scrollTop + windowHeight - 180;

    if (activationPoint > footer?.offsetTop) {
        activateFunction();
    }
};

const activateFunction = () => {
    const footer = document.getElementById('footer');
    footer.classList.add("active");

    window.removeEventListener('scroll', handleFooterScroll);
};
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import { store } from "store";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <App />
        </GoogleOAuthProvider>
    </Provider>
);
import React from 'react';
import "./SortHead.scss";
import MySelect from 'components/UI/MySelect/MySelect';

const PageSortHead = ({ headTitle, sortingData, setFilters }) => {
    return (
        <div className="sort_wrapper flex">
            <h2>{headTitle}</h2>
            {
                sortingData.map(select =>
                    <MySelect
                        key={select.id}
                        title={select.title}
                        options={select.options}
                        filterType={select.filterType}
                        setFilters={setFilters}
                    />
                )
            }
        </div>
    )
}

export default PageSortHead;
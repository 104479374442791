import React from 'react';
import './TabletMenu.scss';
import MyButton from "components/UI/MyButton/MyButton";
import SwitchBlock from "components/UI/SwitchBlock/SwitchBlock";
import ShapeFire from 'svg/ShapeFire';
import Question from 'svg/Question';
import Coupon from 'svg/CouponIcon';
import Consultant from 'components/UI/Consultant/Consultant';
import { openSidebar } from 'utils/sidebar';
import { getRoute } from 'utils/routes';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const TabletMenu = () => {
    // const toggleModal = (name) => {
    //     document.querySelector("body").classList.toggle("no_scroll");
    //     const modal = document.querySelector(`.modal_wrapper.${name}-modal`);
    //     modal.classList.toggle("hide");
    // }
    let location = useLocation();
    const [liveBets, setLiveBets] = useState(false);

    useEffect(() => {
        if (location.pathname.includes('live-bets')) {
            setLiveBets(true);
        } else setLiveBets(false);
    }, [location]);

    return (
        <div className="tablet_menu_wrapper menu_container hide block-if_lg_size hide-if_sm_size">
            <div className="tablet_menu menu flex">
                <SwitchBlock mr="8px" blockClass="half_width grow">
                    <MyButton
                        href={getRoute('home')}
                        className={"my_button full_height" + (!liveBets ? " active" : "")}
                    >
                        Ставки Спорт
                    </MyButton>
                    <MyButton
                        href={getRoute('live-bets')}
                        className={"my_button full_height" + (liveBets ? " active" : "")}
                        svg={<ShapeFire />}
                    >
                        Лайв Ставки
                    </MyButton>
                </SwitchBlock>
                <MyButton
                    href={getRoute('help-info')}
                    title="Допомога та інформація"
                    svg={<Question />}
                    className="my_button btn_open_window"
                >
                    Допомога та інформація
                </MyButton>
                <MyButton
                    svg={<Coupon />}
                    className="my_button btn_open_window coupon_mobile_btn"
                    onClick={e => openSidebar(e, ".coupon_sidebar")}
                >
                    Купон
                </MyButton>
                {/* <Consultant className="flex" /> */}
            </div>
        </div>
    )
}

export default TabletMenu;
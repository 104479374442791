import React, { useState } from 'react';
import "./MobileHeader.scss";
import Logo from "components/UI/Logo/Logo";
import ThemeMode from "components/UI/ThemeMode/ThemeMode";
import MySearch from "components/UI/MySearch/MySearch";
import LoginLang from "components/UI/LoginLang/LoginLang";
import { useDispatch, useSelector } from 'react-redux';
import { updateParamAction } from 'store/CategoryReducer';
import { Link } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import { openTopSidebar } from 'utils/sidebar';
import Profile from 'svg/ProfileIcon';
import MyButton from 'components/UI/MyButton/MyButton';
import SwitchBlock from 'components/UI/SwitchBlock/SwitchBlock';
import { handleFooterScroll } from 'utils/footer';
import Lang from 'components/UI/Lang/Lang';
import ProfileBlock from 'components/UI/ProfileBlock/ProfileBlock';

const MobileHeader = () => {
    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);
    const dispatch = useDispatch();
    const categories = useSelector(state => state.CategoryReducer.categories);
    const categoryParam = useSelector(state => state.CategoryReducer.categoryParam);

    const classActive = async (e) => {
        const navCategories = e.currentTarget.parentElement.querySelectorAll('.category');
        navCategories.forEach(navcategory => {
            navcategory.children[0].classList.remove('active');
        });
        e.currentTarget.children[0].classList.add("active");
        await dispatch(updateParamAction(e.currentTarget.id));
        // handleFooterScroll();
    }

    const [authWindow, setAuthWindow] = useState(false);

    const clickFunc = (e) => {
        if (!e.target.closest('.auth_wrapper')) {
            setAuthWindow(false);
            document.removeEventListener('click', clickFunc)
        }
    }

    const authHandler = (e) => {
        setAuthWindow(!authWindow)
        document.addEventListener('click', clickFunc)
    }

    return (
        <header className="mobile_fixed_window hide flex column flex-if_lg_size">
            <div className='flex'>
                <div className="flex logo_wrapper align_center space_between justify_center-if_sm_size">
                    <Logo />
                    <ThemeMode
                        className="hide-if_sm_size"
                    >
                        Тема
                    </ThemeMode>
                </div>
                <MySearch className="hide-if_sm_size" />

                <ThemeMode
                    className="hide flex-if_sm_size"
                    version_2={true}
                >
                </ThemeMode>

                {
                    !!isAuth
                        ?
                        <>
                            {/* <Lang /> */}
                            <ProfileBlock />
                        </>
                        :
                        <LoginLang />
                }


                {
                    !isAuth &&
                    <div className={'auth_wrapper hide block-if_sm_size' + (authWindow ? " open" : '')}>
                        <MyButton
                            onClick={(e) => authHandler(e)}
                            minWidth={true}
                            className="medium my_button full_height"
                            svg={<Profile />}
                        ></MyButton>
                        <div className="auth_window">
                            <SwitchBlock blockClass="grow column" isSwitch={false}>
                                <MyButton
                                    href={getRoute('login')}
                                    className="my_button full_height"
                                >Вхід</MyButton>
                                <MyButton
                                    href={getRoute('register')}
                                    className="active my_button full_height"
                                >Реєстрація</MyButton>
                            </SwitchBlock>
                        </div>
                    </div>
                }

                <div
                    className="mobile_btn hide flex-if_sm_size"
                    onClick={e => openTopSidebar(e, ".mobile_sidebar")}
                >
                    <div className='burger'>
                        <i></i>
                    </div>
                </div>


            </div>
            <div className='category_container flex space_between'>
                <nav className="category_wrapper flex">
                    {
                        categories.map(category =>
                            <Link
                                id={category.slug}
                                onClick={e => classActive(e)}
                                to={getRoute('category', [category.slug])}
                                className='flex category_item'
                                key={category.id}
                            >
                                <div title={category.translation.title} className={
                                    "inner_box" + ((category.slug === categoryParam) ? ' active' : '')
                                }>
                                    {category.icon}
                                    <span className="title">
                                        {category.translation.title}
                                    </span>
                                </div>
                            </Link>
                        )
                    }
                </nav>
            </div>
        </header>
    )
}

export default MobileHeader;
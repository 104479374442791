const defaultState = {
    title: '',
    subtitle: '',
    info_pages: []
}

const UPDATE_HELP_INFO_TITLE = 'UPDATE_HELP_INFO_TITLE';
const UPDATE_HELP_INFO_SUBTITLE = 'UPDATE_HELP_INFO_SUBTITLE';
const UPDATE_HELP_INFO_INFO_PAGES = 'UPDATE_HELP_INFO_INFO_PAGES';

export const HelpInfoReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_HELP_INFO_TITLE:
            return { ...state, title: action.payload }
        case UPDATE_HELP_INFO_SUBTITLE:
            return { ...state, subtitle: action.payload }
        case UPDATE_HELP_INFO_INFO_PAGES:
            return { ...state, info_pages: action.payload }
        default:
            return state;
    }
}

export const updateHelpInfoTitleAction = (payload) => ({ type: UPDATE_HELP_INFO_TITLE, payload });
export const updateHelpInfoSubtitleAction = (payload) => ({ type: UPDATE_HELP_INFO_SUBTITLE, payload });
export const updateHelpInfoInfoPagesAction = (payload) => ({ type: UPDATE_HELP_INFO_INFO_PAGES, payload });
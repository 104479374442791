import React from 'react';

const Clock = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
            <path d="M13.4431 7.45105C13.4431 11.1987 10.5417 14.1986 7.00864 14.1986C3.47559 14.1986 0.574219 11.1987 0.574219 7.45105C0.574219 3.70343 3.47559 0.703491 7.00864 0.703491C10.5417 0.703491 13.4431 3.70343 13.4431 7.45105Z" stroke="#6E7074" />
            <path d="M6.75391 3.79895V8.24573H9.2357" stroke="#6E7074" strokeLinecap="round" />
        </svg>
    )
}

export default Clock;
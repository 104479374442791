import api from '../services/api';

export default class PagesService {
    static async getPageData(pageName) {

        const request = await api.get('pages/'+pageName)
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }

    static async getPageDataBySlug(pageName, pageSlug) {

        const request = await api.get('pages/'+pageName+'/'+pageSlug)
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }
}
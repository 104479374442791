import React, { useCallback, useEffect, useRef, useState } from 'react';
import './AsideMenu.scss';
import Coupon from './Coupon/Coupon';
import Bet from './Bet/Bet';
import LoginLang from 'components/UI/LoginLang/LoginLang';
import { useDispatch, useSelector } from 'react-redux';
import PostService from 'api/PostService';
import { useUpdateOdds } from 'hooks/useOdds';

const AsideMenu = () => {
    const betBlock = useRef();
    const dispatch = useDispatch();

    const couponOdds = useSelector(state => state.BetReducer.couponOdds);
    const [betCards, setBetCards] = useState(couponOdds)

    useEffect(() => {
        setBetCards(couponOdds)
    }, [couponOdds])


    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);
    const updateOdds = useUpdateOdds();
    const updateCouponOdds = useCallback(async () => {
        const response = await PostService.updateCoupon();
        console.log('coupon update');
        updateOdds(response);
    }, [couponOdds, dispatch]);

    useEffect(() => {
        let interval;
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible' && isAuth && betCards.length) {
                interval = setInterval(updateCouponOdds, 5000);
            } else {
                clearInterval(interval);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        if (document.visibilityState === 'visible' && isAuth && betCards.length) {
            interval = setInterval(updateCouponOdds, 5000);
        }

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            clearInterval(interval);
        }
    }, [couponOdds, isAuth])

    return (
        <aside className='aside_menu flex column hide-if_lg_size'>
            <LoginLang />
            <div className="content_wrapper flex column grow">
                <Coupon
                    betCards={betCards}
                    setBetCards={setBetCards}
                    ref={betBlock}
                />
                <Bet
                    ref={betBlock}
                    betCards={betCards}
                    setBetCards={setBetCards}
                />
            </div>
        </aside>
    )
}

export default AsideMenu;
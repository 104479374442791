import React from 'react';

const Google = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M23.679 9.87398H22.7459V9.8259H12.3203V14.4595H18.867C17.9119 17.1568 15.3455 19.0931 12.3203 19.0931C8.48196 19.0931 5.36992 15.9811 5.36992 12.1427C5.36992 8.30435 8.48196 5.19231 12.3203 5.19231C14.0921 5.19231 15.704 5.8607 16.9313 6.9525L20.2078 3.67597C18.1389 1.74781 15.3715 0.558716 12.3203 0.558716C5.92306 0.558716 0.736328 5.74544 0.736328 12.1427C0.736328 18.54 5.92306 23.7267 12.3203 23.7267C18.7176 23.7267 23.9043 18.54 23.9043 12.1427C23.9043 11.366 23.8244 10.6078 23.679 9.87398Z" fill="white" />
            <path d="M2.07227 6.75094L5.87818 9.5421C6.908 6.99246 9.40203 5.19231 12.3206 5.19231C14.0924 5.19231 15.7043 5.86071 16.9316 6.9525L20.2082 3.67597C18.1393 1.74781 15.3718 0.558716 12.3206 0.558716C7.87121 0.558716 4.01258 3.0707 2.07227 6.75094Z" fill="white" />
        </svg>
    )
}

export default Google;
const defaultState = {
    matches: [],
    filter_league_id: [],
}

const UPDATE_FUTURE_MATCHES = 'UPDATE_FUTURE_MATCHES';
const UPDATE_FILTER_LEAGUE_ID = 'UPDATE_FILTER_LEAGUE_ID';

export const FutureMatchesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_FUTURE_MATCHES:
            return { ...state, matches: action.payload }
        case UPDATE_FILTER_LEAGUE_ID:
            return { ...state, filter_league_id: action.payload }
        default:
            return state;
    }
}

export const updateFutureMatchesAction = (payload) => ({ type: UPDATE_FUTURE_MATCHES, payload });
export const updateFilterLeagueIdAction = (payload) => ({ type: UPDATE_FILTER_LEAGUE_ID, payload });
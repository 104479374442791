import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import MyButton from 'components/UI/MyButton/MyButton';
import MainLayout from 'layouts/MainLayout';
import React from 'react';
import { useSelector } from 'react-redux';
import PasswordRecoverySuccessIcon from 'svg/login/PasswordRecoverySuccessIcon';
import PasswordRecoverySuccessIconDark from 'svg/login/PasswordRecoverySuccessIconDark';
import { getRoute } from 'utils/routes';

const PasswordRecoverySuccess = () => {
    const ligthMode = useSelector(state => state.ThemeReducer.ligthMode);

    return (
        <MainLayout pageName="password-recovery-success">
            <main className="content_block_wrapper flex sign_form column-if_sm_size">
                <div className='half_width content'>

                    <div className="content_inner">
                        <Breadcrumb pages={[
                            { name: 'Авторизація', routeName: 'login' },
                            { name: 'Відновлення пароля', routeName: '' },
                        ]} />
                        <form action="">
                            {
                                ligthMode
                                    ?
                                    <PasswordRecoverySuccessIconDark />
                                    :
                                    <PasswordRecoverySuccessIcon />
                            }
                            <h1 className='title'>Пароль був успішно змінений!</h1>
                            <p className="subtitle">
                                Ваш обліковий запис зараз захищений новим паролем. Дякуємо за дію, і тепер ви можете користуватися нашими послугами, використовуючи оновлені авторизаційні дані.
                            </p>
                            <MyButton href={getRoute("home")} type='submit' className="my_button active">
                                Повернутися назад на головну
                            </MyButton>
                        </form>
                    </div>

                </div>
                <div className='half_width poster_wrapper'>
                    <img src={require("assets/login_poster.png")} alt="poster" />
                </div>
            </main>
        </MainLayout>
    )
}

export default PasswordRecoverySuccess;
import React, { useEffect } from 'react';
import "./Profile.scss"
import MainLayout from 'layouts/MainLayout';
import Footer from 'components/Footer/Footer';
import AsideMenu from 'components/AsideMenu/AsideMenu';
import MyAccount from 'svg/profile/MyAccount';
import ProfileInfo from 'components/PageProfile/ProfileInfo/ProfileInfo';
import CardList from 'components/PageProfile/CardList/CardList';
import { useDispatch, useSelector } from 'react-redux';
import { updatePagesRedirectAction } from 'store/AccountReducer';
import { useNavigate } from 'react-router-dom';
import { getRoute } from 'utils/routes';

const Profile = () => {
    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth === false) {
            dispatch(updatePagesRedirectAction('profile'));
            navigate(getRoute("login"));
        }
    }, [isAuth, dispatch, navigate])

    return (
        <MainLayout pageName="profile">
            <main className="content_block_wrapper small_width profile_page">
                <section className='content_block'>
                    <ProfileInfo />
                    <div className="title_page flex align_center">
                        <MyAccount />
                        <span>Мій аккаунт</span>
                    </div>
                    <CardList />
                </section>
                <Footer />
                <AsideMenu />
            </main>
        </MainLayout>
    )
}

export default Profile;
import React from 'react';
import "./CardList.scss"
import PersonalData from 'svg/profile/PersonalData';
import DocumentsIcon from 'svg/profile/DocumentsIcon';
import HelpAndInformation from 'svg/profile/HelpAndInformation';
import MyBets from 'svg/profile/MyBets';
import MyBalance from 'svg/profile/MyBalance';
import ExitAccount from 'svg/profile/ExitAccount';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import { useLogOut } from 'hooks/useAccount';

const CardList = () => {
    const dispatch = useDispatch();
    const cards = [
        { id: 1, svg: <PersonalData />, name: 'Персональні дані', page: 'personal-info' },
        { id: 2, svg: <DocumentsIcon />, name: 'Документи', page: 'documents' },
        { id: 3, svg: <HelpAndInformation />, name: 'Допомога та інформація', page: 'help-info' },
        { id: 4, svg: <MyBets />, name: 'Мої ставки', page: 'my-bets' },
        { id: 5, svg: <MyBalance />, name: 'Баланс', page: 'balance' },
        // { id: 6, svg: <ExitAccount />, name: 'Вихід' },
    ]
    
    const logOut = useLogOut();

    return (
        <div className="card_list">
            {
                cards.map(card =>
                    <Link
                        to={getRoute(card.page)}
                        key={card.id}
                        className="card_item flex column"
                    >
                        <div className="img_wrapper">
                            {card.svg}
                        </div>
                        <span className="card_name">
                            {card.name}
                        </span>
                    </Link>
                )
            }
            <div
                className="card_item flex column"
                onClick={logOut}
            >
                <div className="img_wrapper">
                    <ExitAccount />
                </div>
                <span className="card_name">
                    Вихід
                </span>
            </div>
        </div>
    )
}

export default CardList;
import React, { useEffect, useState } from 'react';
import "./SetBet.scss";
import SwitchBlock from 'components/UI/SwitchBlock/SwitchBlock';
import Edit from 'svg/bet/Edit';
import MyButton from 'components/UI/MyButton/MyButton';
import AgreeEdit from 'svg/bet/AgreeEdit';
import { useDispatch, useSelector } from 'react-redux';
import { updateBetValuesAction } from 'store/BetReducer';
import PostService from 'api/PostService';
import { useCheckBet, useSetMaxBet } from 'hooks/useOdds';

const SetBet = ({ sumValue, setSumValue, setBetErrors, betCards, backendBetErrors, setBackendBetErrors }) => {
    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);
    
    const regex = /^\d*\.?\d{0,2}$/;
    const checkBet = useCheckBet();
    const setMaxBet = useSetMaxBet();
    const maxBetHandler = () => {

        const maxBet = setMaxBet(backendBetErrors, setBackendBetErrors);
        setBetErrors(maxBet.error)
        setSumValue(maxBet.maxBet)
    }

    const sumHandler = (value) => {
        if (!regex.test(value)) {
            return;
        }
        
        setSumValue(value);
        const check = checkBet(value, backendBetErrors, setBackendBetErrors);
        setBetErrors(check);
    }

    useEffect(() => {
        const check = checkBet(sumValue, backendBetErrors, setBackendBetErrors);
        setBetErrors(check);
    }, [betCards])


    const [editActive, setEditActive] = useState(false);

    const betValues = useSelector(state => state.BetReducer.betValues);
    const dispatch = useDispatch();
    const changeValue = (e, i) => {
        if (!regex.test(e.target.value)) {
            return;
        }

        const updatedBetValues = [...betValues];
        updatedBetValues[i].value = e.target.value || 0;
        dispatch(updateBetValuesAction(updatedBetValues));
    }

    const handleAgreeEditClick = () => {
        setEditActive(!editActive)

        let data = {
            data: betValues
        }

        PostService.updateCouponButtons(data);
    }

    return (
        <>
            <div className='flex align_center edit_bet_wrapper'>
                {
                    editActive
                        ?
                        <SwitchBlock isSwitch={false} blockClass={isAuth ? "edit-active" : ""}>
                            {
                                betValues.map(item =>
                                    <div key={item.id} className='input_wrapper'>
                                        <input
                                            className='bet_value_edit'
                                            type="text"
                                            value={item.value}
                                            onChange={(e) => changeValue(e, item.id - 1)}
                                        />
                                    </div>
                                )
                            }
                        </SwitchBlock>
                        :
                        <SwitchBlock bckColor2={true} blockClass={isAuth ? "edit-active" : ""}>
                            {
                                betValues.map(item =>
                                    <MyButton
                                        key={item.id}
                                        onClick={(e) => sumHandler(e.target.innerText)}
                                        className={"my_button small" + (item.id === 1 ? " active" : "")}
                                    >
                                        {item.value}
                                    </MyButton>
                                )
                            }
                        </SwitchBlock>
                }
                {
                    !!isAuth &&
                    (
                        editActive
                            ?
                            <AgreeEdit onClick={handleAgreeEditClick} className='edit_bet pointer' />
                            :
                            <Edit onClick={() => setEditActive(!editActive)} className='edit_bet pointer' />
                    )
                }
            </div>
            <div className="bet_summa flex column">
                <span className='input_title'>Сума ставки</span>
                <div className="input_block flex align_center">
                    <input
                        value={sumValue}
                        onChange={e => sumHandler(e.target.value)}
                        type="text"
                        name='number'
                        placeholder='Введіть сумму'
                    />
                    <span onClick={maxBetHandler} className='pointer'>Max</span>
                </div>
            </div>
        </>
    )
}

export default SetBet;
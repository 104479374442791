import React from 'react';

const Question2Light = ({...props}) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M15.1896 7.93606C15.1896 11.9237 11.9579 15.1561 7.97159 15.1561C3.98533 15.1561 0.753601 11.9237 0.753601 7.93606C0.753601 3.94845 3.98533 0.716064 7.97159 0.716064C11.9579 0.716064 15.1896 3.94845 15.1896 7.93606Z" stroke="#28292E" />
            <path d="M8.46942 9.71802H7.37958C7.38348 9.34302 7.41669 9.03638 7.47919 8.7981C7.54559 8.55591 7.65302 8.33521 7.80145 8.13599C7.94989 7.93677 8.14716 7.71021 8.39325 7.4563C8.57294 7.27271 8.737 7.10083 8.88544 6.94067C9.03778 6.77661 9.16083 6.60083 9.25458 6.41333C9.34833 6.22192 9.3952 5.99341 9.3952 5.72778C9.3952 5.45825 9.34637 5.22583 9.24872 5.03052C9.15497 4.83521 9.01434 4.68481 8.82684 4.57935C8.64325 4.47388 8.41473 4.42114 8.1413 4.42114C7.91473 4.42114 7.69989 4.46216 7.49677 4.54419C7.29364 4.62622 7.12958 4.75317 7.00458 4.92505C6.87958 5.09302 6.81512 5.31372 6.81122 5.58716H5.72723C5.73505 5.14575 5.84442 4.76685 6.05536 4.45044C6.2702 4.13403 6.55927 3.89185 6.92255 3.72388C7.28583 3.55591 7.69208 3.47192 8.1413 3.47192C8.63739 3.47192 9.05927 3.56177 9.40692 3.74146C9.75848 3.92114 10.0261 4.17896 10.2097 4.51489C10.3932 4.84692 10.485 5.24146 10.485 5.69849C10.485 6.05005 10.4128 6.37427 10.2682 6.67114C10.1276 6.96411 9.94598 7.2395 9.72333 7.49731C9.50067 7.75513 9.26434 8.00122 9.01434 8.2356C8.7995 8.43481 8.65497 8.65942 8.58075 8.90942C8.50653 9.15942 8.46942 9.42896 8.46942 9.71802ZM7.3327 11.5754C7.3327 11.3997 7.38739 11.2512 7.49677 11.1301C7.60614 11.009 7.76434 10.9485 7.97137 10.9485C8.18231 10.9485 8.34247 11.009 8.45184 11.1301C8.56122 11.2512 8.61591 11.3997 8.61591 11.5754C8.61591 11.7434 8.56122 11.8879 8.45184 12.009C8.34247 12.1301 8.18231 12.1907 7.97137 12.1907C7.76434 12.1907 7.60614 12.1301 7.49677 12.009C7.38739 11.8879 7.3327 11.7434 7.3327 11.5754Z" fill="#28292E" />
        </svg>
    )
}

export default Question2Light;
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { publicRoutes } from 'route';
import { useSelector } from 'react-redux';

const AppRouter = () => {
    const ligthMode = useSelector(state => state.ThemeReducer.ligthMode);

    useEffect(() => {
        if (ligthMode) {
            document.querySelector("body").classList.add("light_mode");
        }
    }, [ligthMode]);

    const location = useLocation();
    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [location])

    return (
        <Routes>
            {publicRoutes.map(route =>
                <Route
                    exact={route.exact}
                    path={route.path}
                    element={route.element}
                    key={route.path}
                />
            )}
        </Routes>
    )
}

export default AppRouter;
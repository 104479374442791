import React from 'react';
import "./HistoryItem.scss";
import moment from 'moment-timezone';
import { getTimezone } from 'utils/timezone';

const ExpressItem = ({ item, index, placedTimestampUtc }) => {

    return (
        <div className='history_block flex column'>
            <div className='flex space_between align_center'>
                <div className="flex align_center">
                    <span className="index">{index + 1}</span>
                    <span className="id">id:&nbsp;{item.id}</span>
                </div>
                <span className="data">
                    {moment.utc(placedTimestampUtc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('D MMMM')}
                    &nbsp;
                    {moment.utc(placedTimestampUtc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('H:mm')}
                </span>
            </div>
            <div className='flex space_between align_center'>
                <span className="team">
                    {item.sport_match.local_team.translation.title}
                    &nbsp;-&nbsp;
                    {item.sport_match.visitor_team.translation.title}
                </span>
            </div>
            <div className="flex space_between">
                <span className="sum_title">Коефіцієнт</span>
                <span className="express_coef">
                    {item.formatted_value}
                </span>
            </div>
        </div>
    )
}

export default ExpressItem;
import { OddsNameTranslation } from "constants/OddsNameTranslation";
import { OddsTypesConst } from "constants/OddsTypesConst";

export const getTotalExpressCoef = (betCards) => {
    const totalCoef = betCards.reduce((totalCoef, item) => (totalCoef * item.value), 1);
    return totalCoef.toFixed(2);
}

export const updateOddsObjects = (sortOrder, oddsArray) => {
    const sortedArray = oddsArray
        .sort((a, b) => {
            const aIndex = sortOrder.findIndex(item => item.name === a.name.toLowerCase());
            const bIndex = sortOrder.findIndex(item => item.name === b.name.toLowerCase());
            return aIndex - bIndex;
        })
        .map(obj => {
            const { title } = sortOrder.find(item => item.name.toLowerCase() === obj.name.toLowerCase()) || {};
            return { ...obj, coefTitle: title || "" };
        });
    return sortedArray;
}

export const getOddName = (odd) => {
    const oddName = odd.name.toLowerCase();
    const oddTypeId = odd.odds_type.id;

    if (oddTypeId === OddsTypesConst.correct_score) {
        return `${odd.name}`
    }

    switch (`${oddName}_${oddTypeId}`) {
        case 'home_1':
            return `${odd.sport_match.local_team.translation.title}`
        case 'draw_1':
            return OddsNameTranslation[oddName]
        case 'away_1':
            return `${odd.sport_match.visitor_team.translation.title}`

        case 'over_5':
            return `${OddsNameTranslation[oddName]} (${odd.condition_param})`
        case 'under_5':
            return `${OddsNameTranslation[oddName]} (${odd.condition_param})`

        case 'home_6':
            return `${odd.sport_match.local_team.translation.title} (${odd.condition_param})`
        case 'away_6':
            return `${odd.sport_match.visitor_team.translation.title} (${odd.condition_param})`

        case 'home_23':
            return `${odd.sport_match.local_team.translation.title} (${OddsNameTranslation['yes']})`
        case 'away_23':
            return `${odd.sport_match.visitor_team.translation.title} (${OddsNameTranslation['yes']})`

        case 'home_14':
            return `${odd.sport_match.local_team.translation.title}`
        case 'draw_14':
            return OddsNameTranslation[oddName + '_' + '14']
        case 'away_14':
            return `${odd.sport_match.visitor_team.translation.title}`

        case 'home_30':
            return `${odd.sport_match.local_team.translation.title}`
        case 'away_30':
            return `${odd.sport_match.visitor_team.translation.title}`

        case 'home_3':
            return `${odd.sport_match.local_team.translation.title}`
        case 'draw_3':
            return OddsNameTranslation[oddName]
        case 'away_3':
            return `${odd.sport_match.visitor_team.translation.title}`

        case 'home_13':
            return `${odd.sport_match.local_team.translation.title}`
        case 'draw_13':
            return OddsNameTranslation[oddName]
        case 'away_13':
            return `${odd.sport_match.visitor_team.translation.title}`

        case 'over_7':
            return `${OddsNameTranslation[oddName]} (${odd.condition_param})`
        case 'under_7':
            return `${OddsNameTranslation[oddName]} (${odd.condition_param})`

        case 'over_8':
            return `${OddsNameTranslation[oddName]} (${odd.condition_param})`
        case 'under_8':
            return `${OddsNameTranslation[oddName]} (${odd.condition_param})`

        case 'yes_9':
            return `${OddsNameTranslation[oddName]}`
        case 'no_9':
            return `${OddsNameTranslation[oddName]}`

        case 'yes_21':
            return `${OddsNameTranslation[oddName]}`
        case 'no_21':
            return `${OddsNameTranslation[oddName]}`

        case 'yes_22':
            return `${OddsNameTranslation[oddName]}`
        case 'no_22':
            return `${OddsNameTranslation[oddName]}`

        case 'home/draw_12':
            return '1X'
        case 'home/away_12':
            return '12'
        case 'draw/away_12':
            return 'X2'

        case 'home/draw_19':
            return '1X'
        case 'home/away_19':
            return '12'
        case 'draw/away_19':
            return 'X2'

        case 'home/draw_20':
            return '1X'
        case 'home/away_20':
            return '12'
        case 'draw/away_20':
            return 'X2'

        default:
            return OddsNameTranslation[oddName]
    }
}


import React from 'react';

const Remove = ({...props}) => {
    return (
        <svg {...props} className='remove' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <rect width="19.7959" height="1.44834" rx="0.72417" transform="matrix(0.699997 -0.714146 0.699997 0.714146 0.699219 14.4702)" fill="#6E7074" />
            <path d="M1.20793 0.848706C1.48789 0.563085 1.9418 0.563085 2.22176 0.848706L15.065 13.9515C15.3449 14.2371 15.3449 14.7002 15.065 14.9858C14.785 15.2715 14.3311 15.2715 14.0511 14.9858L1.20793 1.88303C0.927964 1.59741 0.927964 1.13433 1.20793 0.848706Z" fill="#6E7074" />
        </svg>
    )
}

export default Remove;
import React from 'react';
import "./Breadcrumb.scss";
import { Link } from 'react-router-dom';
import Home from 'svg/Home';
import { getRoute } from 'utils/routes';
import ArrowPrev from 'svg/ArrowPrev';
import MyAccount from 'svg/profile/MyAccount';

const Breadcrumb = ({ pages, prevPage = false, profile = false, ...props }) => {
    const getParam = (param) => {
        if (param) {
            return param;
        }
        return []
    }

    return (
        <div className="breadcrumb flex wrap">
            {
                profile
                    ?
                    <Link
                        to={getRoute('profile')}
                        className={
                            'page main_page' + (prevPage ? ' hide-if_sm_size' : '')
                        }
                    >
                        <MyAccount />
                        <span>Мій аккаунт </span>
                    </Link>
                    :
                    <Link
                        to={getRoute('home')}
                        className={
                            'page main_page' + (prevPage ? ' hide-if_sm_size' : '')
                        }
                    >
                        <Home />
                        <span>Головна</span>
                    </Link>
            }
            {
                pages.map(page =>
                    <div
                        className={
                            'page_wrapper flex align_center' + (prevPage ? ' hide-if_sm_size' : '')
                        }
                        key={page.routeName}
                    >
                        <span className="divider">/</span>
                        {
                            !!page.routeName
                                ?
                                <Link to={getRoute(page.routeName, getParam(page.param))} className='page'>
                                    {page.name}
                                </Link>
                                :
                                <span className='current_page page'>
                                    {page.name}
                                </span>
                        }
                    </div>
                )
            }



            {
                prevPage &&
                <Link
                    to={
                        getRoute(
                            pages.length > 1
                                ?
                                pages[pages.length - 2].routeName
                                :
                                profile
                                    ?
                                    'profile'
                                    :
                                    'home',
                            getParam(pages[pages.length - 2]?.param))
                    }
                    className='prev_page'
                >
                    <ArrowPrev />
                    <span>Повернутися назад</span>
                </Link>
            }
        </div>
    )
}

export default Breadcrumb;
import React from 'react';
import './MyButton.scss';
import { Link } from 'react-router-dom';

const MyButton = ({ href = null, children, minWidth = false, svg = null, ...props }) => {
    if (!!href) {
        return (
            <Link
                title={props.title}
                to={href}
                className='my_button'
                {...props}
                style={{ ...(minWidth && { width: 'min-content' }) }}
            >
                {svg}
                <span>
                    {children}
                </span>
            </Link>
        )
    }

    if (props.type && props?.type === 'submit') {
        return (
            <button
                title={props.title}
                className='my_button'
                {...props}
                style={{ ...(minWidth && { width: 'min-content' }) }}
            >
                {svg}
                <span>
                    {children}
                </span>
            </button>
        )
    }

    return (
        <span
            title={props.title}
            className='my_button'
            {...props}
            style={{ ...(minWidth && { width: 'min-content' }) }}
        >
            {svg}
            <span>
                {children}
            </span>
        </span>
    )
}

export default MyButton;
import React, { useEffect, useRef } from 'react';
import './NavMenu.scss';
import Question from 'svg/Question';
import Category from './Category/Category';
import ThemeMode from 'components/UI/ThemeMode/ThemeMode';
import Logo from 'components/UI/Logo/Logo';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import FilterMenu from './FilterMenu/FilterMenu';
import { Link } from 'react-router-dom';
import { getRoute } from 'utils/routes';

const NavMenu = () => {
    const categories = useSelector(state => state.CategoryReducer.categories);
    const filterRef = useRef();
    const [leaguesGroups, setleaguesGroups] = useState([]);
    const [currentCategoryId, setCurrentCategoryId] = useState(null);

    const categoryParam = useSelector(state => state.CategoryReducer.categoryParam);
    useEffect(() => {
        filterRef.current.classList.remove('active');
    }, [categoryParam])

    const openFilterMenu = (e) => {
        filterRef.current.classList.add('active');
        setCurrentCategoryId(Number(e.currentTarget.id));
        setleaguesGroups(categories.filter(item => item.id === Number(e.currentTarget.id))[0]?.leagues_groups)
    }

    const hideFilterMenu = () => {
        filterRef.current.classList.remove("active");
    }

    return (
        <aside className='flex column nav_menu hide-if_lg_size'>
            <div className="content flex column grow">
                <Logo />
                <ThemeMode>
                    Змінити тему
                </ThemeMode>
                <Link to={getRoute("help-info")} className='help pointer'>
                    Підтримка
                </Link>
                <nav className="category_wrapper flex column">
                    {
                        categories.map(category =>
                            <Category
                                key={category.id}
                                category={category}
                                openFilterMenu={openFilterMenu}
                            />
                        )
                    }
                </nav>
            </div>
            <div className="help_block flex column">
                <span className="help">
                    Підтримка
                </span>
                <Link to={getRoute('help-info')} className="help-info flex align_center pointer">
                    <Question />
                    <span className='title'>
                        Допомога та інформація
                    </span>
                </Link>
            </div>
            <FilterMenu
                hideFilterMenu={hideFilterMenu}
                ref={filterRef}
                leaguesGroups={leaguesGroups}
                currentCategoryId={currentCategoryId}
            />
        </aside>
    )
}

export default NavMenu;
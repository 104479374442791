import BetCoef from 'components/BetCoef/BetCoef';
import React from 'react';
import { updateOddsObjects } from 'utils/odds';

const Handicap = ({ match, oddTypeId, oddsGroup }) => {
    const getHandicapOddText = (odds) => {
        const [condition_param_1, condition_param_2] = odds.map(obj => obj.condition_param);

        return [
            { name: "home", title: `${match.local_team.translation.title} (${condition_param_1})` },
            { name: "away", title: `${match.visitor_team.translation.title} (${condition_param_2})` },
        ]
    }

    return (
        <div className="item_submenu flex full_width column">
            {
                oddsGroup.map((oddsSubgroup, i) =>
                    <div
                        key={oddTypeId + '_' + i}
                        className="flex full_width space_between bet-coef_wrapper"
                    >
                        {updateOddsObjects(getHandicapOddText(oddsSubgroup.odds), oddsSubgroup.odds).map(odd =>
                            <BetCoef
                                key={odd.id}
                                coefTitle={odd.coefTitle}
                                odd={odd}
                                match={match}
                                halfWidth={true}
                            />
                        )}
                    </div>
                )
            }
        </div>
    )
}

export default Handicap;
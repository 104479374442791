import React from 'react';
import './ProfileInfo.scss'
import ProfileIcon from 'svg/ProfileIcon';
import MyButton from 'components/UI/MyButton/MyButton';
import SwitchBlock from 'components/UI/SwitchBlock/SwitchBlock';
import SignOut from 'svg/login/SignOut';
import { updateBalanceCashTabAction } from 'store/AccountReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getRoute } from 'utils/routes';
import { useLogOut } from 'hooks/useAccount';

const ProfileInfo = () => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.AccountReducer.userData);

    const logOut = useLogOut();

    return (
        <div className='flex profile_info column-if_sm_size'>

            <div className='flex align_center info_container'>
                <div className='flex align_center'>
                    {
                        userData.avatar_url
                            ?
                            <img src={userData.avatar_url} alt="avatar" className='avatar_image' />
                            :
                            <ProfileIcon />
                    }
                    <div className='flex column name_wrapper'>
                        <span className="name">
                            {
                                userData.first_name
                                    ?
                                    userData.last_name
                                        ?
                                        userData.middle_name
                                            ?
                                            userData.first_name + " " + userData.last_name + " " + userData.middle_name
                                            :
                                            userData.first_name + " " + userData.last_name
                                        :
                                        userData.first_name
                                    :
                                    userData.email
                            }
                        </span>
                        <span className="user_id">id: {userData.id}</span>
                    </div>
                </div>
                <div className='balance_wrapper hide flex-if_sm_size'>
                    <span className="title">Баланс:</span>
                    <span className="balance">{userData.balance} UAH</span>
                </div>
            </div>

            <SwitchBlock isSwitch={false}>
                <MyButton href={getRoute('balance')}  onClick={() => dispatch(updateBalanceCashTabAction(false))} className="my_button medium active full_height">
                    Поповнити
                </MyButton>
                <MyButton href={getRoute('balance')} onClick={() => dispatch(updateBalanceCashTabAction(true))} className="my_button medium full_height">
                    Вивести
                </MyButton>
                <MyButton className="my_button medium sign_out full_height" onClick={logOut}>
                    Вийти
                    <SignOut />
                </MyButton>
            </SwitchBlock>

        </div>
    )
}

export default ProfileInfo;
import React from 'react';

const Facebook = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="24" viewBox="0 0 11 24" fill="none">
            <path d="M7.04393 23.4417H2.41314V12.1419H0.0996094V8.24772H2.41314V5.90968C2.41314 2.73284 3.71951 0.84375 7.43105 0.84375H10.521V4.73835H8.58956C7.14475 4.73835 7.04918 5.28256 7.04918 6.29821L7.04393 8.24728H10.5429L10.1335 12.1414H7.04393V23.4417Z" fill="white" />
        </svg>
    )
}

export default Facebook;
export const showHidePassword = (e) => {
    const input = e.currentTarget.parentElement.querySelector('input');
    if (input.type === 'password') {
        input.type = "text";
    } else input.type = "password";
}

export const inputHandleChange = (event, setFormData) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
}

export const pushError = (response) => {
    let errors = [];

    if (response.status === 422) {
        if (response.data.errors) {
            Object.keys(response.data.errors).forEach((error_key) => {
                if (Array.isArray(response.data.errors[error_key])) {
                    response.data.errors[error_key].forEach((error_str) => {
                        errors.push(error_str);
                    });
                } else if (typeof response.data.errors[error_key] === 'string') {
                    errors.push(response.data.errors[error_key]);
                }
            });
        }
    } else {
        errors.push('Щось пішло не так, спробуйте ще раз.');
    }

    return errors;
}
import React from 'react';

const NoSearch = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <path d="M27.3033 27.5332C27.3032 27.5332 27.3034 27.5332 27.3033 27.5332C24.7753 27.5332 22.3983 28.5178 20.6108 30.3054C19.9735 30.9426 19.9735 31.9759 20.6108 32.6132C20.9294 32.9318 21.3471 33.0912 21.7647 33.0912C22.1824 33.0912 22.6 32.9318 22.9185 32.6132C24.0898 31.4419 25.647 30.797 27.3033 30.797C28.9597 30.797 30.5169 31.442 31.6881 32.6132C32.3253 33.2503 33.3586 33.2503 33.9959 32.6132C34.6332 31.976 34.6332 30.9427 33.9959 30.3054C32.2082 28.5178 29.8315 27.5332 27.3033 27.5332Z" fill="white" />
            <path d="M21.8782 23.9773C22.2839 23.5726 22.5157 23.0112 22.5157 22.439C22.5157 21.8667 22.2839 21.3054 21.8782 20.9006C21.4735 20.496 20.9121 20.2632 20.3399 20.2632C19.7665 20.2632 19.2063 20.496 18.8016 20.9006C18.3969 21.3054 18.1641 21.8667 18.1641 22.439C18.1641 23.0112 18.3969 23.5726 18.8016 23.9773C19.2063 24.382 19.7676 24.6148 20.3399 24.6148C20.9121 24.6148 21.4735 24.382 21.8782 23.9773Z" fill="white" />
            <path d="M34.2656 20.2632C33.6923 20.2632 33.1321 20.496 32.7274 20.9006C32.3216 21.3054 32.0898 21.8667 32.0898 22.439C32.0898 23.0112 32.3216 23.5726 32.7274 23.9773C33.1321 24.382 33.6934 24.6148 34.2656 24.6148C34.8379 24.6148 35.3992 24.3819 35.8039 23.9773C36.2097 23.5726 36.4414 23.0112 36.4414 22.439C36.4414 21.8667 36.2097 21.3054 35.8039 20.9006C35.3992 20.496 34.8379 20.2632 34.2656 20.2632Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M46.3299 26.7948C46.3299 37.4915 37.6585 46.1629 26.9618 46.1629C16.265 46.1629 7.59363 37.4915 7.59363 26.7948C7.59363 16.098 16.265 7.42663 26.9618 7.42663C37.6585 7.42663 46.3299 16.098 46.3299 26.7948ZM42.9166 47.5832C38.4979 50.9795 32.9656 52.9987 26.9618 52.9987C12.4897 52.9987 0.757812 41.2668 0.757812 26.7948C0.757812 12.3227 12.4897 0.59082 26.9618 0.59082C41.4338 0.59082 53.1657 12.3227 53.1657 26.7948C53.1657 32.7987 51.1465 38.3309 47.7502 42.7496L62.4184 57.4177C63.7531 58.7525 63.7531 60.9166 62.4184 62.2514C61.0836 63.5861 58.9195 63.5861 57.5847 62.2514L42.9166 47.5832Z" fill="white" />
        </svg>
    )
}

export default NoSearch;
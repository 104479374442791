import React from 'react';

const BetSuccess = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M34.8379 18.0001C34.8379 27.4164 27.4008 35.0066 18.2793 35.0066C9.15783 35.0066 1.7207 27.4164 1.7207 18.0001C1.7207 8.58382 9.15783 0.993652 18.2793 0.993652C27.4008 0.993652 34.8379 8.58382 34.8379 18.0001Z" stroke="white" strokeWidth="2" />
            <path d="M11.5723 16.5061L17.0989 21.7203L24.9853 14.2798" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>
    )
}

export default BetSuccess;
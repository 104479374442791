import React from 'react';
import MyButton from 'components/UI/MyButton/MyButton';
import SwitchBlock from 'components/UI/SwitchBlock/SwitchBlock';
import './LoginLang.scss';
import { getRoute } from 'utils/routes';
import Lang from 'components/UI/Lang/Lang';

const LoginLang = () => {
    return (
        <div className='flex login_lang hide-if_sm_size'>
            <SwitchBlock mr="10px" blockClass="grow" isSwitch={false}>
                <MyButton
                    href={getRoute('login')}
                    className="medium my_button full_height"
                >Вхід</MyButton>
                <MyButton
                    href={getRoute('register')}
                    className="medium active my_button full_height"
                >Реєстрація</MyButton>
            </SwitchBlock>
            {/* <Lang /> */}
        </div >
    )
}

export default LoginLang;
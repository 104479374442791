import React from 'react';
import "./SignInMethods.scss";
import Facebook from 'svg/login/Facebook';
import Google from 'svg/login/Google';
import Twitch from 'svg/login/Twitch';

import { getRoute } from 'utils/routes';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useGoogleLogin } from '@react-oauth/google';
import AccountService from 'api/AccountService';

const SignInMethods = ({ fetchLogin, redirectPage, setShowErrors }) => {

    const loginGoogle = useGoogleLogin({
        onSuccess: response => {
            if (response.access_token) {
                fetchLogin(
                    AccountService.logInSocial,
                    { provider_name: 'google', access_token: response.access_token },
                    setShowErrors,
                    redirectPage,
                );
            }
        },
        onError: () => {
            console.log('Login Failed');
        },
        //redirect_uri: window.location.origin + getRoute('login'),
    });

    const openPopupWindow = function (a, b) {
        var c = Math.min(500, window.screen.width - 40);
        var d = Math.min(550, window.screen.height - 40);
        c = ["toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no", "width=" + c, "height=" + d, "top=" + (window.screen.height / 2 - d / 2), "left=" + (window.screen.width / 2 - c / 2)].join();
        d = window;
        b = d.open(a, b, c);
        if (!b || b.closed || "undefined" === typeof b.closed)
            return alert("Failed to open popup window. Maybe blocked by the browser?"), null;
        b.focus();
        return b
    }

    const loginTwitch = async () => {

        let url = 'https://id.twitch.tv/oauth2/authorize?client_id=d1bxaqjfuycpwku9cjyuk1uzweecyj&response_type=token&scope=user:read:email&redirect_uri=' + window.location.origin + getRoute('twitch-redirect');

        let popupWindow = openPopupWindow(url, '_blank');

        window.addEventListener('message', event => {
            const accessToken = event.data;
            if (event.source === popupWindow && typeof accessToken === 'string') {
                popupWindow.close();

                fetchLogin(
                    AccountService.logInSocial,
                    { provider_name: 'twitch', access_token: accessToken },
                    setShowErrors,
                    redirectPage,
                );
            }
        });
    };

    return (
        <>
            <div className='or'>
                <span>Або</span>
            </div>
            <div className="sign-in-methods">

                <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    onSuccess={(response) => {
                        if (response.accessToken) {
                            fetchLogin(
                                AccountService.logInSocial,
                                { provider_name: 'facebook', access_token: response.accessToken },
                                setShowErrors,
                                redirectPage,
                            );
                        }
                    }}
                    onFail={(error) => {
                        console.log('Login Failed!', error);
                    }}
                    render={({ onClick, logout }) => (
                        <div className="method" onClick={onClick}>
                            <Facebook />
                        </div>
                    )}
                />
                <div className="method" onClick={() => loginGoogle()}>
                    <Google />
                </div>
                <div className="method" onClick={() => loginTwitch()}>
                    <Twitch />
                </div>
            </div>
        </>
    )
}

export default SignInMethods;
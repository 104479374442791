import React from 'react';
import CoefBox from './CoefBox/CoefBox';
import './Match.scss';
import { Link } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import LiveGrid from 'components/LiveGrid/LiveGrid';
import { OddsTypesConst } from 'constants/OddsTypesConst';
import { updateOddsObjects } from 'utils/odds';

const Match = ({ currentCategory, match, live }) => {
    const matchWinnerOdds = match.preview_odds_groups[OddsTypesConst.match_winner][0].odds;

    // console.log(match.preview_odds_groups[OddsTypesConst.goals_over_under]);
    // if (!match.preview_odds_groups[OddsTypesConst.goals_over_under][0]) {
        // console.log('match: ', match);
    // } else console.log(match.preview_odds_groups);
    
    const goalsOverUnderGroup = match.preview_odds_groups[OddsTypesConst.goals_over_under] ? match.preview_odds_groups[OddsTypesConst.goals_over_under][0] : null;
    
    const sortOrderMatchWinnerOdds = [
        { name: "home", title: "П1" },
        { name: "draw", title: "X" },
        { name: "away", title: "П2" },
    ];
    const sortOrderGoalsOverUnderGroup = [
        { name: "over", title: "Б" },
        { name: "under", title: "М" },
    ];

    const updateMatchWinnerOdds = updateOddsObjects(sortOrderMatchWinnerOdds, matchWinnerOdds)
    const updateGoalsOverUnderGroup = !!goalsOverUnderGroup ? updateOddsObjects(sortOrderGoalsOverUnderGroup, goalsOverUnderGroup.odds) : [];
    
    return (
        <div className={"match column-if_sm_size" + (live ? " live" : '')}>
            <Link
                to={getRoute(live ? 'live-bets-match' : 'match', [currentCategory.slug, match.id])}
                className="team_block flex column row-if_sm_size"
            >
                {
                    live &&
                    <span className="live_match">
                        {match.liveMatch}
                    </span>
                }
                <div className="team flex">
                    {match.local_team.image_url && (
                        <img src={match.local_team.image_url} alt="team_1" />
                    )}
                    <span className='team_name'>{match.local_team.translation.title}</span>
                </div>
                <div className="team flex">
                    {match.visitor_team.image_url && (
                        <img src={match.visitor_team.image_url} alt="team_2" />
                    )}
                    <span className='team_name'>{match.visitor_team.translation.title}</span>
                </div>
            </Link>
            {
                live &&
                <LiveGrid gridList={match.live} />
            }
            <div className="coef_wrapper">
                {
                    updateMatchWinnerOdds.map(odd =>
                        <CoefBox
                            key={odd.id}
                            coefTitle={odd.coefTitle}
                            odd={odd}
                            match={match}
                        />
                    )
                }
            </div>
            {
                !!updateGoalsOverUnderGroup.length &&
                <div className="coef_wrapper">
                    <CoefBox
                        coefTitle="Т"
                        value={goalsOverUnderGroup.group_param}
                    />
                    {
                        updateGoalsOverUnderGroup.map(odd =>
                            <CoefBox
                                key={odd.id}
                                coefTitle={odd.coefTitle}
                                odd={odd}
                                match={match}
                            />
                        )
                    }
                </div>
            }
        </div>
    )
}

export default Match;
import React from 'react';

const Block = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20" fill="none">
            <path d="M13.3474 8.08633H12.7433V5.26698C12.7433 2.4879 10.4878 0.232422 7.70875 0.232422C4.92967 0.232422 2.67419 2.4879 2.67419 5.26698V8.08633H2.07004C1.18396 8.08633 0.458984 8.81131 0.458984 9.69739V17.5513C0.458984 18.4374 1.18396 19.1624 2.07004 19.1624H13.3474C14.2335 19.1624 14.9585 18.4374 14.9585 17.5513V9.69739C14.9585 8.81131 14.2335 8.08633 13.3474 8.08633ZM4.58732 5.26698C4.58732 3.55523 5.997 2.14555 7.70875 2.14555C9.4205 2.14555 10.8302 3.55523 10.8302 5.26698V8.08633H4.58732V5.26698ZM8.17193 13.866V15.6784C8.17193 15.9402 7.97054 16.1416 7.70875 16.1416C7.44695 16.1416 7.24557 15.9402 7.24557 15.6784V13.866C6.70183 13.6646 6.29907 13.1612 6.29907 12.5369C6.29907 11.7515 6.92335 11.1272 7.70875 11.1272C8.49414 11.1272 9.11842 11.7515 9.11842 12.5369C9.11842 13.141 8.7358 13.6646 8.17193 13.866Z" fill="#6E7074" />
        </svg>
    )
}

export default Block;
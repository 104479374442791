import AsideMenu from 'components/AsideMenu/AsideMenu';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Footer from 'components/Footer/Footer';
import MainLayout from 'layouts/MainLayout';
import React from 'react';
import "./HelpInfo.scss"
import LinkBlock from 'components/PageHelpInfo/LinkBlock/LinkBlock';
import SupportService from 'components/PageHelpInfo/SupportService/SupportService';
import ProfilePageTitle from 'components/ProfilePageTitle/ProfilePageTitle';
import { useSelector } from 'react-redux';

const HelpInfo = () => {
    const pageTitle = useSelector(state => state.HelpInfoReducer.title);
    const pageSubtitle = useSelector(state => state.HelpInfoReducer.subtitle);
    return (
        <MainLayout pageName="help-info">
            <main className="content_block_wrapper small_width help_info_page">
                <Breadcrumb pages={[
                    { name: pageTitle, routeName: '' },
                ]} prevPage={false} profile={false} />
                <section className='content_block profile_content_block'>
                    <ProfilePageTitle
                        title={pageTitle}
                        subtitle={pageSubtitle}
                    />
                    <LinkBlock />
                    <SupportService />
                </section>
                <Footer />
                <AsideMenu />
            </main >
        </MainLayout>
    )
}

export default HelpInfo;
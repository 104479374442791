
const defaultState = {
    loading: true,
}

const UPDATE_LOADING = 'UPDATE_LOADING';

export const LoadingReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_LOADING:
            return {...state, loading: action.payload}
        default:
            return state;
    }
} 

export const updateLoadingAction = (payload) => ({type: UPDATE_LOADING, payload});
const defaultState = {
    pages_links_data: {},
    info_pages_links_data: {}
}

const UPDATE_PAGES_LINKS_DATA = 'UPDATE_PAGES_LINKS_DATA';
const UPDATE_INFO_PAGES_LINKS_DATA = 'UPDATE_INFO_PAGES_LINKS_DATA';

export const LinksDataReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_PAGES_LINKS_DATA:
            return { ...state, pages_links_data: action.payload }
        case UPDATE_INFO_PAGES_LINKS_DATA:
            return { ...state, info_pages_links_data: action.payload }
        default:
            return state;
    }
}

export const updatePagesLinksDataAction = (payload) => ({ type: UPDATE_PAGES_LINKS_DATA, payload });
export const updateInfoPagesLinksDataAction = (payload) => ({ type: UPDATE_INFO_PAGES_LINKS_DATA, payload });
import React from 'react';
import "./MatchHeader.scss";
import { getTimezone } from 'utils/timezone';
import { OddsTypesConst } from 'constants/OddsTypesConst';
import moment from 'moment-timezone';

const MatchHeader = ({currentCategory, match}) => {
    const mainOdds = match.odds_groups[OddsTypesConst.match_winner][0].odds;

    return (
        <div className="match_header flex space_between align_center">
            {match.local_team.image_url && (
                <img className='img_background team_1' src={match.local_team.image_url} alt="team_1" />
            )}
            {match.visitor_team.image_url && (
                <img className='img_background team_2' src={match.visitor_team.image_url} alt="team_2" />
            )}

            <div className="team_wrapper flex align_center column-if_sm_size">
                <div className="team flex align_center column-if_sm_size">
                    {match.local_team.image_url && (
                        <img src={match.local_team.image_url} alt="team_1" />
                    )}
                    <span className="team_name">{match.local_team.translation.title}</span>
                </div>
                <span className="coef">{mainOdds.find(odd => odd.name === 'Home').formatted_value}</span>
            </div>

            <div className="block_info flex column align_center">
                <span className="category">{currentCategory.translation.title}</span>
                <span className="date">{moment.utc(match.start_timestamp_utc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('D MMMM')}</span>
                <span className="time">{moment.utc(match.start_timestamp_utc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('H:mm')}</span>
                <span className="title">{match.league.translation.title}</span>
            </div>

            <div className="team_wrapper flex align_center row_reverse column-if_sm_size">
                <div className="team flex align_center row_reverse column-if_sm_size">
                    {match.visitor_team.image_url && (
                        <img src={match.visitor_team.image_url} alt="team_2" />
                    )}
                    <span className="team_name">{match.visitor_team.translation.title}</span>
                </div>
                <span className="coef">{mainOdds.find(odd => odd.name === 'Away').formatted_value}</span>
            </div>
        </div>
    )
}

export default MatchHeader;
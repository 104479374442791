import React from 'react';
import "./HistoryItem.scss";
import moment from 'moment-timezone';
import { getTimezone } from 'utils/timezone';
import SidebarBack from 'svg/SidebarBack';
import { useNavigate } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import { useDispatch } from 'react-redux';
import { addMyBetsPageMatchId } from 'store/BetReducer';


const HistoryItem = ({ item, index, openExpress }) => {
    let express = false;
    if (item.placed_bet_odds.length > 1) {
        express = true;
    }

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const routerHundler = () => {
        if (express) return
        dispatch(addMyBetsPageMatchId(item.id))
        navigate(getRoute('my-bets'))
    }

    return (
        <div onClick={routerHundler} className='history_block flex column'>
            <div className='flex space_between align_center'>
                <div className="flex align_center">
                    <span className="index">{index + 1}</span>
                    <span className="id">id:&nbsp;{item.id}</span>
                </div>
                <span className="data">
                    {moment.utc(item.placed_timestamp_utc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('D MMMM')}
                    &nbsp;
                    {moment.utc(item.placed_timestamp_utc, 'YYYY-MM-DD HH:mm:ss').tz(getTimezone()).format('H:mm')}
                </span>
            </div>
            <div className='flex space_between align_center'>
                {
                    express
                        ?
                        <span className="team">
                            Експрес {item.total_odd}
                        </span>
                        :
                        <span className="team">
                            {item.placed_bet_odds[0].sport_match.local_team.translation.title}
                            &nbsp;-&nbsp;
                            {item.placed_bet_odds[0].sport_match.visitor_team.translation.title}
                        </span>
                }
                {
                    express &&
                    <SidebarBack
                        className="express_btn"
                        onClick={() => openExpress(item)}
                    />
                }
            </div>
            <div className="flex space_between">
                <span className="sum_title">Сума виплати</span>
                <span
                    className={
                        "payment" +
                        (
                            item.status === 'unknown'
                                ?
                                " unknown"
                                :
                                item.status === 'loss'
                                    ?
                                    " loss"
                                    :
                                    ""
                        )
                    }
                >
                    {
                        item.status === 'unknown'
                            ?
                            'Невизначена'
                            :
                            item.amount + 'грн'
                    }
                </span>
            </div>
        </div>
    )
}

export default HistoryItem;
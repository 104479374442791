import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import MyButton from 'components/UI/MyButton/MyButton';
import MainLayout from 'layouts/MainLayout';
import React from 'react';
import { useSelector } from 'react-redux';
import PasswordRecoveryRequestIcon from 'svg/login/PasswordRecoveryRequestIcon';
import PasswordRecoveryRequestIconDark from 'svg/login/PasswordRecoveryRequestIconDark';

const PasswordRecoveryRequest = () => {
    const ligthMode = useSelector(state => state.ThemeReducer.ligthMode);

    return (
        <MainLayout pageName="password-recovery-request">
            <main className="content_block_wrapper flex sign_form column-if_sm_size">
                <div className='half_width content'>

                    <div className="content_inner">
                        <Breadcrumb pages={[
                            { name: 'Авторизація', routeName: 'login' },
                            { name: 'Відновлення пароля', routeName: '' },
                        ]} />
                        <form action="">
                            {
                                ligthMode
                                    ?
                                    <PasswordRecoveryRequestIconDark />
                                    :
                                    <PasswordRecoveryRequestIcon />
                            }
                            <h1 className='title'>Запит на відновлення паролю був успішно оброблений</h1>
                            <p className="subtitle">
                                Для того щоб відновити свій пароль, перейдіть на свою пошту та знайдіть лист від нас. Відкрийте лист та скористайтеся посиланням, яке ви знайдете всередині, щоб встановити новий пароль для вашого облікового запису.
                            </p>
                            <MyButton href='/' type='submit' className="my_button active">
                                Повернутися назад на головну
                            </MyButton>
                        </form>
                    </div>

                </div>
                <div className='half_width poster_wrapper'>
                    <img src={require("assets/login_poster.png")} alt="poster" />
                </div>
            </main>
        </MainLayout>
    )
}

export default PasswordRecoveryRequest;
import React from "react";
import Footer from "components/Footer/Footer";
import AsideMenu from "components/AsideMenu/AsideMenu";
import "./Home.scss";
import CategorySlider from "components/CategorySlider/CategorySlider";
import MainSlider from "components/MainSlider/MainSlider";
import { useSelector } from "react-redux";
import MainLayout from "layouts/MainLayout";

const Home = () => {
    const categories = useSelector(state => state.CategoryReducer.categories);
    const allMatches = useSelector(state => state.MainMatchesReducer.matches);
    let categoriesMatches = categories.map(category => {
        return allMatches[category.id] ? allMatches[category.id] : [];
    });
    categoriesMatches = categoriesMatches.filter(item => !!item.length)


    return (
        <MainLayout pageName="homepage">
            <main className="content_block_wrapper small_width">
                <section className='content_block'>
                    <MainSlider />
                    {
                        categoriesMatches.map((categoryMatches, i) =>
                            <CategorySlider key={i} categoryMatches={categoryMatches} categories={categories} />
                        )
                    }
                </section>
                <Footer />
                <AsideMenu />
            </main>
        </MainLayout>
    )
}

export default Home;
export function openSidebar(e, sidebarName) {
    e.currentTarget.classList.toggle("active");
    const sidebar = document.querySelector(sidebarName);
    sidebar.classList.toggle("active");

    let isOpen = false;
    if (sidebar.classList.contains("active")) {
        isOpen = true;
    }

    const bool = isSidebarsOpen(isOpen);
    if (bool) {
        document.querySelector(".mobile_menu_wrapper").classList.toggle("menu_active");
        document.querySelector(".tablet_menu_wrapper").classList.toggle("menu_active");
        document.querySelector(".blackout").classList.toggle("active");

        const bool2 = isSidebarsTopOpen();
        if (!bool2) {
            document.querySelector("body").classList.toggle("no_scroll");
        }
    }

    document.querySelector(".blackout.active")?.addEventListener('click', closeAllSidebars)
}

export const sidebarBack = (sidebarName) => {
    const sidebar = document.querySelector(sidebarName);
    sidebar.classList.remove("active");

    document.querySelector(".mobile_menu_wrapper .coupon_mobile_btn").classList.remove("active");
    document.querySelector(".tablet_menu_wrapper .coupon_mobile_btn").classList.remove("active");

    if (isSidebarsOpen(false)) {
        document.querySelector(".mobile_menu_wrapper").classList.remove("menu_active");
        document.querySelector(".tablet_menu_wrapper").classList.remove("menu_active");
        document.querySelector(".blackout").classList.remove("active");

        const bool = isSidebarsTopOpen();
        if (!bool) {
            document.querySelector("body").classList.toggle("no_scroll");
        }
    }
}

export const closeAllSidebars = (e, topSidebarBool = false) => {
    const sidebars = document.querySelectorAll('.sidebar.active');
    sidebars.forEach(sidebar => sidebar.classList.remove('active'));

    if (topSidebarBool) {
        const topSidebar = document.querySelectorAll('.top-sidebar.active');
        topSidebar.forEach(sidebar => sidebar.classList.remove('active'));
    }

    document.querySelector(".mobile_menu_wrapper .coupon_mobile_btn").classList.remove("active");
    document.querySelector(".tablet_menu_wrapper .coupon_mobile_btn").classList.remove("active");

    document.querySelector(".mobile_menu_wrapper").classList.remove("menu_active");
    document.querySelector(".tablet_menu_wrapper").classList.remove("menu_active");
    document.querySelector(".blackout").classList.remove("active", "active-top");

    const bool = isSidebarsTopOpen();
    if (!bool) {
        document.querySelector("body").classList.remove("no_scroll");
    }

    document.querySelector(".blackout").removeEventListener('click', closeAllSidebars)
}


const animationDurationOpen = .9; // + SCSS
const animationDurationClose = .5; // + SCSS
const animationDelayOpen = .8; // + SCSS

export function openTopSidebar(e, sidebarName) {
    const sidebar = document.querySelector(sidebarName);

    if (!sidebar.classList.contains("processing")) {
        e.currentTarget.classList.toggle("active");
        sidebar.classList.toggle("active");
        if (!sidebar.classList.contains('active')) {
            const hasSubmenus = sidebar.querySelectorAll('.has_submenu');
            Array.from(hasSubmenus).forEach(item => {
                item.closest(".item_wrapper").classList.remove("active")
            })
        }

        sidebar.classList.add("processing");
        let time =
            sidebar.classList.contains('active')
                ?
                (animationDurationOpen + animationDelayOpen) * 1000
                :
                (animationDurationClose) * 1000;

        setTimeout(() => {
            sidebar.classList.remove("processing")
        }, time)

        document.querySelector(".blackout").classList.toggle("active-top");
        document.querySelector("body").classList.toggle("no_scroll");
        // document.querySelector("body").classList.toggle("active-top");
    }
}

const isSidebarsOpen = (isOpen) => {
    const sidebars = document.querySelectorAll(".sidebar.active");
    if (isOpen) {
        return (!!(sidebars.length === 1));
    }
    return (!!(sidebars.length === 0));
}

const isSidebarsTopOpen = () => {
    const sidebars = document.querySelectorAll(".top-sidebar.active");

    return (!!(sidebars.length === 1));
}
const defaultState = {
    belongs_to_help_info: '',
    image_path: '',
    title: '',
    subtitle: '',
    body: '',
}

const UPDATE_HELP_INFO = 'UPDATE_HELP_INFO';

export const InfoPageReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_HELP_INFO:
            const {
                belongs_to_help_info = '',
                image_path = '',
            } = action.payload
            const {
                title = '',
                subtitle = '',
                body = '',
            } = action.payload.translation

            return {
                ...state,
                belongs_to_help_info, image_path, title, subtitle, body
            }
        default:
            return state;
    }
}

export const updateHelpInfoAction = (payload) => ({ type: UPDATE_HELP_INFO, payload })
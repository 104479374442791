import React from 'react';
import './CategorySlider.scss';
import Slider from 'react-slick';
import ArrowNext from 'svg/ArrowNext';
import CategoryCard from './CategoryCard/CategoryCard';
import NextSlider from 'components/UI/SliderArrows/NextSlider';
import PrevSlider from 'components/UI/SliderArrows/PrevSlider';
import variables from 'styles/variables.module.scss';
import { Link, useParams } from 'react-router-dom';
import { getRoute } from 'utils/routes';
import ArrowPrev from 'svg/ArrowPrev';

const CategorySlider = ({ categoryMatches = [], categories }) => {
    const param = useParams();
    const currentCategory = categoryMatches.length
        ?
        categories.find(category => category.id === categoryMatches[0].sport_id)
        :
        categories.find(category => category.slug === param.category);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: categoryMatches.length < 4 ? categoryMatches.length : 4,
        slidesToScroll: 1,
        nextArrow: <NextSlider />,
        prevArrow: <PrevSlider />,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: categoryMatches.length < 3 ? categoryMatches.length : 3,
                }
            },
            {
                breakpoint: Number(variables.xlSizeMaxWidth),
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: Number(variables.lgSizeMaxWidth),
                settings: {
                    slidesToShow: categoryMatches.length < 3 ? categoryMatches.length : 3,
                }
            },
            {
                breakpoint: Number(variables.mdSizeMaxWidth),
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: Number(variables.smSizeMaxWidth),
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    }

    return (
        <div
            className='slider_wrapper flex column'
            style={{ paddingBottom: !categoryMatches.length ? "0" : '' }}
        >
            <div className="slider_header flex space_between align_center">
                <div className="title flex align_center">
                    {currentCategory.icon}
                    <span>{currentCategory.translation.title}</span>
                </div>
                {
                    param.category
                    ?
                    <Link to={getRoute("home")} className='prev_page' >
                        <ArrowPrev />
                        <span>Повернутися назад</span>
                    </Link>
                    :
                    <Link to={getRoute('category', [currentCategory.slug])} className="more flex align_center">
                        <span>Майбутні події</span>
                        <ArrowNext />
                    </Link>
                }
            </div>
            {
                !!categoryMatches.length &&
                <Slider {...settings} className='category_slider'>
                    {
                        categoryMatches.map(categoryMatch =>
                            <CategoryCard
                                key={categoryMatch.id}
                                categoryMatch={categoryMatch}
                                currentCategoryHref={currentCategory.slug}
                            />
                        )
                    }
                </Slider>
            }
        </div>
    )
}

export default CategorySlider;
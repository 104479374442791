import React, { useState } from 'react';
import MyButton from 'components/UI/MyButton/MyButton';
import ErrorBlock from 'components/Form/ErrorBlock/ErrorBlock';
import { useUpdatePersonalInfo } from 'hooks/useFetching';
import AccountService from 'api/AccountService';
import BalanceAlert from 'components/PageBalance/BalanceAlert/BalanceAlert';
import { useSelector } from 'react-redux';
import Loader from 'components/UI/Loader/Loader';
import AvatarUpload from './AvatarUpload/AvatarUpload';

const GeneralInfo = () => {
    const activeDocumentOpeartion = useSelector(state => state.DocumentsReducer.activeDocumentOpeartion);
    const userData = useSelector(state => state.AccountReducer.userData);

    const [showErrors, setShowErrors] = useState([]);
    const [formData, setFormData] = useState({
        first_name: userData?.first_name || "",
        last_name: userData?.last_name || "",
        middle_name: userData?.middle_name || "",
        birthday: userData?.birthday || "",
        phone: userData?.phone || "",
        email: userData?.email || "",
        avatar: null,
    });

    const [successBlock, setSuccessBlock] = useState(null)

    const handleChange = (event) => {
        let { name, value } = event.target;

        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = new FormData();
        Object.keys(formData).forEach(key => {
            data.append(key, formData[key]);
        });
        
        fetchGeneralInfo(
            AccountService.updateUserData,
            data,
            setShowErrors,
        );

        setSuccessBlock(true)
    }

    const [fetchGeneralInfo, isGeneralInfoLoading] = useUpdatePersonalInfo();


    const extensions = ['jpg', 'jpeg', 'png'];

    return (
        <div className="form_container">
            <h1 className="title">
                Персональні дані
            </h1>
            {
                isGeneralInfoLoading &&
                <div className='flex justify_center' style={{ marginTop: '12px' }}>
                    <Loader />
                </div>
            }

            {
                (successBlock && !showErrors.length && !isGeneralInfoLoading) &&
                <BalanceAlert
                    title="Персональні дані успішно змінено"
                    subtitle="Вітаємо! Ваші персональні дані були успішно змінені і оновлені в нашій системі."
                    setDepositSuccess={setSuccessBlock}
                />
            }
            <ErrorBlock errors={showErrors} />

            <form onSubmit={handleSubmit} className='flex'>
                <div className='flex column col_labels'>
                    <label htmlFor="first_name" className='input_wrapper'>
                        <span className='input_text'>Ім'я *</span>
                        <input
                            name='first_name'
                            id='first_name'
                            type="text"
                            onChange={handleChange}
                            placeholder='John Doe'
                            value={formData.first_name}
                            disabled={(activeDocumentOpeartion?.status === "pending" || activeDocumentOpeartion?.status === 'success') ? true : false}
                        />
                    </label>
                    <label htmlFor="last_name" className='input_wrapper'>
                        <span className='input_text'>Прізвище *</span>
                        <input
                            name='last_name'
                            id='last_name'
                            type="text"
                            onChange={handleChange}
                            placeholder='Введіть по призвище'
                            value={formData.last_name}
                            disabled={(activeDocumentOpeartion?.status === "pending" || activeDocumentOpeartion?.status === 'success') ? true : false}
                        />
                    </label>
                    <label htmlFor="middle_name" className='input_wrapper'>
                        <span className='input_text'>По батькові *</span>
                        <input
                            name='middle_name'
                            id='middle_name'
                            type="text"
                            onChange={handleChange}
                            placeholder="Введіть по ім'я по батькові"
                            value={formData.middle_name}
                            disabled={(activeDocumentOpeartion?.status === "pending" || activeDocumentOpeartion?.status === 'success') ? true : false}
                        />
                    </label>
                </div>
                <div className='flex column col_labels'>
                    <label htmlFor="birthday" className='input_wrapper'>
                        <span className='input_text'>Дата Народження</span>
                        <input
                            name='birthday'
                            id='birthday'
                            type="date"
                            onChange={handleChange}
                            placeholder='12.08.1997'
                            value={formData.birthday || ''}
                        />
                    </label>
                    <label htmlFor="phone" className='input_wrapper'>
                        <span className='input_text'>Введіть номер *</span>
                        <input
                            name='phone'
                            id='phone'
                            type="phone"
                            onChange={handleChange}
                            placeholder='+380 (64) 456-41-56'
                            value={formData.phone}
                        />
                    </label>
                    <label htmlFor="email" className='input_wrapper'>
                        <span className='input_text'>E-mail *</span>
                        <input
                            name='email'
                            id='email'
                            type="email"
                            onChange={handleChange}
                            placeholder='Placeholder@gmail.com'
                            value={formData.email}
                            disabled
                        />
                    </label>
                </div>
                <div className='flex row_labels'>
                    <AvatarUpload extensions={extensions} setFormData={setFormData} />
                    <MyButton type='submit' className="medium active my_button submit_btn">
                        Змінити дані
                    </MyButton>
                </div>
            </form >
        </div >
    )
}

export default GeneralInfo;
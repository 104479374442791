import React from 'react';

const DarkMode = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11" fill="none">
            <path d="M3.5865 5.70337C3.5865 3.54725 4.98729 1.71958 6.92801 1.07732C6.44628 0.917898 5.93182 0.830322 5.39648 0.830322C2.70517 0.830322 0.523438 3.01205 0.523438 5.70337C0.523438 8.39468 2.70517 10.5764 5.39648 10.5764C5.93182 10.5764 6.44628 10.4888 6.92801 10.3296C4.98729 9.68715 3.5865 7.85934 3.5865 5.70337Z" fill="#2C2E32" />
        </svg>
    )
}

export default DarkMode;
import api from '../services/api';

function wait() {
    return new Promise(resolve => {
        setTimeout(resolve, 64200);
    });
}

export default class PostService {
    static async getAsyncFetch() {
        await wait();
    }


    static async sendSearch(data) {
        const request = await api.get(`/search?search=${data.search}&sport_id=${data.sport_id}`)
            .catch((error) => {
                return error.response;
            });

        return request;
    }

    static async storeBetOdd(data) {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            return;
        }

        const request = await api.post('bet/odds', data)
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }

    static async deleteBetOdd(data) {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            return;
        }

        const request = await api.delete('bet/odds', { data: data })
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }

    static async deleteAllBetOdd() {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            return;
        }

        const request = await api.delete('bet/odds/all')
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }

    static async sendBet(data) {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            return;
        }

        const request = await api.put('bet', data)
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }

    static async updateCouponButtons(data) {

        const request = await api.put('amount-buttons-groups/coupon', data)
            .catch((error) => {
                return error.response;
            });

        return request;
    }

    static async refill(data) {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            return;
        }

        const request = await api.put('balance', data)
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }

    static async withdraw(data) {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            return;
        }

        const request = await api.put('withdraw', data)
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }


    static async updateCoupon() {

        const request = await api.get('coupon-odds')
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }

    static async storeDocuments(data) {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            return;
        }

        const request = await api.post('documents', data)
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }

                return error.response;
            });

        return request;
    }
}
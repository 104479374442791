import React from 'react';

const ExitAccount = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="69" height="96" viewBox="0 0 69 96" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.19186 0.568099C8.79036 0.834911 8.24562 1.48163 7.98095 2.00542L7.49992 2.95764V16.0401V29.1226L8.30163 29.0224C8.74268 28.9672 9.56022 29.0632 10.1189 29.2355L11.1344 29.5489V16.4936V3.43846L23.035 3.5802C29.5802 3.65823 37.6857 3.73135 41.0469 3.74247L47.1581 3.76256L39.1012 5.40063C30.3029 7.18963 30.1923 7.22875 29.36 8.85741L28.879 9.79895V47.7015V85.6041H20.0067H11.1344V72.6447V59.6856L10.6533 59.8384C10.3887 59.9226 9.57112 60.0413 8.83611 60.102L7.49992 60.2128V73.2151C7.49992 81.8638 7.57581 86.419 7.72675 86.8195C8.06433 87.7146 9.19635 88.7868 10.056 89.0256C10.4979 89.1483 14.5781 89.2366 19.8463 89.2372L28.879 89.2385L28.8837 91.1092C28.8912 94.1292 29.8885 95.6067 32.0598 95.8151C33.3137 95.9355 65.1352 89.615 66.3208 89.0102C66.7518 88.7904 67.3306 88.2467 67.607 87.802L68.1096 86.9937V44.77C68.1096 5.09982 68.0872 2.50526 67.7387 1.86837C67.5347 1.49552 67.0537 0.966391 66.6697 0.692525L65.9717 0.194607L37.9467 0.138807L9.92174 0.0830078L9.19186 0.568099ZM7.95615 32.4954C7.06143 32.8715 6.85855 33.5028 6.85855 35.9116V37.9287H4.07926C1.59694 37.9287 1.25487 37.9738 0.877747 38.3509C0.64557 38.5831 0.400138 38.994 0.332366 39.264C0.264594 39.5343 0.238084 42.1427 0.273573 45.0608L0.337925 50.3662L0.937822 50.8819C1.50223 51.3672 1.69443 51.3975 4.19813 51.3975H6.85855V53.5519C6.85855 55.6479 6.87544 55.7231 7.48346 56.3312C8.14963 56.9973 8.7367 57.1079 9.49159 56.7098C10.3341 56.2651 24.0544 46.4042 24.6566 45.8103C25.3438 45.1328 25.4333 44.1782 24.8704 43.5339C24.3156 42.8989 9.60576 32.4571 9.08026 32.3252C8.83226 32.263 8.32643 32.3396 7.95615 32.4954ZM36.4716 49.7231C35.1317 50.7769 35.0518 52.4506 36.2892 53.5369C37.3466 54.4654 38.499 54.434 39.483 53.4499C40.1035 52.8295 40.2099 52.5759 40.2099 51.7182C40.2099 50.8605 40.1035 50.6069 39.483 49.9865C38.5883 49.0918 37.4056 48.9883 36.4716 49.7231Z" fill="#6E7074" />
        </svg>
    )
}

export default ExitAccount;
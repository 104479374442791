import React, { useEffect, useState } from 'react';
import './SignForms.scss';
import { Link, useNavigate } from 'react-router-dom';
import MyButton from 'components/UI/MyButton/MyButton';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import SignInMethods from 'components/Form/SignInMethods/SignInMethods';
import { getRoute } from 'utils/routes';
import { useLogin } from 'hooks/useFetching';
import AccountService from 'api/AccountService';
import Loader from 'components/UI/Loader/Loader';
import ErrorBlock from 'components/Form/ErrorBlock/ErrorBlock';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'layouts/MainLayout';
import { inputHandleChange } from 'utils/form';
import { updatePagesRedirectAction } from 'store/AccountReducer';

const Login = () => {
    const pageRedirect = useSelector(state => state.AccountReducer.pagesRedirect);
    const navigate = useNavigate();
    const redirectPage = () => {
        if (!!pageRedirect) {
            navigate(getRoute(pageRedirect))
        }
    }

    const isAuth = useSelector(state => state.AccountReducer.isLoggedIn);
    const pagesRedirect = useSelector(state => state.AccountReducer.pagesRedirect);
    useEffect(() => {
        if (isAuth && !pagesRedirect) {
            navigate(getRoute("home"))
        }
    }, [isAuth, navigate, pagesRedirect])

    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(updatePagesRedirectAction(''));
        };
    }, [dispatch])

    const [showErrors, setShowErrors] = useState([]);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchLogin(
            AccountService.logIn,
            formData,
            setShowErrors,
            redirectPage,
        );
    }

    const [fetchLogin, isLoginLoading] = useLogin();

    return (
        <MainLayout pageName="login">
            <main className="content_block_wrapper flex sign_form column-if_sm_size">
                <div className='half_width content'>

                    <div className="content_inner">
                        {
                            isLoginLoading
                                ?
                                <div className="flex_centered_item">
                                    <Loader />
                                </div>
                                :
                                <>
                                    <Breadcrumb pages={[
                                        { name: 'Авторизація', routeName: '' },
                                    ]} />
                                    <form onSubmit={handleSubmit}>
                                        <h1 className='title'>Авторизація</h1>

                                        <ErrorBlock errors={showErrors} />

                                        <div className="fieldset">
                                            <label htmlFor="email">E-mail</label>
                                            <input
                                                type="email"
                                                id='email'
                                                name='email'
                                                placeholder='Введіть E-mail'
                                                value={formData.email}
                                                onChange={e => inputHandleChange(e, setFormData)}
                                            />
                                        </div>
                                        <div className="fieldset">
                                            <label htmlFor="password">Пароль</label>
                                            <input
                                                type="password"
                                                id='password'
                                                name='password'
                                                placeholder='Введіть пароль'
                                                value={formData.password}
                                                onChange={e => inputHandleChange(e, setFormData)}
                                            />
                                        </div>
                                        <MyButton type='submit' className="my_button active">
                                            Увійти
                                        </MyButton>
                                        <SignInMethods
                                            fetchLogin={fetchLogin}
                                            redirectPage={redirectPage}
                                            setShowErrors={setShowErrors}
                                        />
                                        <div className="sign-in-block flex space_between column-if_xs_size align_center-if_xs_size">
                                            <span className='sign-in text_center-if_xs_size'>
                                                Немає облікового запису?&nbsp;
                                                <Link to={getRoute('register')}>
                                                    Реєстрація
                                                </Link>
                                            </span>
                                            <Link to={getRoute('forgot-password')} className="forgot-password">
                                                Забули пароль?
                                            </Link>
                                        </div>
                                    </form>
                                </>
                        }
                    </div>

                </div>
                <div className='half_width poster_wrapper'>
                    <img src={require("assets/login_poster.png")} alt="poster" />
                </div>
            </main>
        </MainLayout>
    )
}

export default Login;
import BetCoef from 'components/BetCoef/BetCoef';
import React from 'react';
import { updateOddsObjects } from 'utils/odds';

const CorrectScore = ({ match, oddTypeId, oddsGroup }) => {
    const getCorrectScoreOddText = (odds) => {
        const [score_1, score_2] = odds.map(obj => obj.name);

        return [
            { name: `${score_1}`, title: `${score_1}` },
            { name: `${score_2 || null}`, title: `${score_2 || null}` },
        ]
    }
    const rows = Math.ceil(oddsGroup[0].odds.length/2);
    
    const getRowOdds = (rowId) => {
        if (rowId === rows) {
            return [
                oddsGroup[0].odds[rowId * 2 - 2],
            ]
        }
         return [
            oddsGroup[0].odds[rowId * 2 - 2],
            oddsGroup[0].odds[rowId * 2 - 1]
        ]
    }

    return (
        <div className="item_submenu flex full_width column">
            {
                Array.from({length: rows}, (_, row) => (
                    <div
                        key={oddTypeId + '_' + row + 1}
                        className="flex full_width space_between bet-coef_wrapper"
                    >
                        {updateOddsObjects(getCorrectScoreOddText(getRowOdds(row + 1)), getRowOdds(row + 1)).map(odd =>
                            <BetCoef
                                key={odd.id}
                                coefTitle={odd.coefTitle}
                                odd={odd}
                                match={match}
                                halfWidth={true}
                            />
                        )}
                    </div>
                ))
            }
        </div>
    )
}

export default CorrectScore;
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const Taxes = ({ value }) => {
    const current_deposit_sum = useSelector(state => state.AccountReducer.userData.current_deposit_sum);
    const withdrawal_commission = useSelector(state => state.BalanceReducer.withdrawal_commission);

    const TaxableGain = (value - current_deposit_sum) > 0 ? (value - current_deposit_sum) : 0;
    const depositSum = current_deposit_sum > 0
        ?
        value <= current_deposit_sum
            ?
            value || 0
            :
            current_deposit_sum
        :
        0;
    const depositTaxe = (Number(depositSum) * (withdrawal_commission / 100)).toFixed(2);

    const taxes = useSelector(state => state.BalanceReducer.taxes);

    const getTaxeValue = (taxe) => {
        const taxeValue = (Number(TaxableGain) * (taxe / 100)).toFixed(2);
        return taxeValue
    }

    const getTotalTaxeValue = () => {
        const allTaxes = taxes.map(taxe => taxe.value);
        allTaxes.push(withdrawal_commission);
        const totalTaxeValue = allTaxes.reduce((commisionSum, sum) => {
            const taxe = (Number(TaxableGain) * (sum / 100)).toFixed(2);
            return Number(commisionSum) + Number(taxe)
        }, 0)
        return TaxableGain - totalTaxeValue
    }

    return (
        <div className="text_info flex column">
            <span className="title">Виграш буде виплачено двома частинами:</span>
            <p className="subtitle">
                1. Повернення депозиту (не оподатковується) - {depositSum} UAH
                <br /> Комісія: {depositTaxe} UAH
                <br /> Сума, яка буде зарахована:
                <br /> {depositSum - depositTaxe} UAH
            </p>
            <p className="subtitle">
                2. Оподаткований виграш - {TaxableGain} UAH
                {
                    taxes.map(taxe =>
                        <span key={taxe.id}><br /> {taxe.translation.title} {taxe.value}%: {getTaxeValue(taxe.value)} UAH</span>
                    )
                }
                <br /> Комісія: {getTaxeValue(withdrawal_commission)} UAH
                <br /> Сума, яка буде зарахована:
                <br /> {getTotalTaxeValue()} UAH
            </p>
        </div>
    )
}

export default Taxes;
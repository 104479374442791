import React from 'react';

const ProfileIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <path d="M13.0659 0.563477C6.29325 0.563477 0.826172 5.89076 0.826172 12.4902C0.826172 19.0897 6.29325 24.417 13.0659 24.417C19.8385 24.417 25.3056 19.0897 25.3056 12.4902C25.3056 5.89076 19.8385 0.563477 13.0659 0.563477ZM10.5091 7.03043C11.0259 6.1558 12.0051 5.59922 13.0659 5.59922C14.1267 5.59922 15.1058 6.1558 15.6226 7.03043C16.1394 7.90506 16.1394 9.01822 15.6226 9.89285C15.1058 10.7675 14.1267 11.3241 13.0659 11.3241C12.0051 11.3241 11.0259 10.7675 10.5091 9.89285C9.99236 9.01822 9.99236 7.93156 10.5091 7.03043ZM19.3217 18.1091C19.3217 18.8247 18.7234 19.3812 17.989 19.3812H8.1428C7.40842 19.3812 6.81003 18.7982 6.81003 18.1091V17.1549C6.81003 15.6442 7.65321 14.2395 8.98598 13.4709L9.04038 13.4444C11.5155 12.0397 14.6163 12.0397 17.0914 13.4444L17.1458 13.4709C18.4786 14.2395 19.3217 15.6442 19.3217 17.1549V18.1091Z" fill="black" />
            <path d="M13.0659 0.563477C6.29325 0.563477 0.826172 5.89076 0.826172 12.4902C0.826172 19.0897 6.29325 24.417 13.0659 24.417C19.8385 24.417 25.3056 19.0897 25.3056 12.4902C25.3056 5.89076 19.8385 0.563477 13.0659 0.563477ZM10.5091 7.03043C11.0259 6.1558 12.0051 5.59922 13.0659 5.59922C14.1267 5.59922 15.1058 6.1558 15.6226 7.03043C16.1394 7.90506 16.1394 9.01822 15.6226 9.89285C15.1058 10.7675 14.1267 11.3241 13.0659 11.3241C12.0051 11.3241 11.0259 10.7675 10.5091 9.89285C9.99236 9.01822 9.99236 7.93156 10.5091 7.03043ZM19.3217 18.1091C19.3217 18.8247 18.7234 19.3812 17.989 19.3812H8.1428C7.40842 19.3812 6.81003 18.7982 6.81003 18.1091V17.1549C6.81003 15.6442 7.65321 14.2395 8.98598 13.4709L9.04038 13.4444C11.5155 12.0397 14.6163 12.0397 17.0914 13.4444L17.1458 13.4709C18.4786 14.2395 19.3217 15.6442 19.3217 17.1549V18.1091Z" fill="#6E7074" />
        </svg>
    )
}

export default ProfileIcon;
import React from 'react';

const NotConfirmed = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <rect width="14.243" height="1" rx="0.5" transform="matrix(0.709169 -0.705039 0.709169 0.705039 0.636597 10.8496)" fill="white" />
            <path d="M1.02164 1.15916C1.21747 0.964469 1.53497 0.964469 1.73081 1.15916L11.1224 10.496C11.3182 10.6907 11.3182 11.0064 11.1224 11.2011C10.9265 11.3957 10.609 11.3957 10.4132 11.2011L1.02164 1.8642C0.825805 1.66951 0.825805 1.35385 1.02164 1.15916Z" fill="white" />
        </svg>
    )
}

export default NotConfirmed;
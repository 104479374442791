import React, { useEffect, useState } from 'react';
import "./DocumentsCardList.scss";
import PassportIcon from 'svg/profile/PassportIcon';
import DriversLicense from 'svg/profile/DriversLicense';
import ForeignPassport from 'svg/profile/ForeignPassport';
import { Link } from 'react-router-dom';
import NotConfirmed from 'svg/profile/NotConfirmed';
import ProcessingDocuments from 'svg/profile/ProcessingDocuments';
import Confirmed from 'svg/profile/Confirmed';
import { getRoute } from 'utils/routes';
import { useSelector } from 'react-redux';
import Loader from 'components/UI/Loader/Loader';

const DocumentsCardList = () => {
    const [loader, setLoader] = useState(true);
    const activeDocumentOpeartion = useSelector(state => state.DocumentsReducer.activeDocumentOpeartion);

    const [cards, setCards] = useState([
        { id: 1, document: 'passport', svg: <PassportIcon />, title: 'Паспорт' },
        { id: 2, document: 'driving-license', svg: <DriversLicense />, title: 'Водійське посвідчення' },
        { id: 3, document: 'international-passport', svg: <ForeignPassport />, title: 'Закордонний паспорт' },
    ])

    useEffect(() => {
        if (activeDocumentOpeartion !== null) {
            if (activeDocumentOpeartion.status === 'fail') {
                setCards([
                    { id: 1, document: 'passport', svg: <PassportIcon />, title: 'Паспорт' },
                    { id: 2, document: 'driving-license', svg: <DriversLicense />, title: 'Водійське посвідчення' },
                    { id: 3, document: 'international-passport', svg: <ForeignPassport />, title: 'Закордонний паспорт' },
                ])
                setLoader(false);
                return
            }
            setCards(prevState =>
                prevState.filter(item => item.document === activeDocumentOpeartion.document_type)
            )
        }
        setLoader(false)
    }, [activeDocumentOpeartion])

    const getSubhead = (document) => {
        if (activeDocumentOpeartion?.document_type !== document) return

        switch (activeDocumentOpeartion.status) {
            case 'pending':
                return (
                    <div className='subhead processing_documents'>
                        <ProcessingDocuments />
                        <span>Обробка документів</span>
                    </div>
                )
            case 'fail':
                return (
                    <div className='subhead not_confirmed'>
                        <NotConfirmed />
                        <span>Не підтверджено</span>
                    </div>
                )
            case 'success':
                return (
                    <div className='subhead confirmed'>
                        <Confirmed />
                        <span>Підтверджено</span>
                    </div>
                )
                break;
        }
    }

    return (
        <div className={"documents_card_list" + (cards.length === 1 ? ' single_list' : "")}>
            {
                loader
                    ?
                    <div className='flex justify_center'>
                        <Loader />
                    </div>
                    :
                    cards.map(card =>
                        <Link
                            key={card.id}
                            to={getRoute('document-upload', [card.document])}
                            className="card_item flex column"
                        >
                            <div className="img_wrapper">
                                {getSubhead(card.document)}
                                {card.svg}
                            </div>
                            <span className="card_name">
                                {card.title}
                            </span>
                        </Link>
                    )
            }
        </div>
    )
}

export default DocumentsCardList;

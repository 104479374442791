import React, { useEffect } from 'react';

const TwitchRedirect = () => {
    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp(`[#&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    useEffect(() => {
        const accessToken = getParameterByName('access_token');

        if(accessToken) {
            // Send the auth token back to the main page
            window.opener.postMessage(accessToken, '*');
        }
    }, [])
    
    return (
        <></>
    )
}

export default TwitchRedirect;
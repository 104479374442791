import React from 'react';
import "./SupportService.scss"
import { Link } from 'react-router-dom';
import Chat from 'svg/service/Chat';
import Telegram from 'svg/service/Telegram';
import Email from 'svg/service/Email';

const SupportService = () => {
    return (
        <div className="support_service">
            <span className="block_name">Служба підтримки</span>
            <div className="service_list column-if_sm_size">
                <Link className='service'>
                    <Chat />
                    <span className="title">Онлайн чат</span>
                </Link>
                <a href='https://t.me/mihail_spektr' target='_blank' className='service'>
                    <Telegram />
                    <span className="title">Telegram</span>
                </a>
                <a href='mailto:mishanya.steel@gmail.com' className='service'>
                    <Email />
                    <span className="title">Email</span>
                </a>
            </div>
        </div>
    )
}

export default SupportService;
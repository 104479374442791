import React from 'react';
import ArrowPrev from 'svg/ArrowPrev';

const PrevSlider = ({ className, style, onClick }) => {
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <ArrowPrev />
        </div>
    )
}

export default PrevSlider;
import React, { useEffect, useRef } from 'react';
import "./BetCoef.scss";
import { useSelector } from 'react-redux';
import { useAddBet } from 'hooks/useOdds';
import Block from 'svg/bet/Block';

const BetCoef = ({ coefTitle, value = null, odd = null, match = null, totalBlock = false, halfWidth = false }) => {
    const couponOdds = useSelector(state => state.BetReducer.couponOdds);
    const addBet = useAddBet();
    const ref = useRef();
    
    const matchStopped = match ? !!match.is_stopped : false;

    useEffect(() => {
        if (ref.current.parentElement.children.length === 1) {
            let div = document.createElement("div");
            div.classList.add('bet-coef');
            ref.current.parentElement.append(div)
        }
    }, [])
    
    return (
        <div
            ref={ref}
            className={
                (totalBlock
                    ?
                    " total_block"
                    :
                    " bet-coef column-if_xs_size text_center-if_xs_size") +
                (couponOdds.find(bet => bet.id === odd?.id) ? ' active' : '') +
                (matchStopped || !!odd?.stop || !!odd?.expired ? " is_stoped" : "")
            }
            onClick={() => addBet(odd, match)}
        >
            {
                coefTitle &&
                <span className='title'>{coefTitle}</span>
            }
            <span className="coef">
                {
                    matchStopped || !!odd?.stop || !!odd?.expired
                        ?
                        <Block />
                        :
                        odd ? odd.formatted_value : value
                }
            </span>
        </div>
    )
}

export default BetCoef;
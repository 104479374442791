const defaultState = {
    odds_types: []
}

const UPDATE_ODDS_TYPES = 'UPDATE_ODDS_TYPES';

export const OddsTypesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_ODDS_TYPES:
            return { ...state, odds_types: action.payload }
        default:
            return state;
    }
}

export const updateOddsTypesAction = (payload) => ({ type: UPDATE_ODDS_TYPES, payload });
import React from 'react';

const IconUpload = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="21" viewBox="0 0 28 21" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.53613 0.0761719C2.32699 0.0761719 0.536133 1.86703 0.536133 4.07617V16.9235C0.536133 19.1326 2.32699 20.9235 4.53613 20.9235H23.4639C25.673 20.9235 27.4639 19.1326 27.4639 16.9235V4.07617C27.4639 1.86703 25.673 0.0761719 23.4639 0.0761719H4.53613ZM10.0912 4.85399C10.0912 5.57359 9.5079 6.15694 8.78829 6.15694C8.06869 6.15694 7.48534 5.57359 7.48534 4.85399C7.48534 4.13439 8.06869 3.55103 8.78829 3.55103C9.5079 3.55103 10.0912 4.13439 10.0912 4.85399ZM16.1743 5.91967C16.5592 5.253 17.5215 5.253 17.9064 5.91967L22.9447 14.6463C23.3296 15.3129 22.8484 16.1463 22.0786 16.1463H12.002C11.9819 16.1463 11.9619 16.1457 11.9422 16.1446H6.46281C5.69301 16.1446 5.21188 15.3112 5.59678 14.6446L8.75443 9.17536C9.13933 8.50869 10.1016 8.5087 10.4865 9.17536L12.3906 12.4733L16.1743 5.91967Z" fill="#6E7074" />
        </svg>
    )
}

export default IconUpload;
import React from 'react';

const LightMode = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <path d="M7.37775 11.3474C9.48162 11.3474 11.1871 9.6419 11.1871 7.53803C11.1871 5.43416 9.48162 3.72864 7.37775 3.72864C5.27388 3.72864 3.56836 5.43416 3.56836 7.53803C3.56836 9.6419 5.27388 11.3474 7.37775 11.3474Z" fill="white" />
            <path d="M12.318 2.59893C12.259 2.53943 12.1888 2.49219 12.1114 2.45996C12.034 2.42773 11.951 2.41113 11.8672 2.41113C11.7834 2.41113 11.7004 2.42773 11.6231 2.45996C11.5457 2.49219 11.4755 2.53943 11.4165 2.59893L10.7816 3.23383C10.722 3.29286 10.6748 3.36308 10.6426 3.44044C10.6103 3.51781 10.5938 3.6008 10.5938 3.68461C10.5938 3.76843 10.6103 3.85141 10.6426 3.92878C10.6748 4.00615 10.722 4.07637 10.7816 4.13539C10.8409 4.19423 10.9112 4.24079 10.9886 4.27238C11.0659 4.30398 11.1488 4.31999 11.2323 4.31951C11.3159 4.31999 11.3987 4.30398 11.4761 4.27238C11.5534 4.24079 11.6238 4.19423 11.6831 4.13539L12.318 3.50049C12.3775 3.44147 12.4247 3.37125 12.457 3.29388C12.4892 3.21651 12.5058 3.13353 12.5058 3.04971C12.5058 2.9659 12.4892 2.88291 12.457 2.80555C12.4247 2.72818 12.3775 2.65796 12.318 2.59893Z" fill="white" />
            <path d="M13.7264 6.90344H12.8185C12.6501 6.90344 12.4886 6.97033 12.3696 7.0894C12.2505 7.20847 12.1836 7.36996 12.1836 7.53834C12.1836 7.70673 12.2505 7.86822 12.3696 7.98728C12.4886 8.10635 12.6501 8.17324 12.8185 8.17324H13.7264C13.8948 8.17324 14.0563 8.10635 14.1753 7.98728C14.2944 7.86822 14.3613 7.70673 14.3613 7.53834C14.3613 7.36996 14.2944 7.20847 14.1753 7.0894C14.0563 6.97033 13.8948 6.90344 13.7264 6.90344Z" fill="white" />
            <path d="M10.7757 10.935C10.7162 10.994 10.669 11.0642 10.6367 11.1416C10.6045 11.219 10.5879 11.302 10.5879 11.3858C10.5879 11.4696 10.6045 11.5526 10.6367 11.6299C10.669 11.7073 10.7162 11.7775 10.7757 11.8366L11.4106 12.4715C11.4699 12.5303 11.5403 12.5768 11.6176 12.6084C11.695 12.64 11.7778 12.6561 11.8614 12.6556C11.9449 12.6561 12.0278 12.64 12.1051 12.6084C12.1825 12.5768 12.2528 12.5303 12.3121 12.4715C12.3717 12.4124 12.4189 12.3422 12.4511 12.2648C12.4834 12.1875 12.4999 12.1045 12.4999 12.0207C12.4999 11.9369 12.4834 11.8539 12.4511 11.7765C12.4189 11.6991 12.3717 11.6289 12.3121 11.5699L11.6772 10.935C11.6182 10.8755 11.548 10.8283 11.4706 10.796C11.3933 10.7638 11.3103 10.7472 11.2265 10.7472C11.1427 10.7472 11.0597 10.7638 10.9823 10.796C10.9049 10.8283 10.8347 10.8755 10.7757 10.935Z" fill="white" />
            <path d="M6.74414 12.9793V13.8872C6.74414 14.0556 6.81103 14.217 6.9301 14.3361C7.04916 14.4552 7.21065 14.5221 7.37904 14.5221C7.54743 14.5221 7.70891 14.4552 7.82798 14.3361C7.94705 14.217 8.01394 14.0556 8.01394 13.8872V12.9793C8.01394 12.8109 7.94705 12.6494 7.82798 12.5303C7.70891 12.4113 7.54743 12.3444 7.37904 12.3444C7.21065 12.3444 7.04916 12.4113 6.9301 12.5303C6.81103 12.6494 6.74414 12.8109 6.74414 12.9793Z" fill="white" />
            <path d="M3.08051 10.935L2.44561 11.5699C2.38611 11.6289 2.33887 11.6992 2.30664 11.7765C2.27441 11.8539 2.25781 11.9369 2.25781 12.0207C2.25781 12.1045 2.27441 12.1875 2.30664 12.2649C2.33887 12.3422 2.38611 12.4124 2.44561 12.4715C2.50494 12.5303 2.5753 12.5769 2.65265 12.6085C2.73001 12.6401 2.81284 12.6561 2.89639 12.6556C2.97995 12.6561 3.06278 12.6401 3.14013 12.6085C3.21749 12.5769 3.28785 12.5303 3.34717 12.4715L3.98207 11.8366C4.10162 11.717 4.16879 11.5549 4.16879 11.3858C4.16879 11.2167 4.10162 11.0546 3.98207 10.935C3.86252 10.8155 3.70037 10.7483 3.53129 10.7483C3.36222 10.7483 3.20007 10.8155 3.08051 10.935Z" fill="white" />
            <path d="M1.02943 6.90344C0.861044 6.90344 0.699555 6.97033 0.580489 7.0894C0.461422 7.20847 0.394531 7.36996 0.394531 7.53834C0.394531 7.70673 0.461422 7.86822 0.580489 7.98728C0.699555 8.10635 0.861044 8.17324 1.02943 8.17324H1.93734C2.10572 8.17324 2.26721 8.10635 2.38628 7.98728C2.50534 7.86822 2.57223 7.70673 2.57223 7.53834C2.57223 7.36996 2.50534 7.20847 2.38628 7.0894C2.26721 6.97033 2.10572 6.90344 1.93734 6.90344H1.02943Z" fill="white" />
            <path d="M3.34023 2.59895C3.22067 2.4794 3.05852 2.41223 2.88945 2.41223C2.72038 2.41223 2.55823 2.4794 2.43867 2.59895C2.31912 2.7185 2.25195 2.88065 2.25195 3.04973C2.25195 3.2188 2.31912 3.38095 2.43867 3.50051L3.07357 4.1354C3.1329 4.19425 3.20325 4.2408 3.28061 4.2724C3.35796 4.30399 3.44079 4.32001 3.52435 4.31953C3.60791 4.32001 3.69074 4.30399 3.76809 4.2724C3.84544 4.2408 3.9158 4.19425 3.97513 4.1354C4.03463 4.07638 4.08187 4.00616 4.1141 3.92879C4.14633 3.85143 4.16293 3.76844 4.16293 3.68463C4.16293 3.60081 4.14633 3.51783 4.1141 3.44046C4.08187 3.36309 4.03463 3.29287 3.97513 3.23385L3.34023 2.59895Z" fill="white" />
            <path d="M8.01394 2.09725V1.18934C8.01394 1.02096 7.94705 0.859468 7.82798 0.740401C7.70891 0.621334 7.54743 0.554443 7.37904 0.554443C7.21065 0.554443 7.04916 0.621334 6.9301 0.740401C6.81103 0.859468 6.74414 1.02096 6.74414 1.18934V2.09725C6.74414 2.26563 6.81103 2.42712 6.9301 2.54619C7.04916 2.66526 7.21065 2.73215 7.37904 2.73215C7.54743 2.73215 7.70891 2.66526 7.82798 2.54619C7.94705 2.42712 8.01394 2.26563 8.01394 2.09725Z" fill="white" />
        </svg>
    )
}

export default LightMode;
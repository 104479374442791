import React from 'react';
import "./LiveGrid.scss";

const LiveGrid = ({gridList}) => {
    return (
        <div className="live_grid">
            {
                gridList.map(col =>
                    <span
                        key={col.id}
                        className={
                            'col_item' +
                            (col.colName ? " col_title" : "")
                        }
                    >
                        {col.value}
                    </span>
                )
            }
        </div>
    )
}

export default LiveGrid;
import React from 'react';
import Question2 from 'svg/Question2';
import ArrowDown from 'svg/ArrowDown';
import Remove from 'svg/Remove';
import { useSelector } from 'react-redux';
import Question2Light from 'svg/Question2Light';

const BetsBlockTitle = ({ item_title, item_description }) => {
    const ligthMode = useSelector(state => state.ThemeReducer.ligthMode);
    
    const openModal = (e) => {
        e.currentTarget.parentElement.querySelector(".modal").classList.toggle('active')
    }
    const remove = (e) => {
        e.currentTarget.parentElement.classList.remove('active')
    }
    const toggleAccordion = (e) => {
        if (e.target.closest('.title_box')) {
            return
        }
        e.currentTarget.parentElement.classList.toggle('active');
    }

    return (
        <div className="item flex space_between align_center has_submenu pointer" onClick={toggleAccordion}>
            <div className="title_box flex align_center default">
                {
                    item_description &&
                    <div className="modal flex column">
                        <Remove onClick={e => remove(e)} />
                        <span className="title">
                            {item_title}
                        </span>
                        <span className="subtitle">
                            {item_description}
                        </span>
                    </div>
                }
                <span className='item_title'>{item_title}</span>
                {
                    item_description &&
                        (ligthMode
                        ?
                        <Question2Light onClick={e => openModal(e)} />
                        :
                        <Question2 onClick={e => openModal(e)} />)
                }
            </div>
            <ArrowDown />
        </div>
    )
}

export default BetsBlockTitle;